import { Typography } from "@iss/client-react-components";
import React, { useContext, useEffect, useState } from "react";
import {
  Grid, Table
} from "semantic-ui-react";
import { calculatePercent, toMoneyFormat } from "../../common/functions";
import { StoreContext } from "../../common/services/store";
import "./sub-filter-layout.css";

export default function SubFilterDetailsView(props) {
  const {
    subFilter,
    contextTotals,
    setSubFilterDisplay,
    filterData,
  } = useContext(StoreContext);

  const [subFilterData, setSubFilterData] = useState(subFilter);
  const [contextViewData, setContextView] = useState(subFilter);
  useEffect((prevProps) => {
    if (prevProps !== props) {
      setSubFilterData(subFilter);
      setContextView(contextViewData);
    }
  });

  let cloneFilterData = filterData?.Field?.filter(
    (d) => d["fWealth"] > 0 && d["fCount"] > 0
  );

  let density = subFilterData.reduce((a, data) => a + parseInt(data.fCount), 0);
  let totalDensity = cloneFilterData.reduce(
    (a, data) => a + parseInt(data.fCount),
    0
  );

  let totalAvgWealth = subFilterData.reduce(
    (a, data) => a + parseFloat(data.fWealth / data.fCount || 0),
    0
  );
  let densityPercent = calculatePercent(density, totalDensity);
  let fWealth = subFilterData
    .reduce((a, data) => a + parseFloat(data.fWealth), 0)
    .toFixed(2);
  let totalWealth = cloneFilterData
    .reduce((a, data) => a + parseFloat(data.fWealth), 0)
    .toFixed(2);
  let fWealthPercent = calculatePercent(fWealth, totalWealth);

  let avgWealth = (totalAvgWealth / subFilterData.length).toFixed(2);

  return (
    //City, Fdensity, Concentration,AvgWealth, Fwealth

    <div>
      <div className="close"></div>

      <div className="title justify-space-between">
        <Typography.Heading4>Sub Filter</Typography.Heading4>
        <div
          className="close-button"
          onClick={() => setSubFilterDisplay(false)}
        ></div>
      </div>
      <Grid padded>
        <Grid.Row>
          <table className="w-100 refinement-custom-table">
            <tr>
              <th></th>
              <th>Value </th>
              <th>Percent of Filter</th>
            </tr>
            <tr>
              <td>Cities : </td>
              <td>{toMoneyFormat(subFilterData.length)}</td>
              <td>
                {calculatePercent(
                  subFilterData.length,
                  cloneFilterData.length
                ) + "%"}
              </td>
            </tr>
            <tr>
              <td>Density : </td>
              <td>{toMoneyFormat(density)}</td>
              <td>{densityPercent + "%"}</td>
            </tr>
            <tr>
              <td>Financial Wealth : </td>
              <td>{toMoneyFormat(fWealth)}</td>
              <td>{fWealthPercent + "%"}</td>
            </tr>
            <tr>
              <td>Average Wealth : </td>
              <td>{"$" + avgWealth + "k"}</td>
            </tr>
          </table>
        </Grid.Row>
        <Grid.Row className="sub-filter">
          <Table
            celled
            structured
            selectable
            striped
            compact
            sortable
            className={"refinement-custom-table"}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="8">City</Table.HeaderCell>
                <Table.HeaderCell colSpan="8">Fdensity</Table.HeaderCell>
                <Table.HeaderCell colSpan="8">Concentration</Table.HeaderCell>
                <Table.HeaderCell colSpan="8">AvgWealth</Table.HeaderCell>
                <Table.HeaderCell colSpan="8">Fwealth</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {subFilterData?.map((data) => {
                return (
                  <Table.Row key={data.City}>
                    <Table.Cell colSpan="8">{data.City}</Table.Cell>
                    <Table.Cell colSpan="8">{data.fCount}</Table.Cell>
                    <Table.Cell colSpan="8">
                      {((data.fCount / data.Count) * 100).toFixed(2)}%
                    </Table.Cell>
                    <Table.Cell colSpan="8">
                      {"$" + toMoneyFormat(data.fWealth / data.fCount, 2) + "k"}
                    </Table.Cell>
                    <Table.Cell colSpan="8">
                      {"$" + toMoneyFormat(parseFloat(data.fWealth), 2) + "k"}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Grid.Row>
      </Grid>
    </div>
  );
}
