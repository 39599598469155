import { Typography } from "@iss/client-react-components";
import React from "react";
import { toMoneyFormat } from "../../../common/functions";
import "./contextview.css";
export default function ContextView(props) {
  return (
    <div className="fund-metadata-box">
      <div className="flex-vertical">
        <Typography.SubText1>Context</Typography.SubText1>
        <Typography.Heading2>Canada</Typography.Heading2>
      </div>
      <div className="vertical-line-divider "></div>
      <div className="flex-vertical">
        <Typography.SubText1>Households</Typography.SubText1>
        <Typography.Heading2>
          {toMoneyFormat(props.contextTotals?.households)}
        </Typography.Heading2>
      </div>
      <div className="vertical-line-divider "></div>
      <div className="flex-vertical">
        <Typography.SubText1>Financial Wealth</Typography.SubText1>
        <Typography.Heading2>
          {"$" + toMoneyFormat(props.contextTotals?.Wealth) + "k"}
        </Typography.Heading2>
      </div>
      <div className="vertical-line-divider "></div>
      <div className="flex-vertical">
        <Typography.SubText1>Avg Financial Wealth</Typography.SubText1>
        <Typography.Heading2>
          {"$" + props.contextTotals?.avgWealth.toFixed(0) + "k"}
        </Typography.Heading2>
      </div>
      <div className="vertical-line-divider "></div>
      <div className="flex-vertical">
        <Typography.SubText1>Cities</Typography.SubText1>
        <Typography.Heading2>
          {toMoneyFormat(props.contextTotals?.cities)}
        </Typography.Heading2>
      </div>
    </div>
  );
}
