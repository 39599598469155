import _ from "lodash";

// Const Data

export const keyList = {
  age: ["AGE1h", "AGE2h", "AGE3h", "AGE4h", "AGE5h", "AGE6h", "AGE7h"],
  ageOther: ["AGE1c", "AGE2c", "AGE3c", "AGE4c", "AGE5c", "AGE6c", "AGE7c"],
  ageWealth: ["AGE1w", "AGE2w", "AGE3w", "AGE4w", "AGE5w", "AGE6w", "AGE7w"],
  fage: ["fAGE1c", "fAGE2c", "fAGE3c", "fAGE4c", "fAGE5c", "fAGE6c", "fAGE7c"],
  wealth: ["CFA1h", "CFA2h", "CFA3h", "CFA4h", "CFA5h", "CFA6h", "CFA7h"],
  wealthOther: ["CFA1c", "CFA2c", "CFA3c", "CFA4c", "CFA5c", "CFA6c", "CFA7c"],
  fwealth: [
    "fCFA1c",
    "fCFA2c",
    "fCFA3c",
    "fCFA4c",
    "fCFA5c",
    "fCFA6c",
    "fCFA7c",
  ],
  income: ["INC1h", "INC2h", "INC3h", "INC4h", "INC5h", "INC6h", "INC7h"],
  incomeOther: ["INC1c", "INC2c", "INC3c", "INC4c", "INC5c", "INC6c", "INC7c"],
  fincome: [
    "fINC1c",
    "fINC2c",
    "fINC3c",
    "fINC4c",
    "fINC5c",
    "fINC6c",
    "fINC7c",
  ],
};

const cssClasses = {
  nonFilterCssClasses: [
    "context-color-1",
    "context-color-2",
    "context-color-3",
    "context-color-4",
    "context-color-5",
  ],
  filterCssClasses: [
    "filter-color-1",
    "filter-color-2",
    "filter-color-3",
    "filter-color-4",
    "filter-color-5",
  ],
};

// Exported Functions

export const getWeight = (data, context, head, decimals = 2, string = true) => {
  let sum = data.reduce((a, b) => a + parseFloat(b[head][0] || 0), 0);
  let value = data.find((d) => d.value === context)[head][0];
  if (string) {
    return (
      (((parseFloat(value) || 0) / (parseFloat(sum) || 0)) * 100).toFixed(
        decimals
      ) + "%"
    );
  } else {
    return ((parseFloat(value) || 0) / (parseFloat(sum) || 0)) * 100;
  }
};

export const getNewWeight = (sum, value, decimals = 2) => {
  if (decimals > 0) {
    return (((parseFloat(value) || 0) / (parseFloat(sum) || 0)) * 100).toFixed(
      decimals
    );
  } else {
    return ((parseFloat(value) || 0) / (parseFloat(sum) || 0)) * 100;
  }
};

export const getAgeWeight = (data, context, head, list) => {
  if (data != undefined) {
    let sum = getRowSum(context, data, list);
    let value = data.find((d) => d.value === context)[head];
    return +((parseFloat(value || 0) / parseFloat(sum)) * 100) || 0;
  }
};

export const toMoneyFormat = (value, fixed = 1) => {
  value =
    fixed === 0 ? Math.round(parseFloat(value) || 0) : parseFloat(value) || 0;
  fixed = fixed === 0 ? 1 : fixed;
  let val = value.toFixed(fixed).replace(/\d(?=(\d{3})+\.)/g, "$&,");

  return fixed != 1 ? val : val.split(".")[0];
};

export const getMaxValue = (data, field) => {
  // 2024/06/24 - shetdha - Added following if statement to handle empty data array
  if (data?.length === 0) {
    return 0;
  }
  let maxValue = data?.reduce((prev, current) =>
    (parseFloat(field in prev ? prev[field][0] : 0) || 0) >=
    (parseFloat(field in current ? current[field][0] : 0) || 0)
      ? prev
      : current
  )?.[field];
  return parseFloat(maxValue) || 0;
};

export const getMinValue = (data, field) => {
  // 2024/06/24 - shetdha - Added following if statement to handle empty data array
  if (data?.length === 0) {
    return 0;
  }
  let minValue = data?.reduce((prev, current) =>
    (parseFloat(field in prev ? prev[field][0] : 0) || 0) <=
    (parseFloat(field in current ? current[field][0] : 0) || 0)
      ? prev
      : current
  )?.[field];
  return parseFloat(minValue) || 0;
};

export const paginate = (data, pageSize, currentPage) => {
  return data?.slice((currentPage - 1) * pageSize, currentPage * pageSize);
};

export const getColorClass = (min, max, value, filter = false) => {
  min = min > 0 ? min : 0.01;
  let interval = (max - min) / 5;
  let cssClassType = filter ? "filterCssClasses" : "nonFilterCssClasses";
  let ranges = [
    {
      rangeMin: min,
      rangeMax: interval * 1 + min,
      cssClassName: cssClasses[cssClassType][0],
    },
    {
      rangeMin: interval * 1 + min,
      rangeMax: interval * 2 + min,
      cssClassName: cssClasses[cssClassType][1],
    },
    {
      rangeMin: interval * 2 + min,
      rangeMax: interval * 3 + min,
      cssClassName: cssClasses[cssClassType][2],
    },
    {
      rangeMin: interval * 3 + min,
      rangeMax: interval * 4 + min,
      cssClassName: cssClasses[cssClassType][3],
    },
    {
      rangeMin: interval * 4 + min,
      rangeMax: max + 1,
      cssClassName: cssClasses[cssClassType][4],
    },
  ];

  return ranges.find((r) => {
    return _.inRange(value, r.rangeMin, r.rangeMax + 1);
  })?.cssClassName;
};

const getRowSum = (value, data, keyList) => {
  let sum = 0;
  let record = data.find((d) => d.value === value);
  keyList.forEach((d) => {
    sum = sum + (d in record ? parseFloat(record[d][0]) : 0);
  });
  return sum;
};

export const getNewRowSum = (record, keyList) => {
  let sum = 0;
  keyList.forEach((d) => {
    sum = sum + (d in record ? parseFloat(record[d][0]) : 0);
  });
  return sum;
};

export const getSum = (arr, key) => {
  if (arr === undefined || arr === null) {
    return;
  }
  let sum = 0;
  arr.map((d) => {
    sum += key in d ? parseFloat(d[key] || 0) : 0;
  });
  return sum;
  // return arr.reduce((a, b) => parseFloat(a) + parseFloat(b[key] || 0), 0);
};

export const calculatePercent = (value, outof) => {
  outof = outof > 0 ? outof : 1;
  return ((value / outof) * 100).toFixed(2);
};

export const getSortAttributes = (sortOn, props) => {
  return {
    sorted:
      props.currentSort.sortOn === sortOn ? props.currentSort.sortDir : false,
    onClick: () => {
      props.onSort(sortOn);
    },
  };
};

export const checkDecimal = (value) => {
  // Check for NaN's
  if (parseFloat(value || 0) === 0) {
    return 0;
  }

  // All Others for 100
  if (parseInt(value || 0) === 100) {
    return 100;
  } else {
    return value;
  }
};
