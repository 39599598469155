import axios from "axios";
import OktaAuth from "@okta/okta-auth-js";
import { oktaAuthConfig } from "../security/config";
export default class HttpBaseService {
  constructor() {
    this.oktaAuth = new OktaAuth(oktaAuthConfig);
    axios.defaults.baseURL = "{{WEALTHMINE_BACKEND_HOST}}";
    axios.defaults.withCredentials = true;
    // axios.defaults.headers.common["Content-Type"] =
    //   "application/xml; charset=utf-8";
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.headers.put["Content-Type"] = "application/json";


    let CancelToken = axios.CancelToken;
    this.source = CancelToken.source();
    axios.defaults.cancelToken = this.source.token;
  }

  createHeaders = () => {
    const token = this.oktaAuth.getAccessToken();
    return {
      'Authorization': `Bearer ${token}`
    };
  }

  GET(url, params) {
    return new Promise((resolve, reject) => {
      return axios
        .get(url, {
          headers: this.createHeaders(),
          params: params,
        })
        .then((res) => {
          if (res !== undefined) {
            // xml2js.parseString(res.data, (err, res) => resolve(res?.root));
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // 2023/04/04 - shetdha - Added method for POST request
  POST(url, body) {
    return new Promise((resolve, reject) => {
      return axios
        .post(url, JSON.stringify(body), {
          headers: this.createHeaders(),
        })
        .then((res) => {
          if (res !== undefined) {
            // xml2js.parseString(res.data, (err, res) => resolve(res?.root));
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    })
  }

  POST_DOWNLOAD_FILE(url, body) {
    let config = {
      headers: this.createHeaders(),
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      return axios
        .post(url, body, config)
        .then((data) => {
          if (data !== undefined) {
            resolve(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // GETFILE(url, params) {
  //   return new Promise((resolve, reject) => {
  //     return axios
  //       .get(url, {
  //         headers: this.createHeaders(),
  //         params: params,
  //         responseType: "blob",
  //         // baseURL: "http://localhost",
  //       })
  //       .then((res) => {
  //         if (res !== undefined) {
  //           resolve(res);
  //         }
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // }

  CANCEL() {
    this.source.cancel("Operation Cancelled");
  }
}
