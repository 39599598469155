import { Navigation, PageContent } from "@iss/client-react-components";
import React from "react";
import wealthmineVideo from "../../common/assets/WealthMINE.mp4";

export function OverViewVideoLayout() {
  // let baseUrl =
  //   window.location.protocol +
  //   "//" +
  //   window.location.hostname +
  //   (window.location.port ? ":" + window.location.port : "");

  // let videoUrl = baseUrl + "/WMstreamVideo.php";
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Overview - Video"></Navigation.PageHeader>
        <p style={{ lineHeight: "200%" }}>
          The following video provides an introduction to the WealthMINE data,
          methodology and functionality. For any additional questions, please
          contact us.
        </p>

        <div style={{ maxWidth: "1280px" }}>
          <div
            style={{
              position: "relative",
              overflow: "hidden",
            }}
          >
            {/* <video height="450px" src={videoUrl} controls></video> */}
            <video height="450px" src={wealthmineVideo} controls></video>
          </div>
        </div>
      </PageContent>
    </div>
  );
}
