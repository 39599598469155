import { Typography } from "@iss/client-react-components";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { calculatePercent } from "../../../common/functions";
import CityTable from "./cityFilterTable";
export const CityOnFilterConcentration = (props) => {
  const DEFAULT_STATE = [
    {
      filter_concentration: "90% Above",
      low_density: 0,
      mid_density: 0,
      high_density: 0,
      ultra_high_density: 0,
      cell_row: 0,
      cell_col: 0,
      total: 0,
    },
    {
      filter_concentration: "80% - 90%",
      low_density: 0,
      mid_density: 0,
      high_density: 0,
      ultra_high_density: 0,
      cell_row: 0,
      cell_col: 0,
      total: 0,
    },
    {
      filter_concentration: "70% - 80%",
      low_density: 0,
      mid_density: 0,
      high_density: 0,
      ultra_high_density: 0,
      cell_row: 0,
      cell_col: 0,
      total: 0,
    },
    {
      filter_concentration: "60% - 70%",
      low_density: 0,
      mid_density: 0,
      high_density: 0,
      ultra_high_density: 0,
      cell_row: 0,
      cell_col: 0,
      total: 0,
    },
    {
      filter_concentration: "50% - 60%",
      low_density: 0,
      mid_density: 0,
      high_density: 0,
      ultra_high_density: 0,
      cell_row: 0,
      cell_col: 0,
      total: 0,
    },
    {
      filter_concentration: "40% - 50%",
      low_density: 0,
      mid_density: 0,
      high_density: 0,
      ultra_high_density: 0,
      cell_row: 0,
      cell_col: 0,
      total: 0,
    },
    {
      filter_concentration: "30% - 40%",
      low_density: 0,
      mid_density: 0,
      high_density: 0,
      ultra_high_density: 0,
      cell_row: 0,
      cell_col: 0,
      total: 0,
    },
    {
      filter_concentration: "20% - 30%",
      low_density: 0,
      mid_density: 0,
      high_density: 0,
      ultra_high_density: 0,
      cell_row: 0,
      cell_col: 0,
      total: 0,
    },
    {
      filter_concentration: "10% - 20%",
      low_density: 0,
      mid_density: 0,
      high_density: 0,
      ultra_high_density: 0,
      cell_row: 0,
      cell_col: 0,
      total: 0,
    },
    {
      filter_concentration: "0% - 10%",
      low_density: 0,
      mid_density: 0,
      high_density: 0,
      ultra_high_density: 0,
      cell_row: 0,
      cell_col: 0,
      total: 0,
    },
    {
      filter_concentration: "Total",
      low_density: 0,
      mid_density: 0,
      high_density: 0,
      ultra_high_density: 0,
      total: 0,
    },
  ];
  const [totalfCities, setTotalfCities] = useState(0);
  const [avgWealth, setAvgWealth] = useState(0);
  const [masterData, setMasterData] = useState([]);
  const [filteredCell, setFilteredCell] = useState(_.cloneDeep(DEFAULT_STATE));
  const [city, setCity] = useState([]);

  useEffect(() => {
    let masterData = { ...props.masterData };
    setMasterData(masterData);
    getFilteredCellData(masterData);
  }, [props.masterData]);

  let columns = [
    {
      columnType: "text",
      field: "filter_concentration",
      label: "Concentration",
    },
    {
      columnType: "text",
      field: "low_density",
      label: "Low ( 0-2k )",
    },
    {
      columnType: "text",
      field: "mid_density",
      label: "Med ( 2-20k )",
    },
    {
      columnType: "text",
      field: "high_density",
      label: "High ( 20-200k )",
    },
    {
      columnType: "text",
      field: "ultra_high_density",
      label: "Ultra High ( 200k plus )",
    },
    {
      columnType: "text",
      field: "total",
      label: "Total",
    },
  ];

  let columns1 = [
    {
      columnType: "text",
      field: "filter_concentration",
      label: "",
    },
    {
      columnType: "text",
      field: "low_density",
      label: "Low ( 0-2k )",
    },
    {
      columnType: "text",
      field: "mid_density",
      label: "Med ( 2-20k )",
    },
    {
      columnType: "text",
      field: "high_density",
      label: "High ( 20-200k )",
    },
    {
      columnType: "text",
      field: "ultra_high_density",
      label: "Ultra High ( 200k plus )",
    },
  ];

  let filteredCellData = [];
  let cityData = [];
  function getFilteredCellData() {
    // console.log(props.masterData  , masterData)
    let data = props.masterData;
    if (data["Field"]) {
      //console.log(data["Field"])
      let filterData = data["Field"]?.map((data) => ({
        City: data.City[0] ? data.City[0] : " ",
        Count: data.Count[0] ? data.Count[0] : 0,
        fCount: data.fCount[0] ? data.fCount[0] : 0,
        fWealth: data.fWealth[0] ? data.fWealth[0] : 0,
      }));

      cityData = filterData.map((data) => ({
        ...data,
        householdConc: (data.fCount / data.Count) * 100,
        density: data.Count,
      }));
      setCity(cityData);

      let filter_conc = [
        "0% - 10%",
        "10% - 20%",
        "20% - 30%",
        "30% - 40%",
        "40% - 50%",
        "50% - 60%",
        "60% - 70%",
        "70% - 80%",
        "80% - 90%",
        "90% Above",
      ];
      let row = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      let avgWealth = {
        low_density_avgWealth: 0,
        low_density_city: 0,
        mid_density_avgWealth: 0,
        mid_density_city: 0,
        high_density_avgWealth: 0,
        high_density_city: 0,
        ultra_high_density_avgWealth: 0,
        ultra_high_density_city: 0,
      };
      for (let i = 0; i < 10; i++) {
        filteredCellData[i] = {
          filter_concentration: filter_conc[i],
          low_density: 0,
          mid_density: 0,
          high_density: 0,
          ultra_high_density: 0,
          cell_row: row[i],
          cell_col: 0,
        };
        var count0to2k = 0;
        var count2kto20k = 0;
        var count20kto200k = 0;
        var count200k = 0;
        for (var j = 0; j < cityData.length; j++) {
          if (
            cityData[j]["householdConc"] > i * 10 &&
            cityData[j]["householdConc"] <= (i + 1) * 10
          ) {
            if (cityData[j]["density"] >= 0 && cityData[j]["density"] <= 2000) {
              filteredCellData[i]["low_density"] = ++count0to2k;

              avgWealth.low_density_avgWealth +=
                parseFloat(cityData[j]["fWealth"] || 0) /
                parseInt(cityData[j]["fCount"] || 0);
              avgWealth.low_density_city += 1;
            } else if (
              cityData[j]["density"] > 2000 &&
              cityData[j]["density"] <= 20000
            ) {
              filteredCellData[i]["mid_density"] = ++count2kto20k;
              avgWealth.mid_density_avgWealth +=
                parseFloat(cityData[j]["fWealth"] || 0) /
                parseInt(cityData[j]["fCount"] || 0);
              avgWealth.mid_density_city += 1;
            } else if (
              cityData[j]["density"] > 20000 &&
              cityData[j]["density"] <= 200000
            ) {
              filteredCellData[i]["high_density"] = ++count20kto200k;
              avgWealth.high_density_avgWealth +=
                parseFloat(cityData[j]["fWealth"] || 0) /
                parseInt(cityData[j]["fCount"] || 0);
              avgWealth.high_density_city += 1;
            } else if (cityData[j]["density"] >= 200000) {
              filteredCellData[i]["ultra_high_density"] = ++count200k;
              avgWealth.ultra_high_density_avgWealth +=
                parseFloat(cityData[j]["fWealth"] || 0) /
                parseInt(cityData[j]["fCount"] || 0);
              avgWealth.ultra_high_density_city += 1;
            }
          }
          filteredCellData[i]["total"] =
            filteredCellData[i]["low_density"] +
            filteredCellData[i]["mid_density"] +
            filteredCellData[i]["high_density"] +
            filteredCellData[i]["ultra_high_density"];
        }
      }

      filteredCellData.reverse();

      let totalDensity = {
        filter_concentration: "Total",
        low_density: 0,
        mid_density: 0,
        high_density: 0,
        ultra_high_density: 0,
        total: 0,
        cell_row: 10,
      };

      for (var i = 0; i < filteredCellData.length; i++) {
        totalDensity.low_density =
          totalDensity.low_density + filteredCellData[i]["low_density"];
        totalDensity.mid_density =
          totalDensity.mid_density + filteredCellData[i]["mid_density"];
        totalDensity.high_density =
          totalDensity.high_density + filteredCellData[i]["high_density"];
        totalDensity.ultra_high_density =
          totalDensity.ultra_high_density +
          filteredCellData[i]["ultra_high_density"];
        totalDensity.total = totalDensity.total + filteredCellData[i]["total"];
      }

      filteredCellData.push(totalDensity);
      setFilteredCell(filteredCellData);
      setTotalfCities(totalDensity.total);
      setAvgWealth(avgWealth);
    } else {
      setFilteredCell(_.cloneDeep(DEFAULT_STATE));
      setCity([]);
      setTotalfCities(0);
      setAvgWealth();
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Typography.SubHeading>
          {"Cities based on Filter Concentration (" +
            calculatePercent(totalfCities, props.contextViewData.cities) +
            "%)"}
        </Typography.SubHeading>
      </div>

      {props.masterData && (
        <CityTable
          style={{ padding: "15px 10px" }}
          cellData={filteredCell}
          header={columns}
          masterData={city}
          avgWealth={avgWealth}
        ></CityTable>
      )}
    </div>
  );
};
