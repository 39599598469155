import "@iss/client-react-components/dist/index.css";
import "@iss/client-react-components/fonts/icons/style.css";
import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import React, { useEffect } from "react";
import { BrowserRouter, Route, useHistory } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import "./App.css";
import { ProtectedRoute } from "./common/security/ProtectedRoute";
import { oktaAuthConfig } from "./common/security/config";
import SetupInterceptors from "./common/services/http.interceptor.service";

function App() {
  /*const { authenticationInfo } = useContext(StoreContext);*/
  /*SetupInterceptors();
  return (
    <>
      {authenticationInfo.loggedIn && <ConsoleLayout></ConsoleLayout>}
      {!authenticationInfo.loggedIn && <Login></Login>}
    </>
  );*/
  const history = useHistory();
  const oktaAuth = new OktaAuth(oktaAuthConfig);

  useEffect(() => {
    return () => {
      oktaAuth.options.restoreOriginalUri = undefined;
    };
  }, []);

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <BrowserRouter>
      <Security
        oktaAuth={oktaAuth}
        restoreOriginalUri={restoreOriginalUri}
      >
        <SetupInterceptors />
        <Route path="/login/callback" component={LoginCallback} />
        <ProtectedRoute />
      </Security>
    </BrowserRouter>
  )
}

export default App;
