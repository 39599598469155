import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import {
  checkDecimal,
  getAgeWeight,
  getColorClass,
  getMaxValue,
  getMinValue,
  getNewRowSum,
  getSortAttributes,
  getSum,
  getWeight,
  keyList
} from "../../common/functions";

export default function IncomeView(props) {
  const [state, setState] = useState({
    minMax: {},
    keys: { income: [], fincome: [], incomeContext: [] },
    data: [],
    currentContextTotals: {},
  });

  useEffect(() => {
    if (props.data?.Field?.length > 0) {
      let minMax = {};
      let keys = {};
      keys["incomeContext"] = keyList.income;
      if (["age", "income", "wealth"].find((d) => d === props.selectedKey)) {
        keys["income"] = keyList.incomeOther;
        keys["fincome"] = keyList.fincome;
        keyList.incomeOther.forEach((d) => {
          minMax[d] = {
            min: getMinValue(props.data.Field, d),
            max: getMaxValue(props.data.Field, d),
          };
        });

        keyList.fincome.forEach((d) => {
          minMax[d] = {
            min: getMinValue(props.data.Field, d),
            max: getMaxValue(props.data.Field, d),
          };
        });
      } else {
        keys["income"] = keyList.income;
        keys["fincome"] = keyList.fincome;
        keyList.income.forEach((d) => {
          minMax[d] = {
            min: getMinValue(props.data.Field, d),
            max: getMaxValue(props.data.Field, d),
          };
        });
        keyList.fincome.forEach((d) => {
          minMax[d] = {
            min: getMinValue(props.data.Field, d),
            max: getMaxValue(props.data.Field, d),
          };
        });
      }

      ["Count", "fCount"].forEach((d) => {
        minMax[d] = {
          min: getMinValue(props.data.Field, d),
          max: getMaxValue(props.data.Field, d),
        };
      });

      let currentContextTotals = {};
      keys["income"].map((index) => {
        currentContextTotals[index] = getSum(props.data.Field, index);
      });
      if (props.isFiltered) {
        keys["fincome"].map((index) => {
          currentContextTotals[index] = getSum(props.data.Field, index);
        });
      }
      currentContextTotals["sumInc"] = keys["income"].reduce(
        (a, b) => a + currentContextTotals[b],
        0
      );
      currentContextTotals["fSumInc"] = keys["fincome"].reduce(
        (a, b) => a + currentContextTotals[b],
        0
      );

      let data = props.data?.Field;
      data = data.map((record) => ({
        ...record,
        sum: getNewRowSum(record, keys["income"]),
        fsum: getNewRowSum(record, keys["fincome"]),
      }));

      for (let record in data) {
        keys["income"].map((key) => {
          data[record][key + "weight"] = [
            (data[record][key] / data[record]["sum"]) * 100,
          ];
        });
        keys["fincome"].map((key) => {
          data[record][key + "weight"] = [
            (data[record][key] / data[record]["fsum"]) * 100,
          ];
        });
      }
      keys["income"].map((key) => {
        minMax[key + "weight"] = {
          min: getMinValue(data, key + "weight"),
          max: getMaxValue(data, key + "weight"),
        };
      });

      keys["fincome"].map((key) => {
        minMax[key + "weight"] = {
          min: getMinValue(data, key + "weight"),
          max: getMaxValue(data, key + "weight"),
        };
      });

      setState({
        minMax: minMax,
        keys: keys,
        data: data,
        currentContextTotals: { ...currentContextTotals },
        contextTotals: props.contextInfo,
      });
    }
  }, [props]);
  return (
    <Table
      celled
      structured
      selectable
      striped
      compact
      sortable
      className="refinement-custom-table right-align-text"
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            rowSpan="2"
            textAlign="center"
            style={{ textTransform: "uppercase" }}
          >
            {props.selectedKey}
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="8" textAlign="center">
            Context
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="8" textAlign="center">
            Filter
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Weight</Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("inc1h", props)}>
            $0 <br /> $50k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("inc2h", props)}>
            $50k <br /> $75k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("inc3h", props)}>
            $75k <br /> $100k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("inc4h", props)}>
            $100k <br /> $150k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("inc5h", props)}>
            $150k <br /> $200k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("inc6h", props)}>
            $200k <br /> $250k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("inc7h", props)}>
            $250k <br /> plus
          </Table.HeaderCell>
          <Table.HeaderCell>Weight</Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("finc1c", props)}>
            $0 <br /> $50k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("finc2c", props)}>
            $50k <br /> $75k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("finc3c", props)}>
            $75k <br /> $100k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("finc4c", props)}>
            $100k <br /> $150k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("finc5c", props)}>
            $150k <br /> $200k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("finc6c", props)}>
            $200k <br /> $250k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("finc7c", props)}>
            $250k <br /> plus
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.Cell style={{ color: "#274398" }} className="value-field-td">
            Canada
          </Table.Cell>
          <Table.Cell>100%</Table.Cell>
          {state.keys.incomeContext.map((index) => {
            return (
              <Table.Cell key={index}>
                {(
                  (state.contextTotals[index] / state.contextTotals?.sumInc) *
                  100
                ).toFixed(0) + "%"}
              </Table.Cell>
            );
          })}
          <Table.Cell>{props.isFiltered && "100%"}</Table.Cell>
          {state.keys.fincome.map((value, index) => {
            return (
              <Table.Cell key={index}>
                {props.isFiltered &&
                  (!props.filterDimensionStatus.incomeFilter ||
                    props.filterDimensions["inc" + (index + 1)] !== 8) &&
                  (
                    (state.contextTotals[value] / state.contextTotals.fSumInc) *
                    100
                  ).toFixed(0) + "%"}
              </Table.Cell>
            );
          })}
        </Table.Row>
        <Table.Row>
          <Table.Cell style={{ color: "#274398" }} className="value-field-td">
            Context: {props.context}
          </Table.Cell>
          <Table.Cell>
            {checkDecimal(
              parseFloat(
                (state.currentContextTotals?.sumInc /
                  state.contextTotals?.sumInc) *
                  100 || 0
              ).toFixed(1)
            ) + "%"}
          </Table.Cell>

          {state.keys.income.map((index) => {
            return (
              <Table.Cell key={index}>
                {(
                  (state.currentContextTotals[index] /
                    state.currentContextTotals?.sumInc) *
                  100
                ).toFixed(0) + "%"}
              </Table.Cell>
            );
          })}
          <Table.Cell>
            {props.isFiltered &&
              checkDecimal(
                (
                  (state.currentContextTotals?.fSumInc /
                    state.contextTotals?.fSumInc) *
                  100
                ).toFixed(1)
              ) + "%"}
          </Table.Cell>
          {state.keys.fincome.map((value, index) => {
            return (
              <Table.Cell key={index}>
                {props.isFiltered &&
                  (!props.filterDimensionStatus.incomeFilter ||
                    props.filterDimensions["inc" + (index + 1)] !== 8) &&
                  (
                    (state.currentContextTotals[value] /
                      state.currentContextTotals?.fSumInc) *
                    100
                  ).toFixed(0) + "%"}
              </Table.Cell>
            );
          })}
        </Table.Row>
        <Table.Row>
          {[...Array(17).keys()].map((d) => {
            return <Table.Cell key={d}/>;
          })}
        </Table.Row>
        {state.data.length > 0 &&
          state.data.map((data, index) => {
            data.value = data[props.groupInfo.valueField];
            data.valueField =
              index + 1 + ". " + data[props.groupInfo.valueField];
            data.cWeight = getWeight(state.data, data.value, "Count", 0, false);
            data.fWeight = getWeight(
              state.data,
              data.value,
              "fCount",
              0,
              false
            );
            return (
              <Table.Row onClick={() => props.onClick(data)} key={index}>
                <Table.Cell className="value-field-td">
                  {data.valueField}
                </Table.Cell>
                <Table.Cell
                  className={getColorClass(
                    state.minMax["Count"]?.min,
                    state.minMax["Count"]?.max,
                    data["Count"]
                  )}
                >
                  {data.cWeight > 0 ? data.cWeight.toFixed(1) + "%" : ""}
                </Table.Cell>
                {[...Array(7).keys()].map((index) => {
                  let weight = getAgeWeight(
                    state.data,
                    data.value,
                    state.keys.income[index],
                    state.keys.income
                  );
                  return (
                    <Table.Cell
                      key={index}
                      className={getColorClass(
                        state.minMax[state.keys.income[index] + "weight"]?.min,
                        state.minMax[state.keys.income[index] + "weight"]?.max,
                        data[state.keys.income[index] + "weight"]
                      )}
                    >
                      {weight > 0 ? weight.toFixed(1) + "%" : ""}
                    </Table.Cell>
                  );
                })}
                <Table.Cell
                  className={
                    props.isFiltered &&
                    getColorClass(
                      state.minMax["fCount"]?.min,
                      state.minMax["fCount"]?.max,
                      data["fCount"],
                      true
                    )
                  }
                >
                  {props.isFiltered && data.fWeight > 0
                    ? data.fWeight.toFixed(1) + "%"
                    : ""}
                </Table.Cell>
                {[...Array(7).keys()].map((index) => {
                  let weight = getAgeWeight(
                    state.data,
                    data.value,
                    state.keys.fincome[index],
                    state.keys.fincome
                  );
                  return (
                    <Table.Cell
                      key={index}
                      className={
                        props.isFiltered &&
                        (!props.filterDimensionStatus.incomeFilter ||
                          props.filterDimensions["inc" + (index + 1)] !== 8) &&
                        getColorClass(
                          state.minMax[state.keys.fincome[index] + "weight"]
                            ?.min,
                          state.minMax[state.keys.fincome[index] + "weight"]
                            ?.max,
                          data[state.keys.fincome[index] + "weight"],
                          true
                        )
                      }
                    >
                      {props.isFiltered &&
                      (!props.filterDimensionStatus.incomeFilter ||
                        props.filterDimensions["inc" + (index + 1)] !== 8) &&
                      weight > 0
                        ? weight.toFixed(1) + "%"
                        : ""}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  );
}
