import { TextInline, Typography } from "@iss/client-react-components";
import React, { useState } from "react";
import "./filterrefinement.css";

export const FilterRefinementView = (props) => {
  const handleReset = (arr) => {
    arr.forEach((item) => props.onChange({ attribute: item, value: 0 }));
    //props.onChange({ arr[0], value });
  };
  //let disableFilter = false;
  const [disableLowFilter, setdisableLowFilter] = useState(false);
  const [disableMediumFilter, setdisableMediumFilter] = useState(false);
  const [disableHighFilter, setdisableHighFilter] = useState(false);
  const [disableUltraHighFilter, setdisableUltraHighFilter] = useState(false);

  return (
    <div>
      <div className="title">
        <Typography.Heading4>Filter Refinement</Typography.Heading4>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <table className={"refinement-custom-table"}>
          <thead>
            <tr>
              <th rowSpan="2"></th>
              <th rowSpan="2">City Household Density</th>
              <th>Filter HH Concentration</th>
              <th>Filter Density</th>
              <th>Filter Average Wealth</th>
            </tr>
            <tr>
              <th>Greater Than:</th>
              <th>Greater Than:</th>
              <th>Greater Than:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ width: "40px" }}>
                <i
                  className={
                    !disableLowFilter
                      ? "icon-delete-a disabled-icon"
                      : "icon-add-a"
                  }
                  onClick={() => {
                    setdisableLowFilter(!disableLowFilter);
                    handleReset(["cmL", "dmL", "awmL"]);
                  }}
                ></i>
              </td>
              <td>Low</td>
              <td>
                <TextInline
                  type="text"
                  value={(
                    parseFloat(props.filterRefinementData.cmL) ? parseFloat(props.filterRefinementData.cmL) * 100 : "0"
                  )?.toString()}
                  placeholder="0"
                  disabled={disableLowFilter}
                  onChange={(val) => {
                    props.onChange({
                    attribute: "cmL",
                      value: val / 100,
                    });
                  }}
                />
              </td>
              <td>
                <TextInline
                  type="text"
                  value={isNaN(props.filterRefinementData.dmL) ? "0" : props.filterRefinementData.dmL?.toString()}
                  disabled={disableLowFilter}
                  onChange={(val) => {
                    props.onChange({
                      attribute: "dmL",
                      value: val,
                    });
                  }}
                />
              </td>
              <td>
                <TextInline
                  type="text"
                  value={isNaN(props.filterRefinementData.awmL) ? "0" : props.filterRefinementData.awmL?.toString()}
                  placeholder="0"
                  disabled={disableLowFilter}
                  onChange={(val) => {
                    props.onChange({
                      attribute: "awmL",
                      value: val,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <i
                  className={
                    !disableMediumFilter
                      ? "icon-delete-a disabled-icon"
                      : "icon-add-a"
                  }
                  onClick={() => {
                    setdisableMediumFilter(!disableMediumFilter);
                    handleReset(["cmM", "dmM", "awmM"]);
                  }}
                ></i>
              </td>
              <td>Medium</td>
              <td>
                <TextInline
                  type="text"
                  value={(props.filterRefinementData.cmM * 100) ? props.filterRefinementData.cmM * 100?.toString() : "0"}
                  placeholder="0"
                  disabled={disableMediumFilter}
                  onChange={(val) => {
                    props.onChange({
                      attribute: "cmM",
                      value: val / 100,
                    });
                  }}
                />
              </td>
              <td>
                <TextInline
                  type="text"
                  value={isNaN(props.filterRefinementData.dmM) ? "0" :props.filterRefinementData.dmM?.toString()}
                  placeholder="0"
                  disabled={disableMediumFilter}
                  onChange={(val) => {
                    props.onChange({
                      attribute: "dmM",
                      value: val,
                    });
                  }}
                />
              </td>
              <td>
                <TextInline
                  type="text"
                  value={isNaN(props.filterRefinementData.awmM) ? "0" :props.filterRefinementData.awmM?.toString()}
                  placeholder="0"
                  disabled={disableMediumFilter}
                  onChange={(val) => {
                    props.onChange({
                      attribute: "awmM",
                      value: val,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <i
                  className={
                    !disableHighFilter
                      ? "icon-delete-a disabled-icon"
                      : "icon-add-a"
                  }
                  onClick={() => {
                    setdisableHighFilter(!disableHighFilter);
                    handleReset(["cmH", "dmH", "awmH"]);
                  }}
                ></i>
              </td>
              <td>High</td>
              <td>
                <TextInline
                  type="text"
                  value={props.filterRefinementData.cmH * 100 ? (props.filterRefinementData.cmH * 100)?.toString() : "0"}
                  placeholder="0"
                  disabled={disableHighFilter}
                  className="filter-text"
                  onChange={(val) => {
                    props.onChange({
                      attribute: "cmH",
                      value: val / 100,
                    });
                  }}
                />
              </td>
              <td>
                <TextInline
                  type="text"
                  value={isNaN(props.filterRefinementData.dmH) ? "0" : props.filterRefinementData.dmH?.toString()}
                  placeholder="0"
                  disabled={disableHighFilter}
                  onChange={(val) => {
                    props.onChange({
                      attribute: "dmH",
                      value: val,
                    });
                  }}
                />
              </td>
              <td>
                <TextInline
                  type="text"
                  value={isNaN(props.filterRefinementData.awmH) ? "0" :props.filterRefinementData.awmH?.toString()}
                  placeholder="0"
                  disabled={disableHighFilter}
                  onChange={(val) => {
                    props.onChange({
                      attribute: "awmH",
                      value: val,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <i
                  className={
                    !disableUltraHighFilter
                      ? "icon-delete-a disabled-icon"
                      : "icon-add-a"
                  }
                  onClick={() => {
                    setdisableUltraHighFilter(!disableUltraHighFilter);
                    handleReset(["cmUH", "dmUH", "awmUH"]);
                  }}
                ></i>
              </td>
              <td>Ultra High</td>
              <td>
                <TextInline
                  type="text"
                  value={
                    !disableUltraHighFilter
                      ? props.filterRefinementData.cmUH * 100 ? (props.filterRefinementData.cmUH * 100)?.toString() : "0"
                      : "0"
                  }
                  placeholder="0"
                  disabled={disableUltraHighFilter}
                  onChange={(val) => {
                    props.onChange({
                      attribute: "cmUH",
                      value: val / 100,
                    });
                  }}
                />
              </td>
              <td>
                <TextInline
                  type="text"
                  value={isNaN(props.filterRefinementData.dmUH) ? "0" :props.filterRefinementData.dmUH?.toString()}
                  placeholder="0"
                  disabled={disableUltraHighFilter}
                  onChange={(val) => {
                    props.onChange({
                      attribute: "dmUH",
                      value: val,
                    });
                  }}
                />
              </td>
              <td>
                <TextInline
                  type="text"
                  value={isNaN(props.filterRefinementData.awmUH) ? "0" : props.filterRefinementData.awmUH?.toString()}
                  placeholder="0"
                  disabled={disableUltraHighFilter}
                  onChange={(val) => {
                    props.onChange({
                      attribute: "awmUH",
                      value: val,
                    });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
