import { Navigation, PageContent } from "@iss/client-react-components";
import React, { useContext } from "react";
import { Table } from "semantic-ui-react";
import "./helpview.css";
import { StoreContext } from "../../common/services/store";

export function Glossary() {
  const { year } = useContext(StoreContext);
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Glossary"></Navigation.PageHeader>

        <Table className="help-custom-table">
          <Table.HeaderCell style={{ width: "40%" }}>Term</Table.HeaderCell>
          <Table.HeaderCell style={{ width: "60%" }}>
            Definition
          </Table.HeaderCell>
          <Table.Body>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold", textAlign: "center" }}>
                Time Stamp
              </Table.Cell>
              <Table.Cell>Data for {year}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold", textAlign: "center" }}>
                Age
              </Table.Cell>
              <Table.Cell>
                Age is grouped in 7 segments: 0 - 24, 25 - 34, 35 to 44, 45 -
                54, 55 - 64, 65 - 74, and 75+. The household age will fall in
                only one of this seven segments determined by the age of Older
                spouse for Couple families, age of Lone-Parent or age of the
                Non-Family Persons.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold", textAlign: "center" }}>
                Average Wealth
              </Table.Cell>
              <Table.Cell>
                Used as a quality index: wealth divided by the number of
                household in a specific locality.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold", textAlign: "center" }}>
                Census Metropolitan Areas
              </Table.Cell>
              <Table.Cell>
                A "census metropolitan area" (CMA) is a grouping of census
                subdivisions comprising a large urban area (the "urban core")
                and those surrounding "urban fringes" and fringes" with which it
                is closely integrated. To become a CMA, an area must register an
                urban core population of at least 100,000 at the previous
                census. CMA status is retained even if this core population
                later drops below 100,000.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold", textAlign: "center" }}>
                CMA
              </Table.Cell>
              <Table.Cell>Census Metropolitan Areas</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold", textAlign: "center" }}>
                Concentration
              </Table.Cell>
              <Table.Cell>
                Density or wealth of a filter divided by the total density or
                wealth of a locality.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold", textAlign: "center" }}>
                Context
              </Table.Cell>
              <Table.Cell>
                Context is the immediately above aggregation level relative to a
                location, i.e. Ontario is the context of the Toronto CMA (Census
                Municipal Area)
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold", textAlign: "center" }}>
                Density
              </Table.Cell>
              <Table.Cell>Number of households in a location</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold", textAlign: "center" }}>
                Filter Household Density
              </Table.Cell>
              <Table.Cell>The number of households in a filter</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold", textAlign: "center" }}>
                Financial Wealth
              </Table.Cell>
              <Table.Cell>
                Financial Wealth is a broad measure of the financial assets of
                Canadian households. It encompasses those financial instruments
                and investments held for the purpose of accumulating wealth.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold", textAlign: "center" }}>
                Household Concentration
              </Table.Cell>
              <Table.Cell>
                Household Concentration is the ratio of filter household density
                to the total household density of a specific location expressed
                as a percentage.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold", textAlign: "center" }}>
                Income
              </Table.Cell>
              <Table.Cell>
                Income reported from any of the following sources: Labour
                income, Pension income,Interest, investment income, Dividend
                income, and other less significant sources of income.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold", textAlign: "center" }}>
                Total Household Density
              </Table.Cell>
              <Table.Cell>
                Number of households in a locality (i.e. Province, Metro Area,
                City or Municipality)
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold", textAlign: "center" }}>
                Wealth Concentration
              </Table.Cell>
              <Table.Cell>
                Wealth Concentration is the ratio of filter total financial
                wealth to the total financial wealth of a specific location
                expressed as a percentage.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ fontWeight: "bold", textAlign: "center" }}>
                FSA
              </Table.Cell>
              <Table.Cell>Forward Sortation Area</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <br />
      </PageContent>
    </div>
  );
}
