import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import React, { useContext, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import ConsoleLayout from '../../console/consoleLayout';
import HttpBaseService from '../services/http.service';
import { StoreContext } from '../services/store';

export const ProtectedRoute = () => {
  const { oktaAuth, authState } = useOktaAuth();

  const { setUserProfile } = useContext(StoreContext);

  const _http = new HttpBaseService(null);

  const history = useHistory();

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      _http.GET("/user/profile").then((userProfile) => {
        setUserProfile(userProfile);
        history.push("/");
      });
    }
  }, [authState]);

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    } 
    // else {
    //   renewTokens();
    // }
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  const renewTokens = async () => {
    await oktaAuth.tokenManager.renew('idToken');
    await oktaAuth.tokenManager.renew('accessToken');
  }

  return (
    <Route index path="/" component={ConsoleLayout} />
  )
}