import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import {
  checkDecimal,
  getAgeWeight,
  getColorClass,
  getMaxValue,
  getMinValue,
  getNewRowSum,
  getSortAttributes,
  getSum,
  getWeight,
  keyList
} from "../../common/functions";

export default function WealthView(props) {
  const [state, setState] = useState({
    minMax: {},
    keys: { wealth: [], fwealth: [], wealthContext: [] },
    data: [],
  });

  useEffect(() => {
    if (props.data?.Field?.length > 0) {
      let minMax = {};
      let keys = {};
      keys["wealthContext"] = keyList.wealth;
      if (["age", "income", "wealth"].find((d) => d === props.selectedKey)) {
        keys["wealth"] = keyList.wealthOther;
        keys["fwealth"] = keyList.fwealth;
        keyList.wealthOther.forEach((d) => {
          minMax[d] = {
            min: getMinValue(props.data.Field, d),
            max: getMaxValue(props.data.Field, d),
          };
        });

        keyList.fwealth.forEach((d) => {
          minMax[d] = {
            min: getMinValue(props.data.Field, d),
            max: getMaxValue(props.data.Field, d),
          };
        });
      } else {
        keys["wealth"] = keyList.wealth;
        keys["fwealth"] = keyList.fwealth;
        keyList.wealth.forEach((d) => {
          minMax[d] = {
            min: getMinValue(props.data.Field, d),
            max: getMaxValue(props.data.Field, d),
          };
        });
        keyList.fwealth.forEach((d) => {
          minMax[d] = {
            min: getMinValue(props.data.Field, d),
            max: getMaxValue(props.data.Field, d),
          };
        });
      }

      ["Count", "fCount"].forEach((d) => {
        minMax[d] = {
          min: getMinValue(props.data.Field, d),
          max: getMaxValue(props.data.Field, d),
        };
      });

      let currentContextTotals = {};
      keys["wealth"].map((index) => {
        currentContextTotals[index] = getSum(props.data.Field, index);
      });
      if (props.isFiltered) {
        keys["fwealth"].map((index) => {
          currentContextTotals[index] = getSum(props.data.Field, index);
        });
      }
      currentContextTotals["sumWealth"] = keys["wealth"].reduce(
        (a, b) => a + currentContextTotals[b],
        0
      );
      currentContextTotals["fSumWealth"] = keys["fwealth"].reduce(
        (a, b) => a + currentContextTotals[b],
        0
      );

      let data = props.data?.Field;

      data = data.map((record) => ({
        ...record,
        sum: getNewRowSum(record, keys["wealth"]),
        fsum: getNewRowSum(record, keys["fwealth"]),
      }));

      for (let record in data) {
        keys["wealth"].map((key) => {
          data[record][key + "weight"] = [
            (data[record][key] / data[record]["sum"]) * 100,
          ];
        });
        keys["fwealth"].map((key) => {
          data[record][key + "weight"] = [
            (data[record][key] / data[record]["fsum"]) * 100,
          ];
        });
      }
      keys["wealth"].map((key) => {
        minMax[key + "weight"] = {
          min: getMinValue(data, key + "weight"),
          max: getMaxValue(data, key + "weight"),
        };
      });

      keys["fwealth"].map((key) => {
        minMax[key + "weight"] = {
          min: getMinValue(data, key + "weight"),
          max: getMaxValue(data, key + "weight"),
        };
      });

      setState({
        minMax: minMax,
        keys: keys,
        data: data,
        contextTotals: props.contextInfo,
        currentContextTotals: {
          ...currentContextTotals,
        },
      });
    }
  }, [props]);
  return (
    <Table
      celled
      structured
      selectable
      striped
      compact
      sortable
      className="refinement-custom-table right-align-text"
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            rowSpan="2"
            textAlign="center"
            style={{ textTransform: "uppercase" }}
          >
            {props.selectedKey}
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="8" textAlign="center">
            Context
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="8" textAlign="center">
            Filter
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Weight</Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("cfa1h", props)}>
            $0
            <br />
            $50k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("cfa2h", props)}>
            $50k
            <br />
            $100k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("cfa3h", props)}>
            $100k
            <br />
            $250k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("cfa4h", props)}>
            $250k
            <br />
            $500k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("cfa5h", props)}>
            $500k
            <br />
            $750k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("cfa6h", props)}>
            $750k
            <br />
            Mill.
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("cfa7h", props)}>
            Mill.
            <br /> plus
          </Table.HeaderCell>
          <Table.HeaderCell>Weight</Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("fcfa1c", props)}>
            $0
            <br />
            $50k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("fcfa2c", props)}>
            $50k
            <br />
            $100k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("fcfa3c", props)}>
            $100k
            <br />
            $250k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("fcfa4c", props)}>
            $250k
            <br />
            $500k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("fcfa5c", props)}>
            $500k
            <br />
            $750k
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("fcfa6c", props)}>
            $750k
            <br />
            Mill.
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("fcfa7c", props)}>
            Mill.
            <br /> plus
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.Cell style={{ color: "#274398" }} className="value-field-td">
            Canada
          </Table.Cell>
          <Table.Cell>100%</Table.Cell>
          {state.keys.wealthContext.map((index) => {
            return (
              <Table.Cell key={index}>
                {(
                  (state.contextTotals[index] /
                    state.contextTotals?.sumWealth) *
                  100
                ).toFixed(0) + "%"}
              </Table.Cell>
            );
          })}
          <Table.Cell>{props.isFiltered && "100%"}</Table.Cell>
          {state.keys.fwealth.map((value, index) => {
            return (
              <Table.Cell key={index}>
                {props.isFiltered &&
                  (!props.filterDimensionStatus.wealthFilter ||
                    props.filterDimensions["wth" + (index + 1)] !== 8) &&
                  (
                    (state.contextTotals[value] /
                      state.contextTotals.fSumWealth) *
                    100
                  ).toFixed(0) + "%"}
              </Table.Cell>
            );
          })}
        </Table.Row>
        <Table.Row>
          <Table.Cell style={{ color: "#274398" }} className="value-field-td">
            Context: {props.context}
          </Table.Cell>
          <Table.Cell>
            {checkDecimal(
              parseFloat(
                (state.currentContextTotals?.sumWealth /
                  state.contextTotals?.sumWealth) *
                  100 || 0
              ).toFixed(1)
            ) + "%"}
          </Table.Cell>
          {state.keys.wealth.map((index) => {
            return (
              <Table.Cell key={index}>
                {(
                  (state.currentContextTotals[index] /
                    state.currentContextTotals?.sumWealth) *
                  100
                ).toFixed(0) + "%"}
              </Table.Cell>
            );
          })}
          <Table.Cell>
            {props.isFiltered &&
              checkDecimal(
                (
                  (state.currentContextTotals?.fSumWealth /
                    state.contextTotals?.fSumWealth) *
                  100
                ).toFixed(1)
              ) + "%"}
          </Table.Cell>
          {state.keys.fwealth.map((value, index) => {
            return (
              <Table.Cell key={index}>
                {props.isFiltered &&
                  (!props.filterDimensionStatus.wealthFilter ||
                    props.filterDimensions["wth" + (index + 1)] !== 8) &&
                  (
                    (state.currentContextTotals[value] /
                      state.currentContextTotals?.fSumWealth) *
                    100
                  ).toFixed(0) + "%"}
              </Table.Cell>
            );
          })}
        </Table.Row>
        <Table.Row>
          {[...Array(17).keys()].map((d) => {
            return <Table.Cell key={d}/>;
          })}
        </Table.Row>
        {state.data.length > 0 &&
          state.data.map((data, index) => {
            data.value = data[props.groupInfo.valueField];
            data.valueField =
              index + 1 + ". " + data[props.groupInfo.valueField];
            data.cWeight = getWeight(state.data, data.value, "Count", 0, false);
            data.fWeight = getWeight(
              state.data,
              data.value,
              "fCount",
              0,
              false
            );
            return (
              <Table.Row onClick={() => props.onClick(data)} key={index}>
                <Table.Cell className="value-field-td">
                  {data.valueField}
                </Table.Cell>
                <Table.Cell
                  className={getColorClass(
                    state.minMax["Count"]?.min,
                    state.minMax["Count"]?.max,
                    data["Count"]
                  )}
                >
                  {data.cWeight > 0 ? data.cWeight.toFixed(1) + "%" : ""}
                </Table.Cell>
                {[...Array(7).keys()].map((index) => {
                  let weight = getAgeWeight(
                    state.data,
                    data.value,
                    state.keys.wealth[index],
                    state.keys.wealth
                  );
                  return (
                    <Table.Cell
                      key={index}
                      className={getColorClass(
                        state.minMax[state.keys.wealth[index] + "weight"]?.min,
                        state.minMax[state.keys.wealth[index] + "weight"]?.max,
                        data[state.keys.wealth[index] + "weight"]
                      )}
                    >
                      {weight > 0 ? weight.toFixed(1) + "%" : ""}
                    </Table.Cell>
                  );
                })}
                <Table.Cell
                  className={
                    props.isFiltered &&
                    getColorClass(
                      state.minMax["fCount"]?.min,
                      state.minMax["fCount"]?.max,
                      data["fCount"],
                      true
                    )
                  }
                >
                  {props.isFiltered && data.fWeight > 0
                    ? data.fWeight.toFixed(1) + "%"
                    : ""}
                </Table.Cell>
                {[...Array(7).keys()].map((index) => {
                  let weight = getAgeWeight(
                    state.data,
                    data.value,
                    state.keys.fwealth[index],
                    state.keys.fwealth
                  );
                  return (
                    <Table.Cell
                      key={index}
                      className={
                        props.isFiltered &&
                        (!props.filterDimensionStatus.wealthFilter ||
                          props.filterDimensions["wth" + (index + 1)] !== 8) &&
                        getColorClass(
                          state.minMax[state.keys.fwealth[index] + "weight"]
                            ?.min,
                          state.minMax[state.keys.fwealth[index] + "weight"]
                            ?.max,
                          data[state.keys.fwealth[index] + "weight"],
                          true
                        )
                      }
                    >
                      {props.isFiltered &&
                      (!props.filterDimensionStatus.wealthFilter ||
                        props.filterDimensions["wth" + (index + 1)] !== 8) &&
                      weight > 0
                        ? weight.toFixed(1) + "%"
                        : ""}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  );
}
