import { Button, Typography } from "@iss/client-react-components";
import PropTypes from "prop-types";
import React from "react";
import "./multilistchooser.css";
// Prop Definitions
MultiListChooser.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

function MultiListChooser(props) {
  return (
    <div>
      <Typography.SubHeading
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        {props.title}
        {/* <Text disabled value={props.title} /> */}
      </Typography.SubHeading>
      <div className="multi-list-chooser-options">
        {Object.keys(props.items).map((item, index) => {
          return (
            <Button
              small
              kind={props.items[item] ? "primary" : "secondary"}
              key={index}
              onClick={() => props.onSelect(item)}
              style={{ marginBottom: "10px" }}
            >
              {item}
            </Button>
          );
        })}
      </div>
    </div>
  );
}

export default MultiListChooser;
