import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import {
  checkDecimal,
  getAgeWeight,
  getColorClass,
  getMaxValue,
  getMinValue,
  getNewRowSum,
  getNewWeight,
  getSortAttributes,
  getSum,
  keyList
} from "../../common/functions";

export default function AGEView(props) {
  const [state, setState] = useState({
    minMax: {},
    keys: { age: [], fage: [], ageContext: [] },
    data: [],
    currentContextTotals: {},
  });

  useEffect(() => {
    if (props.data?.Field?.length > 0) {
      let minMax = {};
      let keys = {};
      keys["ageContext"] = keyList.age;
      if (["age", "income", "wealth"].find((d) => d === props.selectedKey)) {
        keys["age"] = keyList.ageOther;
        keys["fage"] = keyList.fage;
        keys["age"].forEach((d) => {
          minMax[d] = {
            min: getMinValue(props.data.Field, d),
            max: getMaxValue(props.data.Field, d),
          };
        });

        keys["fage"].forEach((d) => {
          minMax[d] = {
            min: getMinValue(props.data.Field, d),
            max: getMaxValue(props.data.Field, d),
          };
        });
      } else {
        keys["age"] = keyList.age;
        keys["fage"] = keyList.fage;
        keys["age"].forEach((d) => {
          minMax[d] = {
            min: getMinValue(props.data.Field, d),
            max: getMaxValue(props.data.Field, d),
          };
        });
        keys["fage"].forEach((d) => {
          minMax[d] = {
            min: getMinValue(props.data.Field, d),
            max: getMaxValue(props.data.Field, d),
          };
        });
      }

      ["Count", "fCount"].forEach((d) => {
        minMax[d] = {
          min: getMinValue(props.data.Field, d),
          max: getMaxValue(props.data.Field, d),
        };
      });

      let currentContextTotals = {};
      keys["age"].map((index) => {
        currentContextTotals[index] = getSum(props.data.Field, index);
      });
      if (props.isFiltered) {
        keys["fage"].map((index) => {
          currentContextTotals[index] = getSum(props.data.Field, index);
        });
      }
      currentContextTotals["sumAge"] = keys["age"].reduce(
        (a, b) => a + currentContextTotals[b],
        0
      );
      currentContextTotals["fSumAge"] = keys["fage"].reduce(
        (a, b) => a + currentContextTotals[b],
        0
      );
      currentContextTotals["sumCount"] = getSum(props.data.Field, "Count");
      currentContextTotals["fSumCount"] = getSum(props.data.Field, "fCount");

      let sum = {};
      keys["age"].map((key) => {
        sum[key] = getSum(props.data?.Field, key);
      });
      keys["fage"].map((key) => {
        sum[key] = getSum(props.data?.Field, key);
      });

      let data = props.data?.Field;
      // let data = props.data?.Field?.map((record) => ({
      //   ...record,
      //   AGE1hWeight: getAgeWeight(
      //     props.data?.Field,
      //     record[props.groupInfo.valueField],
      //     record["AGE1h"]
      //   ),
      //   AGE2hWeight: getNewWeight(
      //     currentContextTotals["AGE2h"],
      //     record["AGE2h"]
      //   ),
      //   AGE3hWeight: getNewWeight(
      //     currentContextTotals["AGE3h"],
      //     record["AGE3h"]
      //   ),
      //   AGE4hWeight: getNewWeight(
      //     currentContextTotals["AGE4h"],
      //     record["AGE4h"]
      //   ),
      //   AGE5hWeight: getNewWeight(
      //     currentContextTotals["AGE5h"],
      //     record["AGE5h"]
      //   ),
      //   AGE6hWeight: getNewWeight(
      //     currentContextTotals["AGE6h"],
      //     record["AGE6h"]
      //   ),
      //   AGE7hWeight: getNewWeight(
      //     currentContextTotals["AGE7h"],
      //     record["AGE7h"]
      //   ),
      // }));

      data = data.map((record) => ({
        ...record,
        sum: getNewRowSum(record, keys["age"]),
        fsum: getNewRowSum(record, keys["fage"]),
      }));

      for (let record in data) {
        keys["age"].map((key) => {
          data[record][key + "weight"] = [
            (data[record][key] / data[record]["sum"]) * 100,
          ];
        });
        keys["fage"].map((key) => {
          data[record][key + "weight"] = [
            (data[record][key] / data[record]["fsum"]) * 100,
          ];
        });
      }
      keys["age"].map((key) => {
        minMax[key + "weight"] = {
          min: getMinValue(data, key + "weight"),
          max: getMaxValue(data, key + "weight"),
        };
      });

      keys["fage"].map((key) => {
        minMax[key + "weight"] = {
          min: getMinValue(data, key + "weight"),
          max: getMaxValue(data, key + "weight"),
        };
      });

      setState({
        minMax: minMax,
        keys: keys,
        data: data,
        selectedKey: props.selectedKey,
        contextTotals: props.contextInfo,
        currentContextTotals: { ...currentContextTotals },
      });

      if (state.data.length === props.data?.Field?.length) {
      }
    }
  }, [props]);
  return (
    <Table
      celled
      structured
      selectable
      striped
      compact
      sortable
      className="refinement-custom-table right-align-text"
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            rowSpan="2"
            textAlign="center"
            style={{ textTransform: "uppercase" }}
          >
            {props.selectedKey}
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="8" textAlign="center">
            Context
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="8" textAlign="center">
            Filter
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Weight</Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("age1h", props)}>
            0-24
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("age2h", props)}>
            25-34
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("age3h", props)}>
            35-44
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("age4h", props)}>
            45-54
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("age5h", props)}>
            55-64
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("age6h", props)}>
            65-74
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("age7h", props)}>
            75 plus
          </Table.HeaderCell>
          <Table.HeaderCell>Weight</Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("fage1c", props)}>
            0-24
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("fage2c", props)}>
            25-34
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("fage3c", props)}>
            35-44
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("fage4c", props)}>
            45-54
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("fage5c", props)}>
            55-64
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("fage6c", props)}>
            65-74
          </Table.HeaderCell>
          <Table.HeaderCell {...getSortAttributes("fage7c", props)}>
            75 plus
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.Cell style={{ color: "#274398" }} className="value-field-td">
            Canada
          </Table.Cell>
          <Table.Cell>100%</Table.Cell>
          {state.keys.ageContext.map((index) => {
            return (
              <Table.Cell key={index}>
                {(
                  (state.contextTotals[index] / state.contextTotals?.sumAge) *
                  100
                ).toFixed(0) + "%"}
              </Table.Cell>
            );
          })}
          <Table.Cell>{props.isFiltered && "100%"}</Table.Cell>
          {state.keys.fage.map((value, index) => {
            return (
              <Table.Cell key={index}>
                {props.isFiltered &&
                  (!props.filterDimensionStatus.ageFilter ||
                    props.filterDimensions["age" + (index + 1)] !== 8) &&
                  (
                    (state.contextTotals[value] / state.contextTotals.fSumAge) *
                    100
                  ).toFixed(0) + "%"}
              </Table.Cell>
            );
          })}
        </Table.Row>
        <Table.Row>
          <Table.Cell style={{ color: "#274398" }} className="value-field-td">
            Context: {props.context}
          </Table.Cell>
          <Table.Cell>
            {checkDecimal(
              parseFloat(
                (state.currentContextTotals?.sumAge /
                  state.contextTotals?.sumAge) *
                  100 || 0
              ).toFixed(1)
            ) + "%"}
          </Table.Cell>
          {state.keys.age.map((index) => {
            return (
              <Table.Cell key={index}>
                {(
                  (state.currentContextTotals[index] /
                    state.currentContextTotals?.sumAge) *
                  100
                ).toFixed(0) + "%"}
              </Table.Cell>
            );
          })}
          <Table.Cell>
            {props.isFiltered &&
              checkDecimal(
                (
                  (state.currentContextTotals?.fSumAge /
                    state.contextTotals?.fSumAge) *
                  100
                ).toFixed(1)
              ) + "%"}
          </Table.Cell>
          {state.keys.fage.map((value, index) => {
            return (
              <Table.Cell key={index}>
                {props.isFiltered &&
                  (!props.filterDimensionStatus.ageFilter ||
                    props.filterDimensions["age" + (index + 1)] !== 8) &&
                  (
                    (state.currentContextTotals[value] /
                      state.currentContextTotals?.fSumAge) *
                    100
                  ).toFixed(0) + "%"}
              </Table.Cell>
            );
          })}
        </Table.Row>
        <Table.Row>
          {[...Array(17).keys()].map((d) => {
            return <Table.Cell key={d} />;
          })}
        </Table.Row>
        {state.data.length > 0 &&
          state.data.map((data, index) => {
            data.value = data[props.groupInfo.valueField];
            data.valueField =
              index + 1 + ". " + data[props.groupInfo.valueField];
            data.cWeight = getNewWeight(
              state.currentContextTotals.sumCount,
              data["Count"],
              0
            );
            data.fWeight = getNewWeight(
              state.currentContextTotals.fSumCount,
              data["fCount"],
              0
            );
            return (
              <>
                <Table.Row onClick={() => props.onClick(data)} key={index}>
                  <Table.Cell className="value-field-td">
                    {data.valueField}
                  </Table.Cell>
                  <Table.Cell
                    className={getColorClass(
                      state.minMax["Count"]?.min,
                      state.minMax["Count"]?.max,
                      data["Count"]
                    )}
                  >
                    {data.cWeight > 0 ? data.cWeight.toFixed(1) + "%" : ""}
                  </Table.Cell>
                  {[...Array(7).keys()].map((index) => {
                    let weight = getAgeWeight(
                      state.data,
                      data.value,
                      state.keys.age[index],
                      state.keys.age
                    );
                    return (
                      <Table.Cell
                        key={index}
                        className={getColorClass(
                          state.minMax[state.keys.age[index] + "weight"]?.min,
                          state.minMax[state.keys.age[index] + "weight"]?.max,
                          data[state.keys.age[index] + "weight"]
                        )}
                      >
                        {weight > 0 ? weight.toFixed(1) + "%" : ""}
                      </Table.Cell>
                    );
                  })}
                  <Table.Cell
                    className={
                      props.isFiltered &&
                      getColorClass(
                        state.minMax["fCount"]?.min,
                        state.minMax["fCount"]?.max,
                        data["fCount"],
                        true
                      )
                    }
                  >
                    {props.isFiltered && data.fWeight > 0
                      ? data.fWeight.toFixed(1) + "%"
                      : ""}
                  </Table.Cell>
                  {[...Array(7).keys()].map((index) => {
                    let weight = getAgeWeight(
                      state.data,
                      data.value,
                      state.keys.fage[index],
                      state.keys.fage
                    );
                    return (
                      <Table.Cell
                        key={index}
                        className={
                          props.isFiltered &&
                          (!props.filterDimensionStatus.ageFilter ||
                            props.filterDimensions["age" + (index + 1)] !==
                              8) &&
                          getColorClass(
                            state.minMax[state.keys.fage[index] + "weight"]
                              ?.min,
                            state.minMax[state.keys.fage[index] + "weight"]
                              ?.max,
                            data[state.keys.fage[index] + "weight"],
                            true
                          )
                        }
                      >
                        {props.isFiltered &&
                        (!props.filterDimensionStatus.ageFilter ||
                          props.filterDimensions["age" + (index + 1)] !== 8) &&
                        weight > 0
                          ? weight.toFixed(1) + "%"
                          : ""}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              </>
            );
          })}
      </Table.Body>
    </Table>
  );
}
