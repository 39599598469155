import React from "react";
import { Accordion } from "semantic-ui-react";
import "./helpview.css";
import questionmark from "./images/questionmark7.gif";

export default class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.subMenuPanels = [
      {
        content: this.subMenuContent([{ OverViewLayout: "Overview" }]),
      },
      {
        content: this.subMenuContent([
          { OverViewVideoLayout: "Overview - Video" },
        ]),
      },
      {
        title: "Dimensions",
        content: {
          content: this.subMenuContent([
            { Age: "Age" },
            { Income: "Income" },
            { FinancialWealth: "Financial Wealth" },
          ]),
        },
        key: "dimensions",
      },
      {
        title: "View",
        content: { content: this.nestedSubMenuView(), key: "view" },
        key: "view",
      },
      {
        title: "Filter",
        content: { content: this.nestedSubMenuFilter(), key: "filter" },
        key: "filter",
      },
      {
        title: "Framework",
        content: {
          content: this.subMenuContent([
            { FrameworkOverview: "Framework Overview" },
            { AverageWealth: "Average Wealth" },
            { ResultsE12: "Results: Example 1 and 2" },
          ]),
        },
        key: "framework",
      },
      {
        title: "Data Downloads",
        content: {
          content: this.subMenuContent([
            { DataDownloads: "Data Downloads" },
            { DataStructure: "Data Structure" },
          ]),
        },
        key: "data-downloads",
      },
      {
        title: "Contact",
        content: { content: this.subMenuContent([{ Contact: "Contact" }]) },
        key: "contact",
      },
      {
        title: "Glossary",
        content: { content: this.subMenuContent([{ Glossary: "Glossary" }]) },
        key: "glossary",
      },
    ];
  }

  nestedSubMenuView = () => {
    let viewNestedSubMenuPanel = [
      {
        content: this.subMenuContent([{ ViewLayout: "The View" }]),
      },
      {
        title: "The View",
        content: {
          content: this.subMenuContent([
            { DataDisplay: "Data Display" },
            // { LevelMonitor: "Level Monitor" },
            // { FilterMonitor: "Filter Monitor" },
            { GroupSelector: "Group Selector" },
            { ContentSelector: "Content Selector" },
            { DownloadData: "Download Data" },
            { FilterPanel: "Filter Panel" },
          ]),
        },
      },
    ];

    return (
      <div className="indent">
        <Accordion.Accordion
          style={{ marginLeft: "20px" }}
          className="no-padding"
          panels={viewNestedSubMenuPanel}
        />
      </div>
    );
  };
  nestedSubMenuFilter = () => {
    this.filterNestedSubMenuPanel = [
      {
        content: this.subMenuContent([{ FilterOverview: "Filter Overview" }]),
      },
      {
        title: "Filter Mechanics",
        content: {
          content: this.subMenuContent([
            { FilterMechanics: "Filter Mechanics" },
            { DimensionCube: "The Dimension Cube" },
            { Intersection3D: "EXAMPLE: Three dimensional intersection" },
            { ThreeFundamentalDim: "The Three Fundamental Dimensions" },
            { FIAOneDim: "Filtering in Action: One Dimension" },
            { FIATwoDim: "Filtering in Action: Two Dimensions" },
            { FIAThreeDim: "Filtering in Action: Three Dimensions" },
          ]),
        },
      },
      {
        title: "Creating your first filter",
        content: {
          content: this.subMenuContent([
            { FilterDimensions: "Filter Dimensions" },
            { FilterResults: "Filter Results" },
            { FilterRefinement: "Filter Refinement" },
            { SubFilterDetails: "Sub Filter Details" },
          ]),
        },
      },
    ];
    return (
      <div className="indent">
        <Accordion.Accordion
          style={{ marginLeft: "20px" }}
          className="no-padding"
          panels={this.filterNestedSubMenuPanel}
        />
      </div>
    );
  };
  SubMenu = () => {
    return (
      <div className="indent">
        <Accordion className="no-padding" panels={this.subMenuPanels} />
      </div>
    );
  };

  subMenuContent = (content) => (
    <div className="indent" style={{ marginTop: "2%", cursor: "pointer" }}>
      {content.map((data, index) => (
        <div
          key={index}
          onClick={() => {
            this.props.onClick(Object.keys(data)[0]);
          }}
          className="p-5 mr-8"
        >
          <img src={questionmark} style={{ marginRight: "10px" }} />
          {Object.values(data)[0]}
        </div>
      ))}
    </div>
  );

  render() {
    return (
      <div
        style={{
          marginTop: "5%",
          marginLeft: "4%",
          border: "1px solid lightgray",
          borderRadius: "8px",
          height: "100%",
        }}
      >
        {this.SubMenu()}
      </div>
    );
  }
}
