import {
  Button, Dialog,
  Label,
  Navigation, PageContent, Typography
} from "@iss/client-react-components";
import _ from "lodash";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Grid, Menu, Modal, Segment, Tab } from "semantic-ui-react";
import { getSum, keyList } from "../../common/functions";
import HttpBaseService from "../../common/services/http.service";
import { StoreContext } from "../../common/services/store";
import AgeView from "./age";
import DataView from "./dataview";
import IncomeView from "./income";
import WealthView from "./wealth";

export default class DataViewLayout2 extends React.Component {
  static contextType = StoreContext;
  constructor(props) {
    super(props);
    this._http = new HttpBaseService();

    this.state = {
      loading: {
        data: false,
        contextInfo: false,
        quantileInfo: false,
      },
      download: false,
      prevData: {},
      selectedKey: "province",
      groups: {
        province: {
          sc: "WMdimPROVINCE",
          selected: false,
          valueField: "Name",
          geolevelIdFields: {},
          filterReset: true, // geolevel 1, sc = "0"
          drillDownEnabled: true,
          show: true,
          // Trial
          geolevel: 1,
          upi: 0,
          context: "0",
        },
        cma: {
          sc: "WMdimCMA",
          selected: false,
          valueField: "Name",
          nongroupField: "Non-CMA",
          geolevelIdFields: {
            2: "UPI",
          },
          filterReset: false,
          drillDownEnabled: true,
          show: true,
          // Trial
          geolevel: 2,
          upi: 0,
          context: "0",
        },
        city: {
          sc: "WMdimCITY",
          nongroupsc: "WMdimCITYnonCMA",
          selected: false,
          valueField: "City",
          geolevelIdFields: {
            2: "ProvID",
            3: "UPI",
          },
          filterReset: false,
          drillDownEnabled: true,
          show: true,
          // Trial
          geolevel: 3,
          upi: 0,
          context: "0",
        },
        fsa: {
          sc: "WMdimFSA",
          selected: false,
          valueField: "City",
          geolevelIdFields: {
            2: "ProvID",
            3: "prevData.UPI",
            4: "UPI",
          },
          filterReset: false,
          drillDownEnabled: true,
          show: true,
          // Trial
          geolevel: 4,
          upi: 0,
          context: "0",
        },
        pc: {
          sc: "WMdimPC",
          selected: false,
          valueField: "City",
          geolevelIdFields: {
            2: "ProvID",
            3: "prevData.UPI",
            4: "UPI",
          },
          filterReset: false,
          drillDownEnabled: false,
          show: false,
          upi: 0,
          context: "0",
          geolevel: 4,
        },
        age: {
          sc: "WMdimAGE",
          selected: false,
          valueField: "AGEgroup",
          filterReset: false,
          drillDownEnabled: false,
          show: true,
        },
        income: {
          sc: "WMdimINCOME",
          selected: false,
          valueField: "INCOMEgroup",
          filterReset: false,
          drillDownEnabled: false,
          show: true,
        },
        wealth: {
          sc: "WMdimWEALTH",
          selected: false,
          valueField: "CFAgroup",
          filterReset: false,
          drillDownEnabled: false,
          show: true,
        },
      },
      drillDownWorkflow: {
        province: "cma",
        cma: "city",
        city: "fsa",
        fsa: "pc",
      },
      currentGeoLevel: 1,
      data: {},
      granularityFilter: {},
      userStatisticsModal: {
        showDownloading: false,
        minDate: this.formatDate(this.getPrevMonthDate()),
        maxDate: this.formatDate(new Date()),
      },
    };

    this.panes = [
      {
        menuItem: "Details",
        render: () => (
          <Tab.Pane attached={false}>
            <DataView
              isFiltered={this.context.filterApplied}
              data={this.state.data}
              groupInfo={this.state.groups}
              onClick={this.drillDown}
              contextInfo={this.context.contextTotals}
              context={
                this.context.filterGroup.context === "0"
                  ? "Canada"
                  : this.context.filterGroup.context
              }
              onSort={(sortOn) => this.sort(sortOn)}
              currentSort={this.getCurrentSort()}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Age",
        render: () => (
          <Tab.Pane attached={false}>
            <AgeView
              isFiltered={this.context.filterApplied}
              filterDimensions={this.context.filterDimension}
              filterDimensionStatus={this.context.filterDimensionStatus}
              data={this.state.data}
              selectedKey={this.state.selectedKey}
              groupInfo={this.state.groups[this.state.selectedKey]}
              onClick={this.drillDown}
              contextInfo={this.context.contextTotals}
              context={
                this.context.filterGroup.context === "0"
                  ? "Canada"
                  : this.context.filterGroup.context
              }
              onSort={(sortOn) => this.sort(sortOn)}
              currentSort={this.getCurrentSort()}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Income",
        render: () => (
          <Tab.Pane attached={false}>
            <IncomeView
              isFiltered={this.context.filterApplied}
              filterDimensions={this.context.filterDimension}
              filterDimensionStatus={this.context.filterDimensionStatus}
              data={this.state.data}
              selectedKey={this.state.selectedKey}
              groupInfo={this.state.groups[this.state.selectedKey]}
              onClick={this.drillDown}
              contextInfo={this.context.contextTotals}
              context={
                this.context.filterGroup.context === "0"
                  ? "Canada"
                  : this.context.filterGroup.context
              }
              onSort={(sortOn) => this.sort(sortOn)}
              currentSort={this.getCurrentSort()}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Wealth",
        render: () => (
          <Tab.Pane attached={false}>
            <WealthView
              isFiltered={this.context.filterApplied}
              filterDimensions={this.context.filterDimension}
              filterDimensionStatus={this.context.filterDimensionStatus}
              data={this.state.data}
              selectedKey={this.state.selectedKey}
              groupInfo={this.state.groups[this.state.selectedKey]}
              onClick={this.drillDown}
              contextInfo={this.context.contextTotals}
              context={
                this.context.filterGroup.context === "0"
                  ? "Canada"
                  : this.context.filterGroup.context
              }
              onSort={(sortOn) => this.sort(sortOn)}
              currentSort={this.getCurrentSort()}
            />
          </Tab.Pane>
        ),
      },
    ];
  }

  componentDidMount = () => {
    this.setState({
      granularityFilter: { ...this.context.currentGranularityFilter },
      groups: { ...this.context.groups },
    });
    this.fetchDataFromStore();
    this.fetchContextTotals();
    this.fetchNationalFilterTotals();
    this.setCurrentFilterGroup();
    this.fetchQuantileInformation();
  };

  componentWillUnmount = () => {
    this._http.CANCEL();
  };

  fetchQuantileInformation = () => {
    if (this.context.quantilesData && this.context.quantilesData?.length > 0) {
      return;
    }
    this.setState((state) => ({
      loading: { ...state.loading, quantileInfo: true },
    }));
    // this._http.GET("wmCanadaQuintiles.php").then((data) => {
    this._http.GET("/data/canada/quintiles").then((data) => {
      if (!data) {
        return;
      }
      // let dataArr = d["Format"][0];
      // let data = dataArr["Field"].map((data) => ({
      //   AvgW: data.AvgW[0],
      //   City: data.City[0],
      //   Wealth: data.Wealth[0],
      // }));

      const sortData = (data) => data.sort((a, b) => a - b);
      const quantile = (data, q) => {
        const sorted = sortData(data);
        const base = Math.floor(((sorted.length - 1) * q).toFixed(2));
        return q === 0 ? sorted[base] : sorted[base + 1];
      };

      let quintileRange = [];
      for (let i = 0; i < 5; i++) {
        quintileRange.push(quantile(data, (i * 20) / 100));
      }

      // Added By Bedesar

      let avgWealthArray = data.map((d) => parseFloat(d.AvgW || 0));
      avgWealthArray.sort((a, b) => a - b);
      let quintiles = [];
      quintiles.push(0.0);
      quintiles.push(
        avgWealthArray[Math.floor(avgWealthArray.length * 0.2) + 1]
      );
      quintiles.push(
        avgWealthArray[Math.floor(avgWealthArray.length * 0.4) + 1]
      );
      quintiles.push(
        avgWealthArray[Math.ceil(avgWealthArray.length * 0.6) + 1]
      );
      quintiles.push(
        avgWealthArray[Math.ceil(avgWealthArray.length * 0.8) + 1]
      );

      let quintilesData = quintiles.map((curr, index) => {
        let next = quintiles[index + 1];
        if (next !== undefined) {
          return {
            wealthQuantile:
              "$" +
              parseFloat(curr).toFixed(2) +
              "k - " +
              "$" +
              parseFloat(next).toFixed(2) +
              "k",
            contextQuantile: "20%",
            quantileStartWealth: parseFloat(curr),
            quantileEndWealth: parseFloat(next),
          };
        } else {
          return {
            wealthQuantile: "$" + parseFloat(curr).toFixed(2) + "k & Above",
            contextQuantile: "20%",
            quantileStartWealth: parseFloat(curr),
            quantileEndWealth: null,
          };
        }
      });
      quintilesData[0].quantileStartWealth = 0;
      this.context.setQuantilesData(quintilesData);
      this.setState((state) => ({
        loading: { ...state.loading, quantileInfo: false },
      }));
    });
  };

  setCurrentFilterGroup = () => {
    let groups = { ...this.state.groups };
    for (let key in groups) {
      groups[key].selected = false;
    }
    groups[this.context.selectedGroup].selected = true;
    this.setState({ groups: groups, selectedKey: this.context.selectedGroup });
  };

  fetchNationalFilterTotals = () => {
    if (!this.context.filterChanged) {
      return;
    }
    let urlParams = this.context.getUrlParameters();
    urlParams.sc = "WMdimNATIONAL";
    // this._http.GET("WMguardFirst.php", urlParams).then((d) => {
    this._http.POST("/data/filtered/by/dimension", urlParams).then((data) => {
      data.Field = data.map((d) => {
        Object.keys(d).forEach(key => {
          d[key] = [d[key]];
        })
        return d;
      })
      this.context.setFilterNationalTotals(data.Field[0]);
    });
  };

  fetchDataFromStore = () => {
    let data = this.context.data;
    if (Object.keys(data).length > 0 && !this.context.filterChanged) {
      this.setState({ data: data });
    } else {
      this.fetchDataFromApi();
    }
  };

  fetchContextTotals = () => {
    if (
      Object.keys(this.context.contextTotals).some(
        (d) => this.context.contextTotals[d] !== 0
      )
    ) {
      return;
    }
    this.setState((state) => ({
      loading: { ...state.loading, contextInfo: true },
    }));
    // this._http.GET("wmContextTotals.php").then((d) => {
    this._http.GET("/data/context/totals").then((d) => {
      if (d) {
        let contextTotals = {
          cities: 0,
          Wealth: 0,
          avgWealth: 0,
          households: 0,
        };
        // console.log(d)
        contextTotals.cities = d?.length; //d?.Field?.length;
        contextTotals.households = getSum(d, "Count"); //getSum(d.Field, "Count");
        contextTotals.Wealth = getSum(d, "Wealth"); //getSum(d.Field, "Wealth");
        contextTotals.avgWealth =
          contextTotals.Wealth / contextTotals.households;

        this.context.setContextTotals(contextTotals);
        this.setState((state) => ({
          loading: { ...state.loading, contextInfo: false },
        }));
      }
    });
  };

  fetchDataFromApi = (filterGroup = null) => {
    let keys = [
      ...keyList.age,
      ...keyList.ageOther,
      ...keyList.fage,
      ...keyList.income,
      ...keyList.incomeOther,
      ...keyList.fincome,
      ...keyList.wealth,
      ...keyList.fwealth,
      ...keyList.wealthOther,
      "fCount",
      "Count",
      "fWealth",
      "Wealth",
    ];
    let urlParams = this.context.getUrlParameters();
    if (filterGroup !== null) {
      urlParams.sc = filterGroup.sc;
      urlParams.context = filterGroup.context;
      urlParams.geolevel = filterGroup.geolevel;
      urlParams.provID = filterGroup.provID;
    }

    this.setState((state) => ({
      loading: { ...state.loading, data: true },
      data: {},
    }));
    // console.log(urlParams)
    // this._http.GET("WMguardFirst.php", urlParams).then((data) => {
    this._http.POST("data/filtered/by/dimension", urlParams).then((data) => {
      // if (data?.Field) {
      if (data) {
        data.Field = data.map((d) => {
          Object.keys(d).forEach(key => {
            d[key] = [d[key]];
          })
          return d;
        })
        data.Field.map((record) => {
          keys.forEach((key) => {
            if (key in record) {
              record[key][0] = +record[key][0] || 0;
            }
          });
        });
        this.context.setData(data);
        this.context.setContextTotalsFromData(data);
        this.setState({ data: data }, () => {
          this.setState((state) => ({
            loading: { ...state.loading, data: false },
          }));
        });
      } else {
        this.setState((state) => ({
          loading: { ...state.loading, data: false },
        }));
      }
    });
  };

  sort = (sortBy) => {
    this.context.setSort(sortBy).then(() => {
      this.fetchDataFromApi();
    });
  };

  getCurrentSort = () => {
    let sort = { ...this.context.sort };
    sort.sortDir = sort.sortDir === "asc" ? "ascending" : "descending";
    return sort;
  };

  getCurrentGroupAndKey = () => {
    let selectedKey = Object.keys(this.state.groups).find((key) => {
      return this.state.groups[key].selected === true;
    });
    let currentGroup = this.state.groups[selectedKey];

    return { currentGroup, selectedKey };
  };

  drillDown = (data) => {
    // Get Filters from store
    let filterGroup = _.cloneDeep(this.context.filterGroup);
    filterGroup.context = data.value[0];
    // Get Current Selection
    const { currentGroup, selectedKey } = this.getCurrentGroupAndKey();
    // Check if this group has drillable nextKey component
    if (!currentGroup.drillDownEnabled) {
      return;
    }
    // Get Next Drilldown as per workflow
    const nextKey = this.state.drillDownWorkflow[selectedKey];

    let groups = this.state.groups;
    for (let key in groups) {
      groups[key].selected = false;
    }
    groups[nextKey].selected = true;

    // filterGroup.geolevel += 1;

    // Specific Case Handling for FSA Called from Non-CMA CMA Value. The City Geolevel has to be 2 in this case but the FSA level still needs to be 4. Wheras if this was a drilldown till FSA via CMA, the geolevel will be 3 which as to be changed

    filterGroup.geolevel = groups[nextKey].geolevel;

    filterGroup.sc = groups[nextKey].sc;

    // Check if value is nongroup if yes get the nongroupsc if any else normal sc
    if (
      currentGroup.nongroupField &&
      currentGroup.nongroupField === filterGroup.context
    ) {
      filterGroup.sc = groups[nextKey].nongroupsc
        ? groups[nextKey].nongroupsc
        : filterGroup.sc;
      filterGroup.geolevel = filterGroup.geolevel - 1;
    }

    // Get geoLevelIdfields from the state for next Group
    let geoLevelIdfields = groups[nextKey].geolevelIdFields;
    // Get ProvID
    let provId = 0;
    let provField = geoLevelIdfields[filterGroup.geolevel];
    if (
      // filterGroup.geolevel === groups[nextKey].geolevel &&
      provField &&
      nextKey !== "pc"
    ) {
      if (provField.includes("prevData.")) {
        provId = this.state.prevData[provField.split("prevData.")[1]][0];
      } else {
        provId = data[provField][0];
      }
    }

    filterGroup.provID = provId;

    groups[nextKey].context = filterGroup.context;
    if (nextKey === "pc") {
      groups[nextKey].show = true;
      filterGroup.provID = filterGroup.context.split(" ")[0];
    }

    groups[nextKey].upi = filterGroup.provID;

    this.context.setFilterGroup(filterGroup);
    this.context.setSelectedFilterGroup(nextKey);
    this.context.setGroups(groups);
    this.fetchDataFromApi(filterGroup);
    this.setState({
      groups: groups,
      prevData: data,
      selectedKey: nextKey,
    });
  };

  onFilterGroupClick = (selectedGroup) => {
    // console.log(selectedGroup);
    let groups = { ...this.state.groups };
    const { currentGroup, selectedKey } = this.getCurrentGroupAndKey();
    for (let key in groups) {
      groups[key].selected = false;
    }
    groups["pc"].show = false;
    let selectedGroupObj = groups[selectedGroup];
    // console.log(selectedGroupObj);

    selectedGroupObj.selected = true;

    let filterGroup = this.context.filterGroup;

    // console.log(filterGroup);

    filterGroup.sc = selectedGroupObj.sc;
    if (filterGroup.context !== "0") {
      if (selectedGroup === "province") {
        // RESET LOGIC
        Object.keys(groups).map((key) => (groups[key].upi = 0));
        Object.keys(groups).map((key) => (groups[key].context = "0"));
      } else {
        filterGroup.provID = selectedGroupObj.upi;
        filterGroup.geolevel =
          selectedGroupObj.upi !== 0 ? selectedGroupObj.geolevel : 1;
        // Added by bedesar for Drillup fix
        filterGroup.context = selectedGroupObj.context;
      }
    }

    // Special Handling for Age Income Wealth
    if (["age", "income", "wealth"].includes(selectedGroup)) {
      // console.log("Inside Special Handling");
      //
      if (["cma", "city", "fsa", "pc"].includes(selectedKey)) {
        this.setState({ granularityFilter: currentGroup });
        this.context.setGranularityFilter({ ...currentGroup });
        filterGroup.provID = currentGroup.upi;
        filterGroup.geolevel =
          currentGroup.upi === 0 ? 1 : currentGroup.geolevel;
        // Added by bedesar for Drillup fix
        filterGroup.context = currentGroup.context;
      } else {
        // console.log(this.state.granularityFilter);
        // console.log(filterGroup);
        if (Object.keys(this.state.granularityFilter).length != 0) {
          filterGroup.provID = this.state.granularityFilter.provID
            ? this.state.granularityFilter.provID
            : this.state.granularityFilter.upi;
          filterGroup.geolevel =
            this.state.granularityFilter.upi === 0
              ? 1
              : this.state.granularityFilter.geolevel;
          // Added by bedesar for Drillup fix
          filterGroup.context = this.state.granularityFilter.context;
          // console.log(filterGroup);
        }
      }
    }

    if (selectedGroupObj.filterReset) {
      filterGroup.context = "0";
      filterGroup.geolevel = 1;
      filterGroup.provID = 0;
    }

    this.setState({
      groups: { ...this.state.groups, [selectedGroup]: selectedGroupObj },
      selectedKey: selectedGroup,
    });
    this.context.setGroups({
      ...this.state.groups,
      [selectedGroup]: selectedGroupObj,
    });

    this.context.setFilterGroup(filterGroup);
    this.context.setSelectedFilterGroup(selectedGroup);
    this.fetchDataFromApi(filterGroup);
  };

  getPrevMonthDate = () => {
    let now = new Date();
    if (now.getMonth() === 0) {
      return new Date(now.getFullYear() - 1, 11, 1);
    } else {
      return new Date(now.getFullYear(), now.getMonth() - 1, 1);
    }
  };

  downloadData = () => {
    let urlParams = _.cloneDeep(this.context.getUrlParameters());
    urlParams.sc = urlParams.sc; // + "csv";
    if (urlParams.context === "0") {
      urlParams.context = "CANADA";
    }
    this.setState({ download: true });
    // this._http.GETFILE("WMguardFirst.php", urlParams).then((response) => {
    this._http.POST_DOWNLOAD_FILE("/data/download", urlParams).then((response) => {
      if (!("content-disposition" in response.headers)) {
        throw Error("Cannot Download File");
      }
      let fileName = response.headers["content-disposition"]
        .split("filename=")[1]
        .replace(/"/g, "");
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([response.data], {
            type: "text/csv",
          }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "text/csv",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      }
      this.setState({ download: false });
    });
  };

  formatDate = (date) => {
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    const year = String(date.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return `${year}-${month}-${day}`;
  };

  onUserStatsDownload = () => {
    this.setState((state) => ({
      ...state,
      userStatisticsModal: {
        ...state.userStatisticsModal,
        showDownloading: true,
      },
    }));
    let urlParams = _.cloneDeep(this.context.getUrlParameters());
    urlParams.sc = "WMUserStatisticsCSV";
    // urlParams.cmaId = 0;
    urlParams.minDate = this.state.userStatisticsModal.minDate;
    urlParams.maxDate = this.state.userStatisticsModal.maxDate;
    if (urlParams.context === "0") {
      urlParams.context = "CANADA";
    }
    // this.setState({ download: true });
    this._http
      .POST_DOWNLOAD_FILE("/data/download", urlParams)
      // .GETFILE("WMguardFirst.php", urlParams)
      .then((response) => {
        if (!("content-disposition" in response.headers)) {
          throw Error("Cannot Download File");
        }
        let fileName = response.headers["content-disposition"]
          .split("filename=")[1]
          .replace(/"/g, "");
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], {
              type: "text/csv",
            }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "text/csv",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        }
        // this.setState({ download: false });
        this.setState((state) => ({
          ...state,
          userStatisticsModal: {
            ...state.userStatisticsModal,
            showDownloading: false,
            minDate: this.formatDate(this.getPrevMonthDate()),
            maxDate: this.formatDate(new Date()),
          },
        }));
      });
  };

  isLoggedInUserAnAdmin = () => {
    const { userProfile } = this.context;
    return userProfile?.userType?.toLocaleLowerCase() === 'admin';
  }

  render() {
    return (
      <>
        {(this.state.loading.data ||
          this.state.loading.contextInfo ||
          this.state.loading.quantileInfo) && (
            <Dialog>
              <Typography style={{ background: "white", padding: "1rem" }}>
                Loading Data...
              </Typography>
            </Dialog>
          )}

        {this.state.download && (
          <Dialog onBeforeDismiss={() => this.setState({ download: false })}>
            <Typography style={{ background: "white", padding: "1rem" }}>
              Downloading Data in CSV Format...
            </Typography>
          </Dialog>
        )}

        <PageContent className="mlr-10px">
          <Navigation.PageHeader
            title={
              "Context: " +
              (this.context.filterGroup.context === "0"
                ? "Canada"
                : this.context.filterGroup.context)
            }
          >
            {this.isLoggedInUserAnAdmin() && <Modal
              size={"tiny"}
              trigger={
                <Button
                  kind="secondary"
                  icon={<i className="icon-download" />}
                >
                  Download User Statistics
                </Button>
              }
              header="Select Date Range..."
              content={
                <div className="margin-15px d-flex justify-space-around flex-direction-row">
                  <Label text="Date From" style={{ overflow: "initial" }}>
                    <DatePicker
                      showMonthYearPicker
                      dateFormat="MMM yyyy"
                      selected={
                        new Date(this.state.userStatisticsModal.minDate)
                      }
                      maxDate={new Date(this.formatDate(new Date()))}
                      onChange={(e) =>
                        this.setState((state) => ({
                          ...state,
                          userStatisticsModal: {
                            ...state.userStatisticsModal,
                            minDate: this.formatDate(e),
                          },
                        }))
                      }
                      placeholderText="Select Date From"
                      className="datepicker-custom-style"
                    />
                  </Label>
                  <Label text="Date To" style={{ overflow: "initial" }}>
                    <DatePicker
                      showMonthYearPicker
                      dateFormat="MMM yyyy"
                      minDate={new Date(this.state.userStatisticsModal.minDate)}
                      maxDate={new Date(this.formatDate(new Date()))}
                      selected={
                        new Date(this.state.userStatisticsModal.maxDate)
                      }
                      onChange={(e) =>
                        this.setState((state) => ({
                          ...state,
                          userStatisticsModal: {
                            ...state.userStatisticsModal,
                            maxDate: this.formatDate(e),
                          },
                        }))
                      }
                      placeholderText="Select Date To"
                      className="datepicker-custom-style"
                    />
                  </Label>
                </div>
              }
              actions={[
                "Cancel",
                {
                  key: "done",
                  content: "Download",
                  positive: true,
                  onClick: () => this.onUserStatsDownload(),
                },
              ]}
            />}

            <Button
              kind="secondary"
              icon={<i className="icon-download" />}
              onClick={this.downloadData}
            >
              Download Data
            </Button>
          </Navigation.PageHeader>

          <Grid>
            <Grid.Column width={2}>
              <Menu fluid vertical tabular>
                {Object.keys(this.state.groups).map((key, index) => {
                  return (
                    this.state.groups[key].show && (
                      <Menu.Item
                        style={{ textTransform: "uppercase" }}
                        name={key}
                        key={index}
                        active={this.state.groups[key].selected}
                        onClick={() => this.onFilterGroupClick(key)}
                      />
                    )
                  );
                })}
              </Menu>
            </Grid.Column>

            <Grid.Column stretched width={14}>
              <Segment
                style={{ border: 0, margin: 0, boxShadow: "none", padding: 0 }}
              >
                <Tab
                  menu={{ pointing: true }}
                  panes={this.panes}
                  style={{ textTransform: "uppercase" }}
                />
              </Segment>
            </Grid.Column>
          </Grid>
        </PageContent>
      </>
    );
  }
}
