import { Navigation, PageContent } from "@iss/client-react-components";
import React from "react";
import { Table } from "semantic-ui-react";
import { getColorClass } from "../../common/functions";
import view from "./wm_images/wealthmine.JPG";
import viewcontentselector from "./wm_images/wealthmine_contentselector.JPG";
import view_datadisplay from "./wm_images/wealthmine_dataview.JPG";
import viewgroupselector from "./wm_images/wealthmine_groupselector.JPG";

export function ViewLayout(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="The View"></Navigation.PageHeader>

        <p style={{ lineHeight: "200%" }}>
          The view is the pivotal screen of WealthMINE. In it, users will find
          all data points relative to the context and the filter of the
          particular view of the data at any moment in time.
        </p>

        <p style={{ lineHeight: "200%" }}>Components of The View</p>

        <div
          className="helpview-layout"
          style={{ justifyContent: "space-between" }}
        >
          <div>
            <div
              className="clickable"
              onClick={() => {
                props.handleChange("DataDisplay");
              }}
            >
              <p>Data Display</p>
            </div>
            {/* <div className="clickable" onClick={() => { props.handleChange('LevelMonitor') }}><p>Level Monitor</p></div>
                        <div className="clickable" onClick={() => { props.handleChange('FilterMonitor') }}><p>Filter Monitor</p></div> */}
            <div
              className="clickable"
              onClick={() => {
                props.handleChange("GroupSelector");
              }}
            >
              <p>Group Selector</p>
            </div>
            <div
              className="clickable"
              onClick={() => {
                props.handleChange("ContentSelector");
              }}
            >
              <p>Content Selector</p>
            </div>
            <div
              className="clickable"
              onClick={() => {
                props.handleChange("DownloadData");
              }}
            >
              <p>Download Data</p>
            </div>
            <div
              className="clickable"
              onClick={() => {
                props.handleChange("FilterPanel");
              }}
            >
              <p>Filter Panel</p>
            </div>
          </div>
          <div style={{ margin: "20px" }}>
            <img src={view} style={{ height: "300pt" }}></img>
            <br />
          </div>
        </div>
      </PageContent>
    </div>
  );
}

export function DataDisplay() {
  const numberColorRange = [35, 33, 30, 27, 26, 19, 18, 12, 11, 10];
  const numberColorRangeLabel = ["Max", "", "", "", "", "", "", "", "", "Min"];
  const numberColorRangeChart = [35, 30, 22, 19, 10];

  const numColorClass = numberColorRangeChart
    .map((data) => {
      return getColorClass(
        numberColorRangeChart[numberColorRangeChart.length - 1],
        numberColorRangeChart[0],
        data
      );
    })
    .reverse();

  const numColorClassLabel = [
    "1) Lower Limit=Min, upper limit < (int*1) + Min",
    "2) Lower Limit=(int*1) + Min, upper limit < upper limit < (int*2) + Min",
    "3) Lower Limit=(int*2) + Min, upper limit < upper limit < (int*3) + Min",
    "4) Lower Limit=(int*3) + Min, upper limit < upper limit < (int*4) + Min",
    "5) Lower Limit=(int*4) + Min, upper limit < Max",
  ];

  const numColorRangeChartData = numberColorRangeChart.reverse();

  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Data Display"></Navigation.PageHeader>
        <p className="line-height">
          This is the area where data details are organized and presented. The
          areas with the BLUE heading comprise Context data, and the areas with
          the YELLOW heading reflect the data resulted from the filtering
          process, or the filtered data. The columns may be sorted by clicking
          on their respective labels (except for &quot;weight&quot; which may be
          achieved by sorting based on &quot;Count&quot;){" "}
        </p>

        <div style={{ margin: "20px" }}>
          <img src={view_datadisplay} style={{ height: "300pt" }} />
          <br />
        </div>

        <p className="line-height">
          The data is organized in rows where the first two rows show
          referential data respective to Canada Totals and Context Totals,
          whatever that might be at any point in time. The other rows reflect
          the detail or granularity applied by the Group Selector. In the
          example below the Group Selector &quot;Province&quot; provides data
          aggregated by province.
        </p>

        <p className="line-height">
          The color coding is applied only to the Weight, Concentration, and
          Average Wealth columns, and the mechanism to define the colors is
          based on an equal segmentation of the minimum and maximum range for
          the field where as the value gets closer to the maximum the color
          darkens, and vice versa.
        </p>

        <p className="line-height">Example of the coloring mechanism:</p>

        {/* <div style={{ margin: '20px' }}><img src={viewcolorcode}></img><br /></div> */}
        <Table
          style={{ width: "15%", borderCollapse: "collapse", borderTop: "0px" }}
        >
          <Table.HeaderCell>Ten Numbers</Table.HeaderCell>
          <Table.Body>
            {numberColorRange.map((data, index) => {
              return (
                <Table.Row key={data}>
                  <Table.Cell
                    style={{ textAlign: "center", padding: "0px" }}
                    className={getColorClass(
                      numberColorRange[numberColorRange.length - 1],
                      numberColorRange[0],
                      data
                    )}
                  >
                    {data}
                  </Table.Cell>
                  <Table.Cell style={{ borderTop: "0px" }}>
                    {numberColorRangeLabel[index]}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <p>Formula : int = (MAX - MIN) 5</p>
        <p>Bins:</p>
        <Table style={{ width: "50%", borderCollapse: "collapse" }}>
          <Table.Body>
            {numColorRangeChartData.map((data, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Cell
                    style={{
                      textAlign: "center",
                      padding: "0px",
                      width: "20%",
                    }}
                    className={numColorClass[index]}
                  ></Table.Cell>
                  <Table.Cell style={{ borderTop: "0px" }}>
                    {numColorClassLabel[index]}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </PageContent>
      <br />
    </div>
  );
}

export function LevelMonitor() {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Level Monitor"></Navigation.PageHeader>
        <p className="line-height">
          Provides users with constant feedback about the level of geography
          being presented. In the example below, National id highlighted meaning
          that the context is Canada. The National level may have a provincial,{" "}
          <i>CMA</i> (Census Metropolitan Areas), or City level of granularity.
          The labels of the Level Monitor are not interactive buttons, they are
          only user awareness providers relative to level of geography
          perspective.
        </p>

        <div style={{ margin: "20px" }}>
          <img src={view} style={{ height: "300pt" }} />
          <br />
        </div>
      </PageContent>
    </div>
  );
}

export function FilterMonitor(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Filter Monitor"></Navigation.PageHeader>

        <p className="line-height">
          This monitor is an abstract version of The Filter (
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("FilterOverview");
            }}
          >
            Filter Overview
          </span>
          ). It provides users with constant feedback about the
          parameters/criteria of the current filter. The lack of a filter
          monitor would reduce analytical productivity slightly by forcing the
          user to click on the Filter button to see this information.
        </p>

        <div style={{ margin: "20px" }}>
          <img src={view} style={{ height: "300pt" }} />
          <br />
        </div>

        <p className="line-height">The components of the Filter Monitor are:</p>

        <p className="line-height">
          <b> 1) </b>
          Four rows (D1: small, D2: medium, D3: high, D4:ultra high) for each
          City Household Density thresholds. More on this under
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("FilterRefinement");
            }}
          >
            Filter Refinement
          </span>{" "}
          .
        </p>

        <p className="line-height">
          <b> 2) </b>A column called CONC. which stands for{" "}
          <i>Concentration </i> ( density or wealth of a filter divided by the
          total density or wealth of a locality) This column displays the
          minimum <i>Concentration </i>
          selected for each of the four City Household Density groups. The
          default value is zero, meaning that the filter does not restrict any
          location based on minimum <i>Concentration </i>
          More on this under{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("FilterRefinement");
            }}
          >
            Filter Refinement
          </span>
          .
        </p>

        <p className="line-height">
          <b> 3) </b>A column called DENSITY which displays the minimum filter
          density applied to the filter. That is, the minimum number of
          households that the user defined for each city in the filter. The
          default value is zero, meaning that the filter does not restrict any
          location based on minimum 695 <i>Density </i>( Number of households
          in a location). More on this under{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("FilterRefinement");
            }}
          >
            Filter Refinement
          </span>{" "}
          .
        </p>

        <p className="line-height">
          <b> 4) </b>A Column called AVG. WTH. which stands for{" "}
          <i>Average Wealth </i>
          (Used as a quality index: wealth divided by the number of household in
          a specific locality). This column displays the minimum
          <i> Average Wealth </i> select for each of the four City Household
          Density groups. The default value is zero, meaning that the filter
          does not restrict any location based on minimum <i>Average Wealth </i>
          . More on this under{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("FilterRefinement");
            }}
          >
            Filter Refinement{" "}
          </span>{" "}
          .
        </p>

        <p className="line-height">
          <b> 4) </b>
          There is a matrix of squares with the headers &quot;a&quot;,
          &quot;i&quot;, &quot;w&quot; each one relating to the dimensions{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("Age");
            }}
          >
            Age
          </span>
          ,
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("Income");
            }}
          >
            Income
          </span>{" "}
          and
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("FinancialWealth");
            }}
          >
            {" "}
            Financial Wealth
          </span>
          . The squares highlighted in orange indicate the segments of each
          dimension applied to the filter. In the image below the 65 - to 74 and
          the 75+ segments of the{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("Age");
            }}
          >
            Age
          </span>
          dimension where selected in the filter.
        </p>
      </PageContent>
    </div>
  );
}

export function GroupSelector(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Group Selector"></Navigation.PageHeader>
        <p className="line-height">
          This set of buttons allow users how to group the data viewed.{" "}
        </p>
        <div style={{ margin: "20px" }}>
          <img src={viewgroupselector} style={{ height: "300pt" }} />
          <br />
        </div>

        <p className="line-height">Components:</p>

        <p className="line-height">
          <b>1)</b>
          Granularity Selector (Province - FSA): it allows users to select if
          they want to see the data grouped by Province, <i> CMA </i> ( Census
          Metropolitan Areas), or City.
        </p>
        <p className="line-height">
          <b>2)</b>
          AGE: displays the data grouped by{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("Age");
            }}
          >
            Age
          </span>
          for the current Context. In the case below, when this button is
          clicked all data would be grouped based on the 7 segments of the{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("Age");
            }}
          >
            Age
          </span>
          dimension.
        </p>

        <p className="line-height">
          <b>3)</b>
          INCOME: displays the data grouped by{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("Income");
            }}
          >
            Income
          </span>
          for the current Context. In the case below, when this button is
          clicked all data would be grouped based on the 7 segments of the{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("Income");
            }}
          >
            Income
          </span>
          dimension.
        </p>

        <p className="line-height">
          <b>4)</b>
          WEALTH: displays the data grouped by{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("FinancialWealth");
            }}
          >
            Financial Wealth
          </span>{" "}
          for the current Context. In the case below, when this button is
          clicked all data would be grouped based on the 7 segments of the{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("FinancialWealth");
            }}
          >
            Financial Wealth
          </span>
          dimension.
        </p>
      </PageContent>
    </div>
  );
}

export function ContentSelector(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Content Selector"></Navigation.PageHeader>
        <p className="line-height">Allows users to change the data viewed.</p>
        <div style={{ margin: "20px" }}>
          <img src={viewcontentselector} style={{ height: "300pt" }} />
          <br />
        </div>
        <p className="line-height">Components : </p>

        <p className="line-height">
          <b>1)</b>
          DETAILS: Shows number of households, wealth, concentration, and
          average wealth data for the Context and the Filter{" "}
        </p>

        <p className="line-height">
          <b>2)</b>
          AGE: Shows the percent of households in each of the 7 segments of the{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("Age");
            }}
          >
            Age
          </span>
          dimension for the Context and the Filter.
        </p>

        <p className="line-height">
          <b>3)</b>
          INCOME: Shows the percent of households in each of the 7 segments of
          the{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("Income");
            }}
          >
            Income
          </span>
          dimension for the Context and the Filter.
        </p>

        <p className="line-height">
          <b>4)</b>
          WEALTH: Shows the percent of households in each of the 7 segments of
          the{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("FinancialWealth");
            }}
          >
            Financial Wealth
          </span>
          dimension for the Context and the Filter.
        </p>

        <p className="line-height">
          <b>5)</b>
          QUADRANT: see{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("ConcentrationQuadrant");
            }}
          >
            Concentration Quadrant
          </span>
        </p>
      </PageContent>
    </div>
  );
}

export function DownloadData(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Download Data"></Navigation.PageHeader>
        <p className="line-height">
          {" "}
          Go to
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("DataDownloads");
            }}
          >
            Data Downloads
          </span>
        </p>
      </PageContent>
    </div>
  );
}
export function FilterPanel(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Filter Panel"></Navigation.PageHeader>
        <p className="line-height">
          {" "}
          Go to
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("FilterOverview");
            }}
          >
            Filter Overview
          </span>
        </p>
      </PageContent>
    </div>
  );
}
