import { Typography } from "@iss/client-react-components";
import _ from "lodash";
import React from "react";
import { toMoneyFormat } from "../../../common/functions";
import { StoreContext } from "../../../common/services/store";
class AvgFinancialWealthSection extends React.Component {
  static contextType = StoreContext;
  constructor(props) {
    super(props);
    this.state = {
      columnList: [
        {
          columnType: "text",
          field: "wealthQuantile",
          label: "Quantile",
        },
        {
          columnType: "text",
          field: "contextDistribution",
          label: "Context Distribution",
        },
        {
          columnType: "text",
          field: "filterDistribution",
          label: "Filter Distribution",
        },
      ],
      filteredApiResponse: [],
      quintileData: [],
      quintileApiResponse: [],
      avgFinancialWealth: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props?.filteredApiResponse?.length > 0) {
        this.setState(
          {
            filteredApiResponse: this.props.filteredApiResponse.filter(
              (d) => d["fWealth"] > 0 && d["fCount"] > 0
            ),
          },
          () => this.getFilterData()
        );
      } else {
        this.setState((state) => ({
          ...state,
          filteredApiResponse: [],
          quintileData: _.cloneDeep(this.context.quantilesData),
          avgFinancialWealth: 0,
        }));
      }
    }
  }

  componentDidMount() {
    this.setState({ quintileData: _.cloneDeep(this.context.quantilesData) });
  }

  // Rewrite by Bedesar
  getFilterData = () => {
    let filteredApiResponse = [...this.state.filteredApiResponse];

    let quintiles = [...this.state.quintileData];

    let avgFinancialWealth = 0;
    if (filteredApiResponse.length > 0) {
      quintiles.map((d) => (d["fCount"] = 0));
      filteredApiResponse.map((d) => {
        d["fAvgWealth"] = d["fWealth"] / d["fCount"];
        d["fAvgWealth"] = isNaN(d["fAvgWealth"]) ? 0 : d["fAvgWealth"];
        d["quantile"] = quintiles.find((q) =>
          q.quantileEndWealth === null
            ? q.quantileStartWealth <= d["fAvgWealth"]
            : q.quantileStartWealth <= d["fAvgWealth"] &&
              d["fAvgWealth"] < q.quantileEndWealth
        )?.wealthQuantile;

        quintiles.find((q) => q.wealthQuantile === d["quantile"]).fCount++;
        avgFinancialWealth += parseFloat(d["fAvgWealth"] || 0);
      });
      quintiles.map((q) => {
        q["filteredQuantile"] =
          ((q["fCount"] / filteredApiResponse.length) * 100).toFixed(2) + "%";
      });
      this.setState({
        quintileData: quintiles,
        avgFinancialWealth: avgFinancialWealth,
      });
    }
  };
  render() {
    return (
      <div>
        <div style={{ textAlign: "center" }}>
          <Typography.SubHeading>
            Filter City Avg. Financial Wealth
          </Typography.SubHeading>
          <Typography.SubText1>
            $
            {toMoneyFormat(
              this.state.avgFinancialWealth /
                this.state.filteredApiResponse.length,
              2
            )}
            k
          </Typography.SubText1>
        </div>
        <table className={"refinement-custom-table  ui celled table"}>
          <thead>
            <tr>
              <th rowSpan="2">Canada Avg. Wealth Quintiles</th>
              <th colSpan="2">City Distribution</th>
            </tr>
            <tr>
              <th></th>
              <th>Filter</th>
            </tr>
          </thead>
          <tbody>
            {this.state.quintileData?.map((d, index) => {
              return (
                <tr key={index}>
                  <td>{d.wealthQuantile}</td>
                  <td>{d.contextQuantile}</td>
                  <td>{d.filteredQuantile}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AvgFinancialWealthSection;
