import { Navigation, PageContent } from '@iss/client-react-components';
import React from 'react';
import { Table } from 'semantic-ui-react';
import fw1 from './images/fw1.gif';
import fw2 from './images/fw2.gif';
import fw3 from './images/fw3.gif';
import fw4 from './images/fw4.gif';
import filtertheframework from './wm_images/filter.JPG';
import viewcleandescavgw from './wm_images/wealthmine.JPG';


export function FrameworkOverview(props) {
    return (
        <div>
            <PageContent>
                <Navigation.PageHeader title="Framework Overview"></Navigation.PageHeader>

                <p className="line-height"><b><i>&quot;Framework:
                a real or conceptual structure intended to serve as a support or guide
                for the building of something that expands the structure into something
                meaningful.&quot;</i></b></p>

                <p className="line-height">In the context of WealthMINE we refer to
                &quot;the framework&quot; as the group of variables and parameters that
                needs to be considered simultaneously in order to draw insight from the
                data and produce more meaningful results.</p>

                <ol>
                    <li ><p className="line-height"><b><i>Average Wealth </i></b>
                        (Used as a quality index: wealth divided by the number of household in a specific locality) </p></li>
                </ol>

                <p className="line-height">In the image below we have a snapshot of
                <span className="clickable" onClick={() => { props.handleChange('ViewLayout') }}>The View</span>. In this particular
                instance we sorted the locations (CMA - Metro Areas) in descending order
                by Average Wealth. Although, on occasions, &#160;averages may suffer from
                outliers and element size inequality, we have found average wealth to
                be a good complementary piece of information when evaluating the wealth
                quality of a locality. Average Wealth is used to measure not only the
                quality of a locality, but also to evaluate the quality
                of a filter, more on this later.</p>

                <div style={{ margin: '20px' }}><img src={viewcleandescavgw} style={{height:"300pt"}} alt=""></img><br /></div>

                <ol start="2" >
                    <li><b><i>Concentration</i></b> ( density or wealth of a filter divided by the total density or wealth of a locality)</li>
                </ol>

                <p className="line-height">The image below shows the first encounter
                with the concept of Concentration. The prerequisite for concentration
                is a filter (you cant have concentration without a numerator). Then, we
                may analyze concentration from two different perspectives <i> Household Concentration </i>
                (the ratio of filter household density to the total household density of a specific location expressed as a percentage) and
                <i> Wealth Concentration </i> (the ratio of filter total financial wealth to the total financial wealth of a specific location
                expressed as a percentage) . On the upper right
                of the &quot;Households&quot; panel the 1.31% indicated that the number
                of households over 65, with income over 100k and Financial Wealth of over
                $250k make up only 1.31% of all households in Canada, or said in a different
                way. a 1.3.1% <i>Household Concentration </i>(the ratio of filter household density to the total household density of a
                specific location expressed as a percentage.).
                Similarly, the &quot;Financial Wealth&quot; panel shows a 14.67%
                <i> Wealth Concentration </i> (the ratio of filter total financial wealth to the total financial wealth of a specific
                location expressed as a percentage.) , this means that
                households over 65, with income over 100k and Financial Wealth of over
                $250k control more or less 15% or the Canadian wealth (not a insignificant
                number)</p>

                <div style={{ margin: '20px' }}><img src={filtertheframework} style={{height:"300pt"}} alt=""></img><br /></div>
            </PageContent>
        </div>
    )
}

export function AverageWealth(props) {
    return (
        <div>
            <PageContent>
                <Navigation.PageHeader title="Average Wealth"></Navigation.PageHeader>
                <p className="line-height">
                    In the image below we have a snapshot of
                    <span className="clickable" onClick={() => { props.handleChange('ViewLayout') }}>The View</span>. In this particular
                    instance we sorted the locations (CMA - Metro Areas) in descending order
                    by Average Wealth. Although, on occasions, &#160;averages may suffer from
                    outliers and element size inequality, we have found average wealth to
                    be a good complementary piece of information when evaluating the wealth
                    quality of a locality. Average Wealth is used to measure not only the
                    quality of a locality, but also to evaluate the quality
                    of a filter, more on this later.
                </p>

                <div style={{ margin: '20px' }}><img src={viewcleandescavgw} style={{height:"300pt"}} alt=""></img><br /></div>



            </PageContent>
        </div>
    )
}

export function ConcentrationQuadrant(props) {
    return (
        <div>
            <PageContent>
                <Navigation.PageHeader title="Concentration Quadrant"></Navigation.PageHeader>
                <div className="helpview-layout">
                    <div>
                        <p>In addition to Average Wealth the Framework
                        relies on  <i>Concentration</i>(density or wealth of a filter divided by the total density or wealth of a locality.)
                        to provide meaningful relative information
                        on the attractiveness of a locality from a wealth perspective.</p>

                        <p className="line-height">There are two kinds of <i>Concentration</i>( density or wealth of a filter divided by the total
                        density or wealth of a locality.) variables used in the
                        Framework:</p>

                        <p className="line-height"> 1) <i>Household Concentration</i> (the ratio of filter household density to
                        the total household density of a specific location expressed as a percentage.)</p>

                        <p className="line-height">2) <i>Wealth
                        Concentration </i> (the ratio of filter total financial wealth to the total financial wealth of a specific location
                        expressed as a percentage.)</p>

                        <p className="line-height">Both are displayed in the
                        details tab of the View, also in the filter, and also in a area called
                        QUADRANT (short for Concentration Quadrant). The adjacent screen shot
                        of the view indicates where to click in order to access the Quadrant.</p>



                    </div>
                    <div style={{ margin: '20px' }}>
                        <img style={{ width: "100%", height: "100%" }}
                            src={viewcleandescavgw} alt=""></img><br /></div>
                </div>
                <p className="line-height">The quadrant provides a visual perspective
                        on the mechanics between <i>Household Concentration </i>(the ratio of filter household density to the total household
                        density of a specific location expressed as a percentage)
                        (horizontal axis) and <i>Wealth Concentration </i> ( the ratio of filter total financial wealth to the total
                        financial wealth of a specific location expressed as a percentage)
                        (vertical axis). Each location is plotted on the scattered chart based
                        on the two concentrations. &#160;The relative position between locations,
                        and the national, and context positions provide a perspective on relative
                        presence of the filtered criteria on each location.</p>
                <p className="line-height">Understand more in <span className="clickable"
                    onClick={() => { props.handleChange('QuadrantE1') }}>Quadrant: Example 1</span></p>

            </PageContent>
        </div>
    )
}

export function QuadrantE1(props) {
    return (
        <div>
            <PageContent>
                <Navigation.PageHeader title="Quadrant: Example 1"></Navigation.PageHeader>
            </PageContent>
            <p className="line-height">One of the fundamental functional features
            of WealthMINE is to allow users to identify where a particular subset
            or segment is located and with what level of concentration.</p>
            <p className="line-height">In this example we create a large subset:
            of all households of age 65+. </p>

            <p className="line-height">The image below reflects the position of
            each province based on <i> Household Concentration </i> (the ratio of filter household density to the total
             household density of a specific location expressed as a percentage)
            and <i>Wealth Concentration </i>(the ratio of filter total financial wealth to the total
            financial wealth of a specific location expressed as a percentage).
            The first thing to highlight is the RED circle with the horizontal and
            vertical coordinate lines. This is the National (highest level of aggregation)
            positioning of the (rounded) concentration levels for the whole country.
            Specifically, 24% of households are age 65+ and 40% of the financial wealth
            is in their hands. This point is the vertex of the quadrant system and
            the position of each location relative to it is an important referential
            argument.</p>

            <div style={{ margin: '20px' }}><img src={fw1} alt=""></img><br /></div>

            <p className="line-height">The provinces on the left are sorted
            in descending order by <i>Household Concentration </i>(the ratio of filter household
            density to the total household density of a specific location expressed as a percentage).
            This means that based on <i>Household Concentration </i>
            only, Prince Edward Island (PEI) is ranked number one, in other words,
            PEI is the province with the highest percentage of households at age 65+
            with 25.89% . Although not necessarily significantly different than the
            national average 24%, PEI is to the right of the national average, and
            below the wealth concentration national average, signaling that indeed,
            PEI has more 65+ households as a percent of all households, but on average,
            these households control a lower portion of the financial wealth 36.21%
            relative to the national average 40%. There seems to be a large number
            of provinces with <i>Household Concentration </i>
            oscillating between the national average and PEI.</p>

            <p className="line-height"><b> <i>What
            will happen to the positioning of PEI if we added &quot;$500k plus in
            Financial Wealth&quot; to our already selected age 65+ criteria? Will
            PEI stay to the right and bottom of the national average?</i></b></p>

            <p className="line-height">Bellow is the quadrant again but now including
            an additional criteria component that restricts the filter to 65+ households
            that have at least $500k in financial wealth. First lets take a look at
            what happened to the national average.  <i>Household Concentration </i>  dropped from 24% to 4%, this means that only
            4% of households in canada meet our new two dimensional criteria. Also
            the national  <i>Wealth Concentration </i>  (the ratio of filter total
             financial wealth to the total financial wealth of a specific location expressed as a percentage)
            dropped from 40% to 32%, still a significant drop although not as dramatic
            as the <i>Household Concentration </i>
            drop. One of the preliminary conclusion we may derive from this brief
            exercise is that.</p>
            <div style={{ margin: '20px' }}><img src={fw2} alt=""></img><br /></div>

            <p className="line-height">What happened to PEI? It is now significantly
            to the left of the national average, indication that is affluent 65+ households
            constitute small portion of PEI total. So if our objective is to find
            affluent 65+ households, we will need to undertake a more exhaustive weed
            out process in PEI that we would in other areas. This does not mean there
            are no opportunities in PEI, it just means that if we through a rock in
            PEI, the chances of hitting a wealthy 65+ household is lower than in other
            provinces.</p>

            <p>
                <span className="clickable" onClick={() => { props.handleChange('QuadrantE2') }}>Quadrant: Example 2</span>
            </p>
            <p>&#160;</p>
        </div>
    )
}

export function QuadrantE2(props) {
    return (
        <div>
            <PageContent>
                <Navigation.PageHeader title="Quadrant: Example 2"></Navigation.PageHeader>
                <p className="line-height">
                    As you will recall from  <span className="clickable"
                        onClick={() => { props.handleChange('QuadrantE1') }}>Quadrant: Example 1</span>
                we were working at a provincial
				level, let us now dig deeper into the Metro Area perspective. The snapshot
				below shows the position of each metro area in Canada after applying the
				original filter of household age 65+. In  <span className="clickable"
                        onClick={() => { props.handleChange('QuadrantE1') }}>Quadrant: Example 1</span>
                PEI was the province with the highest level of <i> Household Concentration </i>
                (the ratio of filter household density to
			    the total household density of a specific location expressed as a percentage) which metro
				area is ranked number one now? Peterborough.
                </p>
                <div style={{ margin: '20px' }}><img src={fw3} alt=""></img><br /></div>

                <p className="line-height"> As you can see there is more
                dispersion of points around the national average. This is a natural phenomenon
                as one starts to drill down to higher levels of granularity. Let us again
                apply another dimension to the filter and see what happens to the position
                of Peterborough. The image below shows that unlike in <span className="clickable"
                        onClick={() => { props.handleChange('QuadrantE1') }}>Quadrant: Example 1</span>
                 where PEI move to a position to the left of the national
                average after applying the &quot;$500k plus&quot; filter, Peterborough
                did stay to the right of the national average indicating that it is a
                relatively good place to find affluent 65+ households.</p>

                <div style={{ margin: '20px' }}><img src={fw4} alt=""></img><br /></div>

                <span className="clickable"
                    onClick={() => { props.handleChange('ResultsE12') }}>Results: Example 1 and 2</span>

            </PageContent>
        </div>
    )
}

export function ResultsE12(props) {
    return (
        <div>
            <PageContent>
                <Navigation.PageHeader title="Results: Example 1 and 2"></Navigation.PageHeader>
                <p className="line-height">When we compare now a Province: PEI, and a Metro Area: Peterborough,
		        we find interesting results.</p>
                <Table classname="help-custom-table">
                    <Table.HeaderCell style={{ width: "40%" }}>Overall Totals</Table.HeaderCell>
                    <Table.HeaderCell>Prince Edward Island</Table.HeaderCell>
                    <Table.HeaderCell>Peterborough</Table.HeaderCell>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell><b>Total Household</b> <i>Density </i>(Number of households in a location)</Table.Cell>
                            <Table.Cell><b>64,223</b></Table.Cell>
                            <Table.Cell><b>41,103</b></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Total</b> <i>Financial Wealth </i> (is a broad measure of the financial assets of Canadian households.
                            It encompasses those financial instruments and investments held for the purpose of accumulating wealth)</Table.Cell>
                            <Table.Cell><b>$6.5 billion</b></Table.Cell>
                            <Table.Cell><b>$6 billion</b></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><i>Average Wealth</i> (Used as a quality index: wealth divided
						    by the number of household in a specific locality)</Table.Cell>
                            <Table.Cell><b>$106k</b></Table.Cell>
                            <Table.Cell><b>$145k</b></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table><br />
                <p>The table above clearly shows that PEI is 156% larger than Peterborough
                    in total household density. They are relatively similar from a <i>Financial Wealth </i> (is a broad measure of the financial
                    assets of Canadian households. It encompasses those financial instruments and investments held for the
                        purpose of accumulating wealth) perspective with Peterborough
                    showing and overall higher average wealth figure. As we start to filter
                    things start to change.</p>

                <p>The table below shows the comparative figures based on the 65+ filter
                only. Now, PEI is 131% larger than Peterborough based on households age
                    65 and older. But the <i>Financial Wealth </i>
                    relationship between the two locations is now flipped making Peterborough
                    a 65+ wealth market 139% larger than PEI.</p>

                <Table classname="help-custom-table">
                    <Table.HeaderCell style={{ width: "40%" }}>FILTER:
					Age 65+ Households</Table.HeaderCell>
                    <Table.HeaderCell>Prince Edward Island</Table.HeaderCell>
                    <Table.HeaderCell>Peterborough</Table.HeaderCell>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell><b>Filter Household</b> <i>Density </i>(Number of households in a location)</Table.Cell>
                            <Table.Cell><b>15,851
						        <font size="1"><i> (25.89%)</i></font>
                            </b></Table.Cell>
                            <Table.Cell><b>12,136 <font size="1"><i> (29.53%)</i></font></b></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Filter</b> <i>Financial Wealth </i> (is a broad measure of the financial assets of Canadian households.
                            It encompasses those financial instruments and investments held for the purpose of accumulating wealth)</Table.Cell>
                            <Table.Cell><b>$2.3 billion <font size="1"><i> (36.21%)</i></font> </b></Table.Cell>
                            <Table.Cell><b>$3.2 billion <font size="1"><i> (53.00%)</i></font></b></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><i>Average Wealth</i> (Used as a quality index: wealth divided
						    by the number of household in a specific locality)</Table.Cell>
                            <Table.Cell><b>$148k</b></Table.Cell>
                            <Table.Cell><b>$261k</b></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table><br />


                <p className="line-height">When we add the second dimension to the filter in the image below, the
                number of households age 65+ with at least $500k in financial wealth in
		        Peterborough is 219% larger than PEI, and 146.6% larger from a <i>Financial Wealth </i> (is a broad measure of the financial assets of Canadian households.
                It encompasses those financial instruments and investments held for the purpose of accumulating wealth) perspective. So the
		        Peterborough market seems to be significantly larger than PEI.</p>
                <p>Is that ALL?</p>
                <p className="line-height">Not quite, look at the average wealth of the two locations below, the
                average wealth in PEI is significantly larger although it is a smaller
		        market. What could be causing this and what does it mean?</p>

                <Table classname="help-custom-table">
                    <Table.HeaderCell style={{ width: "40%" }}>FILTER:
					Age 65+ Households with $500k plus of Financial Wealth</Table.HeaderCell>
                    <Table.HeaderCell>Prince Edward Island</Table.HeaderCell>
                    <Table.HeaderCell>Peterborough</Table.HeaderCell>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell><b>Filter Household</b> <i>Density </i>(Number of households in a location)</Table.Cell>
                            <Table.Cell><b>1,027
						        <font size="1"><i> (1.68%)</i></font>
                            </b></Table.Cell>
                            <Table.Cell><b>2,251 <font size="1"><i> (5.48%)</i></font></b></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Filter</b> <i>Financial Wealth </i> (is a broad measure of the financial assets of Canadian households.
                            It encompasses those financial instruments and investments held for the purpose of accumulating wealth)</Table.Cell>
                            <Table.Cell><b>$1.5 billion <font size="1"><i> (26.06%)</i></font> </b></Table.Cell>
                            <Table.Cell><b>$2.2 billion <font size="1"><i> (37.41%)</i></font></b></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><i>Average Wealth</i> (Used as a quality index: wealth divided
						    by the number of household in a specific locality)</Table.Cell>
                            <Table.Cell><b>$1,451k</b></Table.Cell>
                            <Table.Cell><b>$993k</b></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table><br />

                <p className="line-height">Lets look at the filter we saw above broken down in two sub-filters:
		65+ with over a 1 million in <i>Financial Wealth </i> and another 65+ with $500k to $1,000k in <i>Financial Wealth </i>.</p>

                <Table classname="help-custom-table">
                    <Table.HeaderCell style={{ width: "40%" }}>FILTER:
					Age 65+ Households with $1,000k plus of Financial Wealth</Table.HeaderCell>
                    <Table.HeaderCell>Prince Edward Island</Table.HeaderCell>
                    <Table.HeaderCell>Peterborough</Table.HeaderCell>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell><b>Filter Household</b> <i>Density </i>(Number of households in a location)</Table.Cell>
                            <Table.Cell><b>377
						        <font size="1"><i> (0.62%)</i></font>
                            </b></Table.Cell>
                            <Table.Cell><b>1,131 <font size="1"><i> (2.75%)</i></font></b></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Filter</b> <i>Financial Wealth </i> (is a broad measure of the financial assets of Canadian households.
                            It encompasses those financial instruments and investments held for the purpose of accumulating wealth)</Table.Cell>
                            <Table.Cell><b>$1.03 billion <font size="1"><i> (16.00%)</i></font> </b></Table.Cell>
                            <Table.Cell><b>$1.4 billion <font size="1"><i> (24.22%)</i></font></b></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><i>Average Wealth</i> (Used as a quality index: wealth divided
						    by the number of household in a specific locality)</Table.Cell>
                            <Table.Cell><b>$2,743k</b></Table.Cell>
                            <Table.Cell><b>$1,279k</b></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table><br />

                <Table classname="help-custom-table">
                    <Table.HeaderCell style={{ width: "40%" }}>FILTER:
					Age 65+ Households with $500k to 1,000k of Financial Wealth</Table.HeaderCell>
                    <Table.HeaderCell>Prince Edward Island</Table.HeaderCell>
                    <Table.HeaderCell>Peterborough</Table.HeaderCell>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell><b>Filter Household</b> <i>Density </i>(Number of households in a location)</Table.Cell>
                            <Table.Cell><b>650
						        <font size="1"><i> (1.06%)</i></font>
                            </b></Table.Cell>
                            <Table.Cell><b>1,120 <font size="1"><i> (2.72%)</i></font></b></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Filter</b> <i>Financial Wealth </i> (is a broad measure of the financial assets of Canadian households.
                            It encompasses those financial instruments and investments held for the purpose of accumulating wealth)</Table.Cell>
                            <Table.Cell><b>$.456 <font size="1"><i> (7.06%)</i></font> </b></Table.Cell>
                            <Table.Cell><b>$.788 <font size="1"><i> (13.20%)</i></font></b></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><i>Average Wealth</i> (Used as a quality index: wealth divided
						    by the number of household in a specific locality)</Table.Cell>
                            <Table.Cell><b>$702k</b></Table.Cell>
                            <Table.Cell><b>$704k</b></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table><br />

                <Table classname="help-custom-table">
                    <Table.HeaderCell style={{ width: "40%" }}>Ratios</Table.HeaderCell>
                    <Table.HeaderCell>Prince Edward Island</Table.HeaderCell>
                    <Table.HeaderCell>Peterborough</Table.HeaderCell>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell><b>% Millionaires in the filter</b></Table.Cell>
                            <Table.Cell><b>36.70%</b></Table.Cell>
                            <Table.Cell><b>50.24%</b></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>% of Wealth controlled by Millionaires</b>
                           </Table.Cell>
                            <Table.Cell><b>68.66%</b></Table.Cell>
                            <Table.Cell><b>63.63%</b></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table><br />

                <p className="line-height">From the tables above we can clearly see
                    that the size of the market is clearly larger in Peterborough than in
                    PEI. PEI shows a higher quality of Millionaires as the Ratios table shows.
                    Although they constitute only 36.7 % of our original filter they control
                    almost 70% of the wealth. This phenomenon expected when two areas of different
                    level of aggregation are compared, and the cause is usually related to
                    homogeneities that accompany higher levels of granularity. As a location
                    becomes smaller the households within it tend to display more similar
                    attributes. PEI being a province on itself with display a more heterogeneous
                    set of Millionaires including private business owners and/or large real
                    estate owners that will probably be more in the ultra high net worth category
                    and raise the average wealth of the segment.</p>
            </PageContent>
        </div>
    )
}