import React from "react";
import { StoreContext } from "../../common/services/store";
import SubFilterDetailsView from "./sub-filter-details";

export default class SubFilterDetailsLayout extends React.Component {
  static contextType = StoreContext;
  constructor(props) {
    super(props);

    this.state = {
      filterData: {},

      masterData: [],
    };
  }

  render() {
    return (
      <div>
        <SubFilterDetailsView
          masterData={this.state.masterData}
          filterData={this.state.filterData}
        ></SubFilterDetailsView>
      </div>
    );
  }
}
