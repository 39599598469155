import { Navigation, PageContent } from "@iss/client-react-components";
import React from "react";

export function Contact(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Contact"></Navigation.PageHeader>

        <p className="line-height">
          We are always interested in being contacted by our clients with
          questions and feedback :
        </p>

        {/* <p style={{margin:"0px"}}>Victor Toth</p>
                <p >Senior Consultant</p>
                <p>(416) 341-0114 &#160;ext: 220</p>
                <p>victor@iei.ca</p> */}

        <p style={{ margin: "0px", fontWeight: 600 }}>Carlos Cardone</p>
        <p>Senior Managing Director, iNVESTOR ECONOMiCS</p>
        <p>+1-647-943-1640</p>
        <p>Carlos.Cardone@issmarketintelligence.com</p>

        <hr />

        <p style={{ margin: "0px", fontWeight: 600 }}>Mariela Cayaffa</p>
        <p>Client Success, ISS Market Intelligence</p>
        <p>+1-647-943-1629</p>
        <p>Mariela.Cayaffa@issmarketintelligence.com</p>

        <hr />

        <p style={{ margin: "0px", fontWeight: 600 }}>Bijeta Saha</p>
        <p>Client Success, ISS Market Intelligence</p>
        <p>+1 647-943-1630</p>
        <p>Bijeta.Saha@issmarketintelligence.com</p>

        {/* <hr/>

                <p style={{margin:"0px"}}>Carlos Cardone</p>
                <p >Senior Managing Director, iNVESTOR ECONOMiCS</p>
                <p>+1.647.943.1640</p>
                <p>Carlos.Cardone@issmarketintelligence.com</p> */}
      </PageContent>
    </div>
  );
}
