import { Navigation, PageContent } from "@iss/client-react-components";
import React from "react";
import dimensioncube from "./images/dimensioncube.gif";
import dimensiononeintersec from "./images/dimensiononeintersec.gif";
import dimensionthreeintersec from "./images/dimensionthreeintersec.gif";
import dimensiontwointersec from "./images/dimensiontwointersec.gif";
import filterinterfaceoverview1reduced from "./wm_images/filter.JPG";
import filterinterfacethreedimintersec from "./wm_images/filterDimensions.JPG";
import filterrefinement from "./wm_images/filterRefinement.JPG";
import filterresults from "./wm_images/filterResult.JPG";
import sub_filter_details from "./wm_images/subFilter.JPG";

export function FilterOverview(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Filter Overview"></Navigation.PageHeader>
        <p className="line-height">
          The filter is the place where users select subset of interest from the
          segmentation data. By selecting different combinations of segments
          from each dimension users will be able to produce exactly 2,097,151
          possible subsets of the data for each of the approximately 600
          locations in Canada.
        </p>

        <div
          className="helpview-layout"
          style={{ justifyContent: "space-between" }}
        >
          <div>
            <p>COMPONENTS OF THE FILTER</p>
            <p>
              <span
                className="clickable"
                onClick={() => {
                  props.handleChange("FilterDimensions");
                }}
              >
                Filter Dimensions
              </span>
            </p>
            <p>
              <span
                className="clickable"
                onClick={() => {
                  props.handleChange("FilterResults");
                }}
              >
                Filter Results
              </span>
            </p>
            <p>
              <span
                className="clickable"
                onClick={() => {
                  props.handleChange("FilterRefinement");
                }}
              >
                Filter Refinement
              </span>
            </p>
            <p>
              <span
                className="clickable"
                onClick={() => {
                  props.handleChange("SubFilterDetails");
                }}
              >
                Sub Filter Details
              </span>
            </p>
          </div>
          <div>
            <div style={{ margin: "20px" }}>
              <img
                src={filterinterfaceoverview1reduced}
                style={{ height: "300pt" }}
              ></img>
              <br />
            </div>
          </div>
        </div>
      </PageContent>
    </div>
  );
}

export function FilterMechanics(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Filter Mechanics"></Navigation.PageHeader>
        <p>Basic Concepts Introduction</p>

        <p className="line-height">
          This section will introduce some of the basic concepts for those just
          getting started with WealthMINE. &#160;In order to be productive as
          quickly as possible, you need to know about the various drivers used
          to select subsets from the database. The filter is used to extract
          from the database total households and financial wealth associated
          with specific age, Income, and wealth segments.&#160;
        </p>

        <p className="line-height">
          {" "}
          The following topics will be covered in this section:
        </p>

        <ol>
          <li>
            <p className="line-height">
              <span
                className="clickable"
                onClick={() => {
                  props.handleChange("ThreeFundamentalDim");
                }}
              >
                The Three Fundamental Dimensions
              </span>
            </p>
          </li>
          <li>
            <p className="line-height">
              <span
                className="clickable"
                onClick={() => {
                  props.handleChange("DimensionCube");
                }}
              >
                The Dimension Cube
              </span>
            </p>
          </li>
          <li>
            <p className="line-height">
              <span
                className="clickable"
                onClick={() => {
                  props.handleChange("FIAOneDim");
                }}
              >
                Filtering in Action: One Dimension
              </span>
            </p>
          </li>
          <li>
            <p className="line-height">
              <span
                className="clickable"
                onClick={() => {
                  props.handleChange("FIATwoDim");
                }}
              >
                Filtering in Action: Two Dimensions
              </span>
            </p>
          </li>
          <li>
            <p className="line-height">
              <span
                className="clickable"
                onClick={() => {
                  props.handleChange("FIAThreeDim");
                }}
              >
                Filtering in Action: Three Dimensions
              </span>
            </p>
          </li>
        </ol>
      </PageContent>
    </div>
  );
}

export function DimensionCube(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="The Dimension Cube"></Navigation.PageHeader>
        <div
          className="helpview-layout"
          style={{ justifyContent: "space-between" }}
        >
          <div>
            <p className="line-height">
              The dimension cube is a visual representation of how data is
              organized in the Wealth Segmentation Database. There are 7
              segments for each of the three dimensions: Age, Income, and
              Wealth. Each micro-cube encloses household counts and wealth
              totals for each three-dimensional intersection.
            </p>
          </div>
          <div>
            <div style={{ margin: "20px" }}>
              <img src={dimensioncube}></img>
              <br />
            </div>
          </div>
        </div>
      </PageContent>
    </div>
  );
}

export function Intersection3D(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="EXAMPLE: Three dimensional intersection"></Navigation.PageHeader>
        <div
          className="helpview-layout"
          style={{ justifyContent: "space-between" }}
        >
          <div>
            <p className="line-height">
              As we said before each of the 345 sub-cubes has number of
              household and total wealth for each of the combinations of the
              segments of the age, income, and wealth dimensions. In this
              example we have selected households with ages over 75, income
              between 0 and 50k, and wealth of over one million dollars.
            </p>
          </div>
          <div>
            <div style={{ margin: "20px" }}>
              <img src={dimensionthreeintersec}></img>
              <br />
            </div>
          </div>
        </div>
        <p className="line-height">
          <b>
            How is the Filter interface used to select the cube displayed in the
            example above?
          </b>
        </p>

        <div style={{ margin: "20px" }}>
          <img src={filterinterfacethreedimintersec}></img>
          <br />
        </div>
      </PageContent>
    </div>
  );
}

export function ThreeFundamentalDim(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="The Three Fundamental Dimensions"></Navigation.PageHeader>
        <p className="line-height">
          This section will introduce some of the basic concepts for those just
          getting started with WealthMINE. &#160;In order to be productive as
          quickly as possible, you need to know about the various drivers used
          to select subsets from the database. The filter is used to extract
          from the database total households and financial wealth associated
          with specific age, Income, and wealth segments.&#160;
        </p>

        <ul>
          <li>
            <p className="line-height">Age</p>
          </li>
          <li>
            <p className="line-height">Income</p>
          </li>
          <li>
            <p className="line-height">Wealth</p>
          </li>
        </ul>

        <p className="line-height">
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("FIAOneDim");
            }}
          >
            Filtering in Action: One Dimensions
          </span>
        </p>
      </PageContent>
    </div>
  );
}

export function FIAOneDim(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Filtering in Action: One Dimension"></Navigation.PageHeader>

        <div
          className="helpview-layout"
          style={{ justifyContent: "space-between" }}
        >
          <div>
            <p className="line-height">
              The dimension cube is a visual representation of how data is
              organized in the Wealth Segmentation Database. There are 7
              segments for each of the three dimensions: Age, Income, and
              Wealth. Each micro-cube encloses household counts and wealth
              totals for each three-dimensional intersection.
            </p>
          </div>
          <div>
            <div style={{ margin: "20px" }}>
              <img src={dimensiononeintersec}></img>
              <br />
            </div>
          </div>
        </div>
        <p className="line-height">
          <b>
            How is the Filter interface used to select the cube displayed in the
            example above?
          </b>
        </p>

        <div style={{ margin: "20px" }}>
          <img src={filterinterfacethreedimintersec}></img>
          <br />
        </div>

        <p className="line-height">
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("FIATwoDim");
            }}
          >
            Filtering in Action: Two Dimensions
          </span>
        </p>
      </PageContent>
    </div>
  );
}

export function FIATwoDim(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Filtering in Action: Two Dimension"></Navigation.PageHeader>
        <div
          className="helpview-layout"
          style={{ justifyContent: "space-between" }}
        >
          <div>
            <p className="line-height">
              The dimension cube is a visual representation of how data is
              organized in the Wealth Segmentation Database. There are 7
              segments for each of the three dimensions: Age, Income, and
              Wealth. Each micro-cube encloses household counts and wealth
              totals for each three-dimensional intersection.
            </p>
          </div>
          <div>
            <div style={{ margin: "20px" }}>
              <img src={dimensiontwointersec}></img>
              <br />
            </div>
          </div>
        </div>
        <p className="line-height">
          <b>
            How is the Filter interface used to select the cube displayed in the
            example above?
          </b>
        </p>

        <div style={{ margin: "20px" }}>
          <img src={filterinterfacethreedimintersec}></img>
          <br />
        </div>

        <p className="line-height">
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("FIAThreeDim");
            }}
          >
            Filtering in Action: Three Dimensions
          </span>
        </p>
      </PageContent>
    </div>
  );
}

export function FIAThreeDim(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Filtering in Action: Three Dimension"></Navigation.PageHeader>
        <div
          className="helpview-layout"
          style={{ justifyContent: "space-between" }}
        >
          <div>
            <p className="line-height">
              The dimension cube is a visual representation of how data is
              organized in the Wealth Segmentation Database. There are 7
              segments for each of the three dimensions: Age, Income, and
              Wealth. Each micro-cube encloses household counts and wealth
              totals for each three-dimensional intersection.
            </p>
          </div>
          <div>
            <div style={{ margin: "20px" }}>
              <img src={dimensionthreeintersec}></img>
              <br />
            </div>
          </div>
        </div>
        <p className="line-height">
          <b>
            How is the Filter interface used to select the cube displayed in the
            example above?
          </b>
        </p>

        <div style={{ margin: "20px" }}>
          <img src={filterinterfacethreedimintersec}></img>
          <br />
        </div>

        <br />
      </PageContent>
    </div>
  );
}

export function FilterDimensions(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Filter Dimensions"></Navigation.PageHeader>
        <p className="line-height">The first step of the filtering process.</p>

        <p className="line-height">
          There are three fundamental angles from which the Segmentation
          Database may be examined:{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("Age");
            }}
          >
            Age
          </span>
          ,
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("Income");
            }}
          >
            Income
          </span>{" "}
          and
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("FinancialWealth");
            }}
          >
            Financial Wealth
          </span>
          , these angles are referred to as &quot;dimensions&quot;. As displayed
          in the image below, each dimension is broken down into seven
          segments.The Filter Dimension allows users to gain instant feedback on
          up to 2,097,151 possible combinations (sub-sets of the database) of
          dimensional segments making WealthMINE an extremely powerful tool
          without compromising on ease of use.
        </p>

        <p className="line-height">
          In the image below, we have selected a sub-set where households range
          between 45 and 64 (&quot;The Baby Boomers&quot;).
        </p>

        <div style={{ margin: "20px" }}>
          <img src={filterinterfacethreedimintersec}></img>
          <br />
        </div>

        <p className="line-height">
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("FilterResults");
            }}
          >
            Filter Results
          </span>
        </p>
      </PageContent>
    </div>
  );
}
export function FilterResults(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Filter Results"></Navigation.PageHeader>
        <p className="line-height">
          When a filter is selected in the{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("FilterDimensions");
            }}
          >
            Filter Dimensions
          </span>{" "}
          , the first thing we need to do is to examine the characteristics of
          that filter. This will allow us to evaluate its characteristics and if
          it needs further refinement or not. The Filter Results section offers
          just that, it is a window into the filter from different perspectives:
        </p>

        <div className="helpview-layout">
          <div>
            <ol>
              <li>
                <p className="line-height">
                  <b>Household Count </b>: gives graphical and numeric
                  <b>
                    <i> feedback on number of households</i>
                  </b>{" "}
                  in the filter, and what percentage of all household in Canada
                  they represent.
                </p>
              </li>
            </ol>
            <ol start="2">
              <li>
                <p className="line-height">
                  <b>Financial Wealth </b>: gives graphical and numeric{" "}
                  <b>
                    <i>
                      feedback on total financial wealth controlled by the
                      households &#160;in the filter
                    </i>
                  </b>
                  , and what percentage of all Canadian financial wealth they
                  represent.
                </p>
              </li>
            </ol>
            <ol start="3">
              <li>
                <p className="line-height">
                  <b>City Average Wealth</b>: groups the cities/localities based
                  on the Average Wealth in quintiles and then overlays the
                  results of the filter. This{" "}
                  <b>
                    <i>provides a relative perspective on the quality</i>
                  </b>{" "}
                  (average wealth) of the cities in the sample relative to the
                  overall universe (all cities in Canada)
                </p>
              </li>
            </ol>
            <ol start="4">
              <li>
                <p className="line-height">
                  <b>Cities based on Filter Concentration</b>: the matrix in
                  this section has four columns, these columns group
                  cities/municipalities based on household density. For example,
                  the first column labeled &quot;Low&quot;, groups
                  cities/municipalities that have a maximum of two thousand
                  households (note&quot; keep in mind that these relatively low
                  density cities/municipalities could be part of a very large
                  metro area). The rows reflect the{" "}
                  <i>Household Concentration </i>(the ratio of filter household
                  density to the total household density of a specific location
                  expressed as a percentage). &#160;Now, lets focus our
                  attention on the{" "}
                  <b>
                    <i>&quot;Filter Household Concentration range&quot;</i>
                  </b>{" "}
                  (vertical axis) between 30% and 40%, we can see the number 220
                  in the cell totalizing the city count for that row, correct?
                  &#160;What does this 220 mean? It means that in 220 cities in
                  Canada our filter: all households with age between 45 and 64
                  (&quot;Baby Boomers&quot;) represent between 30% and 40% of
                  all the households.
                </p>
              </li>
            </ol>

            <p className="line-height">
              {" "}
              Next Step:{" "}
              <span
                className="clickable"
                onClick={() => {
                  props.handleChange("SubFilterDetails");
                }}
              >
                Sub Filter Details
              </span>
            </p>
          </div>
          <div style={{ margin: "20px" }}>
            <img src={filterresults} style={{ height: "300pt" }}></img>
            <br />
          </div>
        </div>
      </PageContent>
    </div>
  );
}

export function FilterRefinement(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Filter Refinement"></Navigation.PageHeader>
        <p className="line-height">
          The dimension cube is a visual representation of how data is organized
          in the Wealth Segmentation Database. There are 7 segments for each of
          the three dimensions: Age, Income, and Wealth. Each micro-cube
          encloses household counts and wealth totals for each three-dimensional
          intersection.{" "}
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("Intersection3D");
            }}
          >
            EXAMPLE: Three dimensional intersection
          </span>
        </p>
        <div style={{ margin: "20px" }}>
          <img src={filterrefinement}></img>
          <br />
        </div>
      </PageContent>
    </div>
  );
}

export function SubFilterDetails(props) {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Sub Filter Details"></Navigation.PageHeader>
        <p className="line-height">
          The dimension cube is a visual representation of how data is organized
          in the Wealth Segmentation Database. There are 7 segments for each of
          the three dimensions: Age, Income, and Wealth. Each micro-cube
          encloses household counts and wealth totals for each three-dimensional
          intersection.
          <span
            className="clickable"
            onClick={() => {
              props.handleChange("Intersection3D");
            }}
          >
            EXAMPLE: Three dimensional intersection
          </span>
        </p>
        <div style={{ margin: "20px" }}>
          <img src={sub_filter_details}></img>
          <br />
        </div>
      </PageContent>
    </div>
  );
}
