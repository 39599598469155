import {
  Button, DialogButtons,
  Prompt
} from "@iss/client-react-components";
import React from "react";
import Header from "../common/components/header";
import { StoreContext } from "../common/services/store";
import DataViewLayout from "./dataview/layout";

export default class ConsoleLayout extends React.Component {
  static contextType = StoreContext;
  constructor(props) {
    super(props);

    this.state = {
      loadedComponent: DataViewLayout,
    };

    this.onTabChange = this.onTabChange.bind(this);
  }

  onTabChange = (component) => {
    this.setState({ loadedComponent: component });
  };

  componentDidMount() { }

  render() {
    const errorState = { ...this.context.errorState };
    return (
      <>
        {errorState.show && (
          <Prompt.Modal
            type="attention"
            title="Server Error"
            onBeforeDismiss={() => this.context.resetErrorState()}
          >
            {errorState.message}
            <DialogButtons>
              <Button
                kind="secondary"
                onClick={() => this.context.resetErrorState()}
              >
                Close
              </Button>
            </DialogButtons>
          </Prompt.Modal>
        )}
        <div>
          <Header
            onTabChange={(component) => this.onTabChange(component)}
          ></Header>
          <main role="main">
            <div>
              <this.state.loadedComponent />
            </div>
          </main>
        </div>
      </>
    );
  }
}
