import { Navigation, PageContent } from "@iss/client-react-components";
import React from "react";
import wm1 from "./wm_images/wealthmine.JPG";

export function OverViewLayout() {
  return (
    <div>
      <PageContent>
        <Navigation.PageHeader title="Overview"></Navigation.PageHeader>
        <p style={{ lineHeight: "200%" }}>
          WealthMINE is a web based gateway to Investor Economics’ Financial
          Wealth database. With intuitive and visually oriented screens,
          WealthMINE allows users to develop a firm understanding around the
          distribution of Financial Wealth in Canada from multiple perspectives.
        </p>

        <div className="helpview-layout">
          <div>
            <ul>
              <li>
                <p>
                  <b>
                    Learn, search and identify
                    <span style={{ fontWeight: "normal" }}>
                      {" "}
                      areas displaying
                    </span>
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      high concentrations
                    </span>
                    <span style={{ fontWeight: "normal" }}>
                      {" "}
                      of households and financial wealth based on specific
                      characteristics of the group.
                    </span>
                  </b>
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p>
                  <b style={{ fontWeight: "normal" }}>
                    WealthMINE allows users to generate literally{" "}
                    <span style={{ fontWeight: "bold" }}>
                      millions of possible filters (subsets)
                    </span>{" "}
                    providing unique perspectives on Canadian Financial Wealth
                    distribution based on age, income, financial wealth, minimum
                    concentration levels, average wealth, and more.
                  </b>
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p>
                  <b style={{ fontWeight: "normal" }}>
                    <span style={{ fontWeight: "bold" }}>Create subsets</span>{" "}
                    (filters) of the data grouping Canadian.
                  </b>
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p>
                  <b style={{ fontWeight: "normal" }}>
                    All these intelligence delivered through an interface that
                    lends itself to be used by a larger portion of the
                    organization due to its{" "}
                    <span style={{ fontWeight: "bold" }}>
                      simplicity, visual orientation, and intuitiveness
                    </span>
                    .
                  </b>
                </p>
              </li>
            </ul>
          </div>
          <div>
            <img src={wm1} alt="" style={{ align: "right", height: "300pt" }} />
          </div>
        </div>
      </PageContent>
    </div>
  );
}
