import {
  Dialog, PageContent, Typography
} from "@iss/client-react-components";
import _ from "lodash";
import React from "react";
import { Button, Icon } from "semantic-ui-react";
import HttpBaseService from "../../common/services/http.service";
import { StoreContext } from "../../common/services/store";
import SubFilterDetailsLayout from "../../console/subfilter/layout";
import ContextView from "./contextview/contextview";
import FilterDimensions from "./filterdimensions/filterdimensions";
import { FilterRefinementView } from "./filterrefinement/filterrefinement";
import { CityOnFilterConcentration } from "./filterresult/cityonfilterconcentration";
import FilterResultSection from "./filterresult/filterresult";
import "./filterviewlayout.css";
export default class FilterView extends React.Component {
  static contextType = StoreContext;

  constructor(props) {
    super(props);
    this._http = new HttpBaseService();
    this.DEFAULT_STATE = {
      filtering: false,
      filterDimension: {},
      filterRefinement: {},
      contextView: {
        households: 0,
        fWealth: 0,
        avgWealth: 0,
        cities: 0,
      },
      filterGroup: {
        sc: "WMdimFILTER",
        context: "CANADA",
        geolevel: 1,
        provID: 0,
      },
      data: {},
    };
    this.state = _.cloneDeep(this.DEFAULT_STATE);
  }
  componentDidMount() {
    this.setInitialNResetStates();
  }

  setInitialNResetStates = () => {
    this.setState({
      data: this.context.filterData,
      filterDimension: this.context.filterDimension,
      filterRefinement: this.context.filterRefinement,
      contextView: {
        ...this.context.contextTotals,
        fWealth: this.context.contextTotals.Wealth,
      },
    });
  };

  componentWillUnmount() {
    this._http.CANCEL();
  }

  fetchData = () => {
    if (!this.context.filterApplied) {
      this.context.setFilteredData({});
      this.setState(_.cloneDeep(this.DEFAULT_STATE), () =>
        this.setInitialNResetStates()
      );
      return;
    }
    let urlParams = this.context.getUrlParameters();

    urlParams.sc = this.state.filterGroup.sc;
    urlParams.context = this.state.filterGroup.context;
    urlParams.geolevel = this.state.filterGroup.geolevel;
    urlParams.provID = this.state.filterGroup.provID;

    urlParams.age1 = this.state.filterDimension.age1;
    urlParams.age2 = this.state.filterDimension.age2;
    urlParams.age3 = this.state.filterDimension.age3;
    urlParams.age4 = this.state.filterDimension.age4;
    urlParams.age5 = this.state.filterDimension.age5;
    urlParams.age6 = this.state.filterDimension.age6;
    urlParams.age7 = this.state.filterDimension.age7;

    urlParams.inc1 = this.state.filterDimension.inc1;
    urlParams.inc2 = this.state.filterDimension.inc2;
    urlParams.inc3 = this.state.filterDimension.inc3;
    urlParams.inc4 = this.state.filterDimension.inc4;
    urlParams.inc5 = this.state.filterDimension.inc5;
    urlParams.inc6 = this.state.filterDimension.inc6;
    urlParams.inc7 = this.state.filterDimension.inc7;

    urlParams.wth1 = this.state.filterDimension.wth1;
    urlParams.wth2 = this.state.filterDimension.wth2;
    urlParams.wth3 = this.state.filterDimension.wth3;
    urlParams.wth4 = this.state.filterDimension.wth4;
    urlParams.wth5 = this.state.filterDimension.wth5;
    urlParams.wth6 = this.state.filterDimension.wth6;
    urlParams.wth7 = this.state.filterDimension.wth7;

    // urlParams.cmL = this.state.filterRefinement.cmL;
    // urlParams.cmM = this.state.filterRefinement.cmM;
    // urlParams.cmH = this.state.filterRefinement.cmH;
    // urlParams.cmUH = this.state.filterRefinement.cmUH;
    // urlParams.dmL = this.state.filterRefinement.dmL;
    // urlParams.dmM = this.state.filterRefinement.dmM;
    // urlParams.dmH = this.state.filterRefinement.dmH;
    // urlParams.dmUH = this.state.filterRefinement.dmUH;
    // urlParams.awmL = this.state.filterRefinement.awmL;
    // urlParams.awmM = this.state.filterRefinement.awmM;
    // urlParams.awmH = this.state.filterRefinement.awmH;
    // urlParams.awmUH = this.state.filterRefinement.awmUH;

    this.setState({ data: {} });
    // this._http.GET("WMguardFirst.php", urlParams).then((d) => {
    this._http.POST("/data/filtered/by/dimension", urlParams).then((d) => {
      d.Field = d.map((r) => {
        Object.keys(r).forEach(key => {
          r[key] = [r[key]];
        })
        return r;
      })
      this.setState({ data: this.applyFilterRefinement(d), filtering: false });
      this.context.setFilteredData(d);
    });
  };

  applyFilterRefinement = (data) => {
    let filterRefinement = _.cloneDeep(this.context.filterRefinement);
    data.Field = data?.Field?.filter((d) => {
      let select = true;

      // Filter Density Refinement
      if (parseInt(filterRefinement.dmL || 0) > 0) {
        if (
          parseInt(d.Count[0] || 0) > 0 &&
          parseInt(d.Count[0] || 0) <= 2000
        ) {
          select = parseInt(d.fCount[0] || 0) > filterRefinement.dmL;
        }
      }
      if (parseInt(filterRefinement.dmM || 0) > 0) {
        if (
          parseInt(d.Count[0] || 0) > 2000 &&
          parseInt(d.Count[0] || 0) <= 20000
        ) {
          select = parseInt(d.fCount[0] || 0) > filterRefinement.dmM;
        }
      }
      if (parseInt(filterRefinement.dmH || 0) > 0) {
        if (
          parseInt(d.Count[0] || 0) > 20000 &&
          parseInt(d.Count[0] || 0) <= 200000
        ) {
          select = parseInt(d.fCount[0] || 0) > filterRefinement.dmH;
        }
      }
      if (parseInt(filterRefinement.dmUH || 0) > 0) {
        if (parseInt(d.Count[0] || 0) > 200000) {
          select = parseInt(d.fCount[0] || 0) > filterRefinement.dmUH;
        }
      }

      // Filter Household Concentration Refinement
      if (parseFloat(filterRefinement.cmL || 0) > 0) {
        if (
          parseInt(d.Count[0] || 0) > 0 &&
          parseInt(d.Count[0] || 0) <= 2000
        ) {
          select =
            parseInt(d.fCount[0] || 0) / parseInt(d.Count[0] || 1) >
            filterRefinement.cmL;
        }
      }
      if (parseFloat(filterRefinement.cmM || 0) > 0) {
        if (
          parseInt(d.Count[0] || 0) > 2000 &&
          parseInt(d.Count[0] || 0) <= 20000
        ) {
          select =
            parseInt(d.fCount[0] || 0) / parseInt(d.Count[0] || 1) >
            filterRefinement.cmM;
        }
      }
      if (parseFloat(filterRefinement.cmH || 0) > 0) {
        if (
          parseInt(d.Count[0] || 0) > 20000 &&
          parseInt(d.Count[0] || 0) <= 200000
        ) {
          select =
            parseInt(d.fCount[0] || 0) / parseInt(d.Count[0] || 1) >
            filterRefinement.cmH;
        }
      }
      if (parseFloat(filterRefinement.cmUH || 0) > 0) {
        if (parseInt(d.Count[0] || 0) > 200000) {
          select =
            parseInt(d.fCount[0] || 0) / parseInt(d.Count[0] || 1) >
            filterRefinement.cmUH;
        }
      }

      // Filter Average Wealth Refinement
      if (parseFloat(filterRefinement.awmL || 0) > 0) {
        if (
          parseInt(d.Count[0] || 0) > 0 &&
          parseInt(d.Count[0] || 0) <= 2000
        ) {
          select =
            parseInt(d.fWealth[0] || 0) / parseInt(d.fCount[0] || 1) >
            filterRefinement.awmL;
        }
      }
      if (parseFloat(filterRefinement.awmM || 0) > 0) {
        if (
          parseInt(d.Count[0] || 0) > 2000 &&
          parseInt(d.Count[0] || 0) <= 20000
        ) {
          select =
            parseInt(d.fWealth[0] || 0) / parseInt(d.fCount[0] || 1) >
            filterRefinement.awmM;
        }
      }
      if (parseFloat(filterRefinement.awmH || 0) > 0) {
        if (
          parseInt(d.Count[0] || 0) > 20000 &&
          parseInt(d.Count[0] || 0) <= 200000
        ) {
          select =
            parseInt(d.fWealth[0] || 0) / parseInt(d.fCount[0] || 1) >
            filterRefinement.awmH;
        }
      }
      if (parseFloat(filterRefinement.awmUH || 0) > 0) {
        if (parseInt(d.Count[0] || 0) > 200000) {
          select =
            parseInt(d.fWealth[0] || 0) / parseInt(d.fCount[0] || 1) >
            filterRefinement.awmUH;
        }
      }

      return select;
    });
    return data;
  };

  render() {
    return (
      <PageContent>
        {this.state.filtering && (
          <Dialog onBeforeDismiss={() => this.setState({ filtering: false })}>
            <Typography style={{ background: "white", padding: "1rem" }}>
              Filtering...
            </Typography>
          </Dialog>
        )}
        <ContextView contextTotals={this.state.contextView}></ContextView>
        <div className="filter-view-grid">
          <div className="filter-view-sub-grid left-width">
            <FilterResultSection
              contextViewData={this.state.contextView}
              masterData={this.state.data}
            ></FilterResultSection>
            <div className="horizontal-line-divider mt-1-pct"></div>
            <CityOnFilterConcentration
              masterData={this.state.data}
              contextViewData={this.state.contextView}
            ></CityOnFilterConcentration>
          </div>
          <div className="filter-view-sub-grid right-width">
            {!this.context.subFilterDisplay && (
              <div>
                <FilterDimensions
                  filterDimensionData={this.state.filterDimension}
                  onChange={(data) => {
                    this.setState((state) => ({
                      ...state,
                      filterDimension: {
                        ...state.filterDimension,
                        [data.attribute]: data.value,
                      },
                    }));
                  }}
                ></FilterDimensions>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "5px",
                    marginRight: "5px",
                  }}
                >
                  {/* <div className="horizontal-line-divider w-80"></div> */}
                  <hr className="horizontal-line-divider w-80" />
                  <div>
                    <Button
                      icon
                      labelPosition="right"
                      className="float-button-color"
                      onClick={() => {
                        this.setState({ filtering: true });
                        this.context
                          .setFilterData(
                            this.state.filterDimension,
                            this.state.filterRefinement
                          )
                          .then(() => {
                            this.fetchData();
                          });
                      }}
                    >
                      Filter
                      <Icon name="filter" />
                    </Button>
                  </div>
                </div>
                <FilterRefinementView
                  filterRefinementData={this.state.filterRefinement}
                  onChange={(data) => {
                    if (!+data.value && +data.value !== 0) {
                      return;
                    }
                    this.setState((state) => ({
                      filterRefinement: {
                        ...state.filterRefinement,
                        [data.attribute]: data.value,
                      },
                    }));
                  }}
                ></FilterRefinementView>
              </div>
            )}
            {this.context.subFilterDisplay && (
              <SubFilterDetailsLayout></SubFilterDetailsLayout>
            )}
          </div>
        </div>
        {/* {!this.context.subFilterDisplay && (
          <div className="float-button">
            <Button
              icon
              labelPosition="right"
              className="float-button-color"
              onClick={() => {
                this.setState({ filtering: true });
                this.context.setFilterData(
                  this.state.filterDimension,
                  this.state.filterRefinement
                );
                this.fetchData();
              }}
            >
              Filter
              <Icon name="filter" />
            </Button>
          </div>
        )} */}
      </PageContent>
    );
  }
}
