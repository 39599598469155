import { Typography } from "@iss/client-react-components";
import React from "react";
import "./filterdimensions.css";
import MultiListChooser from "./multilistchooser";

export default class FilterDimensions extends React.Component {
  constructor(props) {
    super(props);
    //  console.log('Filter Dimension Props ', props)

    this.state = {
      filterDimensions: {
        age: {
          "0 to 24": false,
          "25 to 34": false,
          "35 to 44": false,
          "45 to 54": false,
          "55 to 64": false,
          "65 to 74": false,
          "75 plus": false,
        },
        inc: {
          "0 to 50k": false,
          "50k to 75k": false,
          "75k to 100k": false,
          "100k to 150k": false,
          "150k to 200k": false,
          "200k to 250k": false,
          "250k plus": false,
        },
        wth: {
          "0 to 50k": false,
          "50k to 100k": false,
          "100k to 250k": false,
          "250k to 500k": false,
          "500k to 750k": false,
          "750k to 1,000k": false,
          "1,000k plus": false,
        },
      },
    };

    this.onSelect = this.onSelect.bind(this);
  }
  componentDidUpdate(prevProps) {
    // console.log(prevProps !== this.props)
    if (prevProps !== this.props) {
      this.setFilterState();
    }
  }
  componentDidMount(props) {
    this.setFilterState();
  }

  setFilterState() {
    if (this.props.filterDimensionData !== null) {
      let dimensionsToUpdate = Object.keys(
        this.props.filterDimensionData
      ).filter(
        (d) =>
          this.props.filterDimensionData[d] !== 8 &&
          this.props.filterDimensionData[d] !== undefined
      );
      dimensionsToUpdate.forEach((dim) => {
        let dimension = dim.substring(0, 3);
        let index = dim.substring(3, 4);
        let filterDimension = this.state.filterDimensions;
        filterDimension[dimension][
          Object.keys(filterDimension[dimension])[index - 1]
        ] = true;
        this.setState({ filterDimensions: filterDimension });
      });
      this.setState({
        filterDimensions: this.state.filterDimensions,
      });
    }
  }

  onSelect = (dimension, range) => {
    let dim = this.state.filterDimensions[dimension];
    let objIndex = Object.keys(dim).findIndex((d) => d === range) + 1;
    let dimToUpdate = dimension + objIndex;
    dim[range] = !dim[range];
    dim[range]
      ? this.props.onChange({
          attribute: dimToUpdate,
          value: objIndex,
        })
      : this.props.onChange({
          attribute: dimToUpdate,
          value: 8,
        });
    this.setState((state) => ({
      filterDimensions: { ...state.filterDimensions, [dimension]: dim },
    }));
  };

  render() {
    return (
      <div>
        <div className="title">
          <Typography.Heading4>Filter Dimensions</Typography.Heading4>
        </div>
        <div className="filter-dimensions-layout">
          <MultiListChooser
            title="Age"
            items={this.state.filterDimensions.age}
            onSelect={(range) => {
              this.onSelect("age", range);
            }}
          ></MultiListChooser>
          <MultiListChooser
            title="Income"
            items={this.state.filterDimensions.inc}
            onSelect={(range) => this.onSelect("inc", range)}
          ></MultiListChooser>
          <MultiListChooser
            title="Financial Wealth"
            items={this.state.filterDimensions.wth}
            onSelect={(range) => this.onSelect("wth", range)}
          ></MultiListChooser>
        </div>
      </div>
    );
  }
}
