/*
2024/05/31 - shetdha - Updated all occurrences of local function attemptLogout to Okta's signout
2024/06/02 - shetdha - Imported React and returned null to call this as a component
*/
import React from "react";
import axios from "axios";
import { useContext } from "react";
import { StoreContext } from "./store";
import xml2js from "xml2js";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from '@okta/okta-auth-js';

function SetupInterceptors() {
  const { setErrorState } = useContext(StoreContext);
  const { oktaAuth, authState } = useOktaAuth();

  // const axiosInstance = axios.create({
  //   baseURL: 'https://dev-71691617.okta.com',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  // });

  axios.interceptors.response.use(
    (res) => {
      // if (res !== undefined) {
      //   xml2js.parseString(res.data, async (err, res) => {
      //     // console.log(res);
      //     if (res?.root === "Access denied") {
      //       // attemptLogout();
      //       await oktaAuth.signOut();
      //     } else if (
      //       res?.root?.expired &&
      //       res?.root?.expired[0] === "expired"
      //     ) {
      //       // attemptLogout();
      //       await oktaAuth.signOut();
      //     } else if (
      //       res?.root?.unauthorized &&
      //       res?.root?.unauthorized[0] === "unauthorized"
      //     ) {
      //       // attemptLogout();
      //       await oktaAuth.signOut();
      //     }
      //   });
      //   return res;
      // }
      return res;
    },
    async (error) => {
      if (!axios.isCancel(error)) {
        // if (error.response.status === 403 || error.response.status === 401) {
        // attemptLogout();
        // await oktaAuth.signOut();
        // } else {
        // if(error.response.status.includes(403, 401))
        if (error.response.status === 403 || error.response.status === 401)
        {
          oktaAuth.signInWithRedirect();  
        }
        else
          setErrorState(500, "Some Error Occured on the server. Please retry");
        // }
      }
      return Promise.reject(error);
    }
  );

  return null;
}

export default SetupInterceptors;
