import React from "react";
import { Contact } from "./contact";
import { DataDownloads, DataStructure } from "./data-downloads";
import { Age, FinancialWealth, Income } from "./dimensions";
import {
  DimensionCube, FIAOneDim, FIAThreeDim, FIATwoDim, FilterDimensions, FilterMechanics, FilterOverview, FilterRefinement, FilterResults, Intersection3D, SubFilterDetails, ThreeFundamentalDim
} from "./filter";
import {
  AverageWealth,
  ConcentrationQuadrant,
  FrameworkOverview,
  QuadrantE1,
  QuadrantE2,
  ResultsE12
} from "./framework";
import { Glossary } from "./glossary";
import { OverViewLayout } from "./overview";
import { OverViewVideoLayout } from "./overview-video";
import Sidebar from "./sidebar";
import {
  ContentSelector, DataDisplay, DownloadData, FilterMonitor, FilterPanel, GroupSelector, LevelMonitor, ViewLayout
} from "./view";

class HelpViewLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentToBeLoaded: OverViewLayout,
      componentMapping: {
        Age: Age,
        Income: Income,
        FinancialWealth: FinancialWealth,
        OverViewLayout: OverViewLayout,
        OverViewVideoLayout: OverViewVideoLayout,
        ViewLayout: ViewLayout,
        DataDisplay: DataDisplay,
        LevelMonitor: LevelMonitor,
        FilterMonitor: FilterMonitor,
        FilterOverview: FilterOverview,
        GroupSelector: GroupSelector,
        ContentSelector: ContentSelector,
        DownloadData: DownloadData,
        FilterPanel: FilterPanel,
        FrameworkOverview: FrameworkOverview,
        AverageWealth: AverageWealth,
        ConcentrationQuadrant: ConcentrationQuadrant,
        QuadrantE1: QuadrantE1,
        QuadrantE2: QuadrantE2,
        ResultsE12: ResultsE12,
        DataDownloads: DataDownloads,
        DataStructure: DataStructure,
        Contact: Contact,
        FilterMechanics: FilterMechanics,
        DimensionCube: DimensionCube,
        Intersection3D: Intersection3D,
        ThreeFundamentalDim: ThreeFundamentalDim,
        FIAOneDim: FIAOneDim,
        FIATwoDim: FIATwoDim,
        FIAThreeDim: FIAThreeDim,
        FilterDimensions: FilterDimensions,
        FilterResults: FilterResults,
        FilterRefinement: FilterRefinement,
        SubFilterDetails: SubFilterDetails,
        Glossary: Glossary,
      },
    };
  }

  render() {
    return (
      <div className="helpview-layout">
        <div className="left-width-help">
          <Sidebar
            onClick={(component) => {
              this.setState((state) => ({
                ...state,
                componentToBeLoaded: state.componentMapping[component],
              }));
            }}
          />
        </div>

        <div className="right-width-help">
          <this.state.componentToBeLoaded
            handleChange={(component) => {
              this.setState((state) => ({
                ...state,
                componentToBeLoaded: state.componentMapping[component],
              }));
            }}
          />

          <div style={{ width: "100%", position: "relative" }} id="footer">
            {/* <p style={{color: "#b2b2b2", textAlign: "center"}}>© Investor Economics
	                    is a division of Asset International<font size="2">. 2013. All rights
	                    reserved.</font></p> */}
          </div>
        </div>
      </div>
    );
  }
}

export default HelpViewLayout;
