const CLIENT_ID = '{{OKTA_CLIENT_ID}}';
const ISSUER = '{{OKTA_ISSUER}}';
const REDIRECT_URI = `${window.location.origin}/login/callback`;

export const oktaAuthConfig = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  pkce: true,
  responseType: ['id_token', 'token'],
  tokenManager: {
    autoRenew: true,
    secure: true,
    storage: 'localStorage',
    syncStorage: true,
  },
};