import { Typography } from "@iss/client-react-components";
import _ from "lodash";
import React from "react";
import { calculatePercent, toMoneyFormat } from "../../../common/functions";
import AvgFinancialWealthSection from "./avgfinancialwealth";
import { DoughNutChart } from "./doughnut";
export default class FilterResultSection extends React.Component {
  constructor(props) {
    super(props);
    this.DEFAULT_STATE = {
      filteredHousehold: 0,
      filteredFWealth: 0,
      filteredData: {},
      householdSlice: [0, 100],
      fWealthSlice: [0, 100],
      dataLoaded: false,
    };
    this.state = _.cloneDeep(this.DEFAULT_STATE);
  }
  getResponseData(masterData) {
    let data = masterData;
    return data["Field"]?.map((data) => ({
      City: data.City[0] ? data.City[0] : " ",
      Count: data.Count[0] ? data.Count[0] : 0,
      fCount: data.fCount[0] ? data.fCount[0] : 0,
      fWealth: data.fWealth[0] ? data.fWealth[0] : 0,
    }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.masterData !== null) {
        let filterData = this.getResponseData({ ...this.props.masterData });
        let filteredHousehold = filterData?.reduce(
          (acc, data) => acc + parseFloat(data.fCount),
          0
        );
        let filteredFWealth = filterData
          ?.reduce((acc, data) => acc + parseFloat(data.fWealth), 0.0)
          .toFixed(2);
        this.setState(
          {
            filteredData: filterData,
            filteredHousehold: filteredHousehold ? filteredHousehold : 0,
            filteredFWealth: filteredFWealth ? filteredFWealth : 0,
          },
          () => {
            this.getSliceData();
          }
        );
      } else {
        this.setState(_.cloneDeep(this.DEFAULT_STATE));
      }
    }
  }

  getSliceData() {
    let householdSlice = [
      this.state.filteredHousehold,
      this.props.contextViewData.households - this.state.filteredHousehold,
    ];
    let fWealthSlice = [
      this.state.filteredFWealth,
      this.props.contextViewData.fWealth - this.state.filteredFWealth,
    ];
    this.setState({
      householdSlice: householdSlice ? householdSlice : [0, 100],
      fWealthSlice: fWealthSlice ? fWealthSlice : [0, 100],
      dataLoaded: true,
    });
  }

  render() {
    return (
      <div>
        <div className="title">
          <Typography.Heading4>Filter Result</Typography.Heading4>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            // marginTop: "10px",
          }}
        >
          <DoughNutChart
            title={"Households"}
            count={
              toMoneyFormat(this.state.householdSlice[0]) +
              " (" +
              calculatePercent(
                this.state.householdSlice[0],
                this.props.contextViewData.households
              ) +
              "%)"
            }
            slices={this.state.householdSlice}
            labels={["Filtered", "Context"]}
          />
          <div>
            <AvgFinancialWealthSection
              filteredApiResponse={this.state.filteredData}
            ></AvgFinancialWealthSection>
          </div>
          <DoughNutChart
            title={"Financial Wealth"}
            count={
              "$" +
              toMoneyFormat(this.state.fWealthSlice[0], 2) +
              "k" +
              " (" +
              calculatePercent(
                this.state.fWealthSlice[0],
                this.props.contextViewData.fWealth
              ) +
              "%)"
            }
            slices={this.state.fWealthSlice}
            labels={["Filtered", "Context"]}
          />
        </div>
      </div>
    );
  }
}
