import { Typography } from "@iss/client-react-components";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { toMoneyFormat } from "../../../common/functions";

export const DoughNutChart = (props) => {
  let data = {
    labels: props.labels,
    datasets: [
      {
        data: props.slices,
        backgroundColor: ["#F7B333", "#911D00"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB"],
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    responsive: false,
    legend: {
      position: "bottom",
      labels: {
        boxWidth: 10,
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          return toMoneyFormat(
            data["datasets"][0]["data"][tooltipItem["index"]]
          );
        },
      },
    },
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Typography.SubHeading>{props.title}</Typography.SubHeading>
        <Typography.SubText1>{props.count}</Typography.SubText1>
        <div style={{ marginTop: "15%" }}>
          <Doughnut data={data} options={options} />
        </div>
      </div>
    </div>
  );
};
