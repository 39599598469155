import { Navigation, PageContent } from '@iss/client-react-components'
import React from 'react'
import { Table } from 'semantic-ui-react'
import './helpview.css'

export function Age() {
    return (
        <div>
            <PageContent>
                <Navigation.PageHeader title="Age">
                </Navigation.PageHeader>
                <p style={{ lineHeight: "200%" }}>The household age will fall in only one of
                the seven age segments determined by the age of Older spouse for Couple
                families, age of Lone-Parent or age of the Non-Family Persons.</p>

                <Table style={{ width: "30%" }} className="help-custom-table">

                    <Table.HeaderCell>Segments of the Age dimension</Table.HeaderCell>
                    <Table.Body>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>0 to 24</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>25 to 34</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>35 to 44</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>45 to 54</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>55 to 64</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>65 to 74</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>75 and above</Table.Cell></Table.Row>
                    </Table.Body>
                </Table>
            </PageContent>
        </div>
    )
}

export function Income() {
    return (
        <div>
            <PageContent>
                <Navigation.PageHeader title="Income">
                </Navigation.PageHeader>
                <p style={{ lineHeight: "200%" }}>In the context of WealthMINE, households are
                            grouped in seven possible income segments.</p>

                <Table style={{ width: "30%" }} className="help-custom-table">

                    <Table.HeaderCell>Segments of the Income dimension</Table.HeaderCell>
                    <Table.Body>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>$0 to $50,000</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>$50,000 to $75,000</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>$75,000 to $100,000</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>$100,000 to $150,000</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>$150,000 to $200,000</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>$200,000 to $250,000</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>$250,000 and above</Table.Cell></Table.Row>
                    </Table.Body>
                </Table>

                <Table className="help-custom-table">
                    <Table.HeaderCell style={{ width: "40%" }}>Components of the Income dimension</Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "60%" }}>Components</Table.HeaderCell>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={{ fontWeight: "bold"  , textAlign:"center"}}>Labour income</Table.Cell>
                            <Table.Cell><ul >
                                <li><p >Employment income</p></li>
                                <li><p >Wages/salaries/commissions</p></li>
                                <li><p >Other employment income
			                        (tips, gratuities, royalties, etc.)</p></li>
                                <li><p >Net self-employment</p></li>
                                <li><p >Indian Employment Income</p></li>
                                <li><p >Employment insurance (EI)
			                    benefits</p></li>
                            </ul></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ fontWeight: "bold" , textAlign:"center" }}>Pension income</Table.Cell>
                            <Table.Cell><ul>
                                <li><p >Old Age Security pension
                                benefits/net federal supplements (the latter including guaranteed
			                    income supplements and spouses' allowances since 1994)</p></li>
                                <li><p >Canada/Quebec Pension
			                    Plan benefits</p></li>
                                <li><p >Superannuation and other
			                    (private) pensions</p></li>
                            </ul></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ fontWeight: "bold" , textAlign:"center"}}>Other non-taxable income</Table.Cell>
                            <Table.Cell><ul>
                                <li><p >Workers' compensation payments</p></li>
                                <li><p >Social assistance payments</p></li>
                                <li><p >Guaranteed income supplements</p></li>
                                <li><p >Spouses' allowances</p></li>
                            </ul></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ fontWeight: "bold" , textAlign:"center"}}>Other sources</Table.Cell>
                            <Table.Cell><ul>
                                <li><p >Federal Family Allowance benefits</p></li>
                                <li><p >Quebec Family Allowance</p></li>
                                <li><p >Quebec Child Support Payment</p></li>
                                <li><p >British Columbia Family Bonus</p></li>
                                <li><p >New Brunswick Child Benefit Supplement</p></li>
                                <li><p >Alberta Family Employment Tax Credit</p></li>
                                <li><p >Northwest Territories Child Benefit</p></li>
                                <li><p >Nova Scotia Child Tax Benefit</p></li>
                                <li><p >Nunavut Child Benefit</p></li>
                                <li><p >Ontario Child Benefit which integrates the Ontario Child Care Supplement for Working Families</p></li>
                                <li><p >Saskatchewan Child Benefit</p></li>
                                <li><p >Newfoundland and Labrador Child Benefit</p></li>
                                <li><p >Yukon Child Benefit</p></li>
                                <li><p >Interest and other investment income</p></li>
                                <li><p >Dividend income</p></li>
                                <li><p >RRIF income (only tax filers 65+)</p></li>
                                <li><p >Net limited partnership income</p></li>
                                <li><p >Alimony</p></li>
                                <li><p >Net rental income</p></li>
                                <li><p >Income for non-filing spouses</p></li>
                                <li><p >Other incomes as reported on line 130 of the tax form (fellowships, bursaries, grants,registered disability savings plan, etc.; included in &quot;other
			                        income&quot;)</p></li>
                                <li><p >Federal sales tax (FST) credit</p></li>
                                <li><p >Goods and services tax (GST) credit</p></li>
                                <li><p >Harmonized sales tax (HST)  credit</p></li>
                                <li><p >Child tax credit</p></li>
                                <li><p >Canada Child Tax Benefit and Universal Child Care Benefit</p></li>
                                <li><p >Manitoba Child Tax Benefit</p></li>
                            </ul></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <br/>
            </PageContent>
        </div>
    )
}

export function FinancialWealth() {
    return (
        <div>
            <PageContent>
                <Navigation.PageHeader title="Financial Wealth">
                </Navigation.PageHeader>
                <p style={{ lineHeight: "200%" }}>The
                    assets owned by households as reflected in Investor Economics <i>Segmentation </i>
                    and <i >WealthMINE</i> research align
                    with the concept of <b>Financial
                    Wealth</b> as defined in the <i>Household
                    Balance Sheet Report</i>.</p>
                <p>In the context of WealthMINE, financial wealth is
                    provided as a total number and households are grouped in seven segments.</p>

                <Table style={{ width: "30%" }} className="help-custom-table">

                    <Table.HeaderCell>Segments of the Financial Wealth dimension</Table.HeaderCell>
                    <Table.Body>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>$0 to $50,000</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>$50,000 to $100,000</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>$100,000 to $250,000</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>$250,000 to $500,000</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>$500,000 to $750,000</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>$750,000 to $1,000,000</Table.Cell></Table.Row>
                        <Table.Row><Table.Cell style={{textAlign:"center"}}>$1,000,000 and above</Table.Cell></Table.Row>
                    </Table.Body>
                </Table>
                <br />
                <p><b ><span>Financial wealth</span></b> <span >is a broad measure
                of the financial assets of Canadian households. It encompasses those financial
                    instruments and investments held for the purpose of accumulating wealth.</span></p>
                <p>From a business line analysis, <b>financial
                    wealth</b> includes the following components:</p>

                <Table className="help-custom-table">
                    <Table.HeaderCell style={{ width: "40%" }}>Components of the Total Financial Wealth dimension</Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "60%" }}>Components</Table.HeaderCell>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={{ fontWeight: "bold", textAlign: 'left' }}><b>Deposits</b></Table.Cell>
                            <Table.Cell>
                                <ul style={{ textAlign: "left" }}>
                                    <li><p>Savings Deposits</p></li>
                                    <li><p>GICs</p></li>
                                    <li><p>GIAs</p></li>
                                    <li><p>Market-linked GICs.</p></li>
                                </ul>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ textAlign: "left" }}><p><b><span
                                style={{ fontWeight: "bold" }}>Transaction-based assets</span></b> is the component
                                of the financial wealth where the revenue stream is derived from
                                commissions on the purchase or disposal of the instrument. Included
                                here are short-term paper, bonds, and equities in transaction-based
                                accounts.</p>
                            </Table.Cell>
                            <Table.Cell><ul style={{ textAlign: "left" }}>
                                <li><p><b>Short-term instruments</b> include T-bills, commercial paper and bankers’
                                acceptances.</p></li>
                                <li><p><span><b>Bonds</b></span> include Canada
                                and provincial savings bonds, bonds, and mortgage-backed securities
                                that are directly held (i.e. not held in managed asset categories
                                or investment funds).</p></li>
                                <li><p><span><b><span>Equities</span></b></span> represent the assets in common and preferred shares and income
                                trusts, held directly (i.e. not held in managed asset categories
                                or investment funds).</p></li>
                            </ul>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ textAlign: "left" }}>
                                <p><b><span>Fee-based assets</span></b> consist of financial
                            assets where the primary income stream is derived from sustainable
                            recurring revenues, such as management fees that are based on
                            the market value of the assets. Investment funds are an example
                            of fee-based assets.</p>
                            </Table.Cell>
                            <Table.Cell style={{ textAlign: "left" }}><ul>
                                <li><p><b><span>Stand-alone investment funds </span></b><span>
                                    are comprised of mutual funds, segregated funds and insurance
                                    company group pooled funds that are not wrapped or repackaged
                                    into managed assets, universal life insurance policies or
                            hedge funds.</span></p></li>
                                <li><p><b><span>Managed assets</span></b><span> include fund
                                wraps, fee-based brokerage, advisor managed, in-house managed
                                wraps, separately managed wraps, pooled funds, separately
                                managed accounts, estates and trusts, and the investment portion
                            of universal life policies.</span></p></li>
                            </ul></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <br />
                <p>From a distribution channel point of view, <b>financial
                    wealth</b> includes:</p>
                <Table className="help-custom-table">
                    <Table.HeaderCell style={{ width: "40%" }}>Components of the Total Financial Wealth dimension</Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "60%" }}>Components</Table.HeaderCell>
                    <Table.Body >
                        <Table.Row>
                            <Table.Cell ><b>Bank branches</b></Table.Cell>
                            <Table.Cell ><ul>
                                <li><p><b><span>Branch direct </span></b><span>
                                    is largely focused on deposit-taking and lending, and retains
                                    only a portion of managed asset sales in the form of funds
                                of funds and some packaged fund wraps.</span></p></li>
                                <li><p><b><span>Branch advice </span></b><span>
                                    encompasses the financial wealth asset-gathering activities
                                    of financial advisors and financial planners who operate within
                                    the deposit-taker branch networks. Examples include CIBC Imperial
                                    Service, TD Waterhouse Financial Planning, and RBC Financial
                                Planning.</span></p></li>
                            </ul></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Online/discount brokerage</b></Table.Cell>
                            <Table.Cell style={{ paddingLeft: "35px" }}><p>Covers
                            a wide range of trading activity including direct access to domestic
		                    and foreign markets.</p></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Direct to public</b></Table.Cell>
                            <Table.Cell style={{ paddingLeft: "35px" }}><p><span>Encompasses
                            the activities of manufacturers of mutual funds that do not rely
                            on advice-giving intermediaries for distribution such as Phillips,
		                    Hager &amp; North (now owned by RBC), Sceptre and Mawer</span></p></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Financial advisors</b></Table.Cell>
                            <Table.Cell style={{ paddingLeft: "35px" }}><p><span>Often
                            referred to colloquially as “financial planners”—operate in both
		                    independent firms and the dedicated sales forces of product manufacturers.</span>
                            </p></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Full-service brokers</b></Table.Cell>
                            <Table.Cell style={{ paddingLeft: "35px" }}><p><span>Investment
                            advisors in member firms of the Investment Industry Regulatory
                            Organization of Canada. These individuals sell a wide range of
                            investment instruments and insurance products, either on a commission
		                    or fee-based basis.</span></p></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Private Investment Counsel (PIC)</b></Table.Cell>
                            <Table.Cell style={{ paddingLeft: "35px" }}><p><span>Encompasses
                            the assets gathered through the private client services of money
                            managers. The key offerings are pooled funds and separately managed—or
		                    segregated—accounts.</span></p></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Directly held securities</b></Table.Cell>
                            <Table.Cell style={{ paddingLeft: "35px" }}><p><span>Refers
                            to all financial wealth assets that are not held or acquired through
                            one of the delivery channels described above. This includes Canada
                            Savings Bonds purchased via payroll deduction, dividend reinvestment
                            plans, and certain categories of securities that are held under
                            the investor’s own name, not in street form (e.g. bonds and stock
                            certificates kept in safety deposit boxes or held directly with
		                    custodians).</span></p></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <br />

            </PageContent>
        </div>
    )
}