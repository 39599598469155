import React, { useEffect, useState } from "react";
import { Table, Pagination } from "semantic-ui-react";
import {
  getColorClass,
  getMaxValue,
  getMinValue,
  getSortAttributes,
  getSum,
  getWeight,
  paginate,
  toMoneyFormat,
  getNewWeight,
  checkDecimal,
} from "../../common/functions";
import _ from "lodash";

export default function DataView(props) {
  const [state, setState] = useState({ selectedKey: "", currentGroup: {} });
  useEffect(() => {
    let selectedKey = Object.keys(props.groupInfo).find((key) => {
      return props.groupInfo[key].selected === true;
    });
    let currentGroup = props.groupInfo[selectedKey];
    let contextInformation = _.cloneDeep(props.contextInfo);
    let data = props.data.Field?.map((record, index) => ({
      ...record,
      fCount: [Math.round(record.fCount[0])],
      Count: [Math.round(record.Count[0])],
      fWealth: [Math.round(record.fWealth[0])],
      Wealth: [Math.round(record.Wealth[0])],
      WealthForContextSum: [record.Wealth[0]], // This is because due to some reason, context sum uses default value and not the round one
      fWealthForContextSum: [record.fWealth[0]], // This is because due to some reason, context sum uses default value and not the round one
      cCount: [(record.fCount[0] / record.Count[0]) * 100],
      cWealth: [(record.fWealth[0] / record.Wealth[0]) * 100],
      fAvg: [Math.round(record.fWealth[0] / record.fCount[0])],
      Avg: [Math.round(record.Wealth[0] / record.Count[0])],
      value: record[props.groupInfo[selectedKey].valueField],
      valueDisplayed:
        index + 1 + ". " + record[props.groupInfo[selectedKey].valueField],
    }));
    let contextInfo = {
      count: contextInformation.households,
      wealth: contextInformation.Wealth,
      fCount: contextInformation.fCount,
      cCount: (contextInformation.fCount / contextInformation.households) * 100,
      fWealth: contextInformation.fWealth,
      cWealth: (contextInformation.fWealth / contextInformation.Wealth) * 100,
      avgWealth: contextInformation.Wealth / contextInformation.households,
      fAvgWealth: contextInformation.fWealth / props.contextInfo.fCount,
    };
    let currentContextInfo = {
      count: getSum(data, "Count"),
      wealth: Math.round(getSum(data, "WealthForContextSum")),
      fCount: getSum(data, "fCount"),
      cCount: (getSum(data, "fCount") / getSum(data, "Count")) * 100,
      fWealth: getSum(data, "fWealthForContextSum"),
      cWealth: (getSum(data, "fWealth") / getSum(data, "Wealth")) * 100,
      avgWealth: getSum(data, "Wealth") / getSum(data, "Count"),
      fAvgWealth: getSum(data, "fWealth") / getSum(data, "fCount"),
    };

    data = data?.map((record, index) => ({
      ...record,
      cWeight: [getNewWeight(currentContextInfo.count, record["Count"], 2)],
    }));

    setState({
      inputData: data,
      // filteredData: paginate(data, 15, 1),
      filteredData: data,
      selectedKey: selectedKey,
      currentGroup: currentGroup,
      contextInfo: contextInfo,
      currentContextInfo: currentContextInfo,
      count: {
        min: getMinValue(data, "Count"),
        max: getMaxValue(data, "Count"),
      },
      fcount: {
        min: getMinValue(data, "fCount"),
        max: getMaxValue(data, "fCount"),
      },
      ccount: {
        min: getMinValue(data, "cCount"),
        max: getMaxValue(data, "cCount"),
      },
      wealth: {
        min: getMinValue(data, "Wealth"),
        max: getMaxValue(data, "Wealth"),
      },
      fwealth: {
        min: getMinValue(data, "fWealth"),
        max: getMaxValue(data, "fWealth"),
      },
      cwealth: {
        min: getMinValue(data, "cWealth"),
        max: getMaxValue(data, "cWealth"),
      },
      avg: {
        min: getMinValue(data, "Avg"),
        max: getMaxValue(data, "Avg"),
      },
      favg: {
        min: getMinValue(data, "fAvg"),
        max: getMaxValue(data, "fAvg"),
      },
      cWeight: {
        min: getMinValue(data, "cWeight"),
        max: getMaxValue(data, "cWeight"),
      },
    });
  }, [props]);
  return (
    <>
      <Table
        celled
        structured
        selectable={state.currentGroup.drillDownEnabled}
        striped
        compact
        sortable
        className="refinement-custom-table right-align-text"
      >
        {/* Headers for the Table */}
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              rowSpan="3"
              style={{ textTransform: "uppercase" }}
              textAlign="center"
            >
              {state.selectedKey}
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="5" textAlign="center">
              Households
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="5" textAlign="center">
              Financial Wealth (000's)
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="2" textAlign="center">
              Average Wealth (000's)
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell colSpan="2" textAlign="center">
              Context
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="3" textAlign="center">
              Filter
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="2" textAlign="center">
              Context
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="3" textAlign="center">
              Filter
            </Table.HeaderCell>
            <Table.HeaderCell
              rowSpan="2"
              textAlign="center"
              sorted={
                props.currentSort.sortOn === "avgWealth"
                  ? props.currentSort.sortDir
                  : false
              }
              onClick={() => {
                props.onSort("avgWealth");
              }}
            >
              Context
            </Table.HeaderCell>
            <Table.HeaderCell
              rowSpan="2"
              textAlign="center"
              {...getSortAttributes("fAvgWealth", props)}
            >
              Filter
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell
              sorted={
                props.currentSort.sortBy === "Count"
                  ? props.currentSort.sortDir
                  : false
              }
              onClick={() => {
                props.onSort("count");
              }}
            >
              Count
            </Table.HeaderCell>
            <Table.HeaderCell>Weight</Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                props.currentSort.sortBy === "fCount"
                  ? props.currentSort.sortDir
                  : false
              }
              onClick={() => {
                props.onSort("fCount");
              }}
            >
              Count
            </Table.HeaderCell>
            <Table.HeaderCell>Weight</Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                props.currentSort.sortBy === "concent"
                  ? props.currentSort.sortDir
                  : false
              }
              onClick={() => {
                props.onSort("cCount");
              }}
            >
              Concent.
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                props.currentSort.sortBy === "Wealth"
                  ? props.currentSort.sortDir
                  : false
              }
              onClick={() => {
                props.onSort("wealth");
              }}
            >
              Wealth
            </Table.HeaderCell>
            <Table.HeaderCell>Weight</Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                props.currentSort.sortBy === "fWealth"
                  ? props.currentSort.sortDir
                  : false
              }
              onClick={() => {
                props.onSort("fWealth");
              }}
            >
              Wealth
            </Table.HeaderCell>
            <Table.HeaderCell>Weight</Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                props.currentSort.sortBy === "concentW"
                  ? props.currentSort.sortDir
                  : false
              }
              onClick={() => {
                props.onSort("cWealth");
              }}
            >
              Concent.
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {/* Overall Context Row. 1st Row */}
          <Table.Row>
            <Table.Cell style={{ color: "#274398" }} className="value-field-td">
              Canada
            </Table.Cell>
            <Table.Cell>{toMoneyFormat(state.contextInfo?.count)}</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              {props.isFiltered && toMoneyFormat(state.contextInfo?.fCount)}
            </Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              {props.isFiltered && state.contextInfo?.cCount?.toFixed(1) + "%"}
            </Table.Cell>
            <Table.Cell>{toMoneyFormat(state.contextInfo?.wealth)}</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              {props.isFiltered && toMoneyFormat(state.contextInfo?.fWealth, 0)}
            </Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              {props.isFiltered && state.contextInfo?.cWealth?.toFixed(1) + "%"}
            </Table.Cell>
            <Table.Cell>
              {toMoneyFormat(Math.round(state.contextInfo?.avgWealth))}
            </Table.Cell>
            <Table.Cell>
              {props.isFiltered &&
                toMoneyFormat(Math.round(state.contextInfo?.fAvgWealth))}
            </Table.Cell>
          </Table.Row>
          {/* Current Context Row */}
          <Table.Row>
            {/* Context */}
            <Table.Cell style={{ color: "#274398" }} className="value-field-td">
              Context: {props.context}
            </Table.Cell>
            {/* Context Count */}
            <Table.Cell>
              {toMoneyFormat(state.currentContextInfo?.count)}
            </Table.Cell>
            {/* Context Weight */}
            <Table.Cell>
              {/* state.currentContextInfo?.count > 0 -- Added by bedesar */}
              {state.currentContextInfo?.count > 0 &&
                state.contextInfo?.count > 0 &&
                checkDecimal(
                  (
                    (state.currentContextInfo?.count /
                      state.contextInfo?.count) *
                    100
                  ).toFixed(1)
                ) + "%"}
            </Table.Cell>
            {/* filter count */}
            <Table.Cell>
              {props.isFiltered &&
                toMoneyFormat(state.currentContextInfo?.fCount)}
            </Table.Cell>
            {/* filter weight */}
            <Table.Cell>
              {props.isFiltered &&
                checkDecimal(
                  (state.currentContextInfo?.fCount /
                    state.contextInfo?.fCount) *
                    100
                ).toFixed(1) + "%"}
            </Table.Cell>
            {/* filter concentration */}
            <Table.Cell>
              {props.isFiltered &&
                state.currentContextInfo?.cCount > 0 &&
                state.currentContextInfo?.cCount?.toFixed(1) + "%"}
            </Table.Cell>
            {/* context wealth */}
            <Table.Cell>
              {toMoneyFormat(state.currentContextInfo?.wealth)}
            </Table.Cell>
            {/* context weight */}
            <Table.Cell>
              {state.currentContextInfo?.wealth > 0 &&
                state.contextInfo?.wealth > 0 &&
                checkDecimal(
                  (state.currentContextInfo?.wealth /
                    state.contextInfo?.wealth) *
                    100
                ).toFixed(1) + "%"}
            </Table.Cell>
            {/* FILTER wealth */}
            <Table.Cell>
              {props.isFiltered &&
                toMoneyFormat(state.currentContextInfo?.fWealth, 0)}
            </Table.Cell>
            {/* filter weight */}
            <Table.Cell>
              {props.isFiltered &&
                checkDecimal(
                  (state.currentContextInfo?.fWealth /
                    state.contextInfo?.fWealth) *
                    100
                ).toFixed(1) + "%"}
            </Table.Cell>
            {/* filter concentration */}
            <Table.Cell>
              {props.isFiltered &&
                state.currentContextInfo?.cWealth > 0 &&
                state.currentContextInfo?.cWealth?.toFixed(1) + "%"}
            </Table.Cell>
            <Table.Cell>
              {toMoneyFormat(Math.round(state.currentContextInfo?.avgWealth))}
            </Table.Cell>
            <Table.Cell>
              {props.isFiltered &&
                toMoneyFormat(Math.round(state.currentContextInfo?.fAvgWealth))}
            </Table.Cell>
          </Table.Row>
          {/* Blank Row */}
          <Table.Row>
            {[...Array(13).keys()].map((d) => {
              return <Table.Cell key={d}/>;
            })}
          </Table.Row>
          {state.filteredData?.map((data, index) => {
            return (
              <Table.Row onClick={() => props.onClick(data)} key={index}>
                <Table.Cell className="value-field-td">
                  {data.valueDisplayed}
                </Table.Cell>
                <Table.Cell>{toMoneyFormat(data.Count[0])}</Table.Cell>
                <Table.Cell
                  className={getColorClass(
                    state.count?.min,
                    state.count?.max,
                    data.Count[0]
                  )}
                >
                  {getWeight(state.inputData, data.value, "Count")}
                </Table.Cell>
                <Table.Cell>
                  {props.isFiltered && toMoneyFormat(data.fCount[0])}
                </Table.Cell>
                <Table.Cell
                  className={
                    props.isFiltered &&
                    getColorClass(
                      state.fcount?.min,
                      state.fcount?.max,
                      data.fCount[0],
                      true
                    )
                  }
                >
                  {props.isFiltered &&
                    data.fCount[0] > 0 &&
                    getWeight(state.inputData, data.value, "fCount")}
                </Table.Cell>
                <Table.Cell
                  className={
                    props.isFiltered &&
                    getColorClass(
                      state.ccount?.min,
                      state.ccount?.max,
                      data.cCount[0],
                      true
                    )
                  }
                >
                  {props.isFiltered &&
                    data.fCount[0] > 0 &&
                    data.cCount[0]?.toFixed(2) + "%"}
                </Table.Cell>
                <Table.Cell>{toMoneyFormat(data.Wealth[0])}</Table.Cell>
                <Table.Cell
                  className={getColorClass(
                    state.wealth?.min,
                    state.wealth?.max,
                    data.Wealth[0]
                  )}
                >
                  {getWeight(state.inputData, data.value, "Wealth")}
                </Table.Cell>
                <Table.Cell>
                  {props.isFiltered && toMoneyFormat(data.fWealth[0])}
                </Table.Cell>
                <Table.Cell
                  className={
                    props.isFiltered &&
                    getColorClass(
                      state.fwealth?.min,
                      state.fwealth?.max,
                      data.fWealth[0],
                      true
                    )
                  }
                >
                  {props.isFiltered &&
                    data.fWealth[0] > 0 &&
                    getWeight(state.inputData, data.value, "fWealth")}
                </Table.Cell>
                <Table.Cell
                  className={
                    props.isFiltered &&
                    getColorClass(
                      state.cwealth?.min,
                      state.cwealth?.max,
                      data.cWealth[0],
                      true
                    )
                  }
                >
                  {props.isFiltered &&
                    data.fWealth[0] > 0 &&
                    data.cWealth[0]?.toFixed(2) + "%"}
                </Table.Cell>
                <Table.Cell
                  className={getColorClass(
                    state.avg?.min,
                    state.avg?.max,
                    data.Avg[0]
                  )}
                >
                  {toMoneyFormat(data.Avg)}
                </Table.Cell>
                <Table.Cell
                  className={
                    props.isFiltered &&
                    getColorClass(
                      state.favg?.min,
                      state.favg?.max,
                      data.fAvg[0],
                      true
                    )
                  }
                >
                  {props.isFiltered && toMoneyFormat(data.fAvg)}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Pagination
              defaultActivePage={1}
              totalPages={Math.ceil(state.inputData?.length / 15)}
              onPageChange={(e, paginationProps) => {
                setState({
                  ...state,
                  filteredData: paginate(
                    state.inputData,
                    15,
                    paginationProps.activePage
                  ),
                });
              }}
            />
          </div> */}
    </>
  );
}
