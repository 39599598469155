import React from "react";
import { getColorClass, toMoneyFormat } from "../../../common/functions";
import { StoreContext } from "../../../common/services/store";
import "../filterviewlayout.css";

class CityTable extends React.Component {
  static contextType = StoreContext;
  constructor(props) {
    super(props);
    //console.log('City table props'+props.masterData)
    this.state = {
      cityTableData: props.cellData,
      headerData: props.header,
      masterData: props.masterData,
      subFilterData: {},
      filterData: {},

      masterData: [],
      colorValues: {
        min: 0,
        max: 1,
      },
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      let counts = [];

      this.props.cellData.map((d) => {
        if (d.filter_concentration === "Total") {
          return;
        }
        counts.push(
          d.low_density,
          d.mid_density,
          d.high_density,
          d.ultra_high_density
        );
      });
      this.setState({
        cityTableData: this.props.cellData,
        headerData: this.props.header,
        masterData: this.props.masterData,
        colorValues: {
          min: 1,
          max: Math.max(...counts),
        },
        avgWealth: this.props.avgWealth,
      });
    }
  }

  renderTableHeader() {
    //console.log('RenderTableHeader' , this.state.headerData)
    let header = this.state.headerData.map((value) => value.label);
    return header.map((key, index) => {
      return <th key={index}>{key}</th>;
    });
  }

  renderTableData() {
    const { setSubFilterDisplay } = this.context;
    return this.state.cityTableData.map((data) => {
      const {
        filter_concentration,
        low_density,
        mid_density,
        high_density,
        ultra_high_density,
        total,
        cell_row,
      } = data; //destructuring
      return (
        <tr key={filter_concentration}>
          <td>{filter_concentration}</td>
          <td
            className={`cursor-pointer
            ${
              filter_concentration !== "Total" &&
              getColorClass(
                this.state.colorValues.min,
                this.state.colorValues.max,
                low_density
              )
            }`}
            onClick={() => {
              this.cellData(cell_row, "low_density");
              setSubFilterDisplay(true);
            }}
          >
            {low_density}
          </td>
          <td
            className={`cursor-pointer
            ${
              filter_concentration !== "Total" &&
              getColorClass(
                this.state.colorValues.min,
                this.state.colorValues.max,
                mid_density
              )
            }`}
            onClick={() => {
              this.cellData(cell_row, "mid_density");
              setSubFilterDisplay(true);
            }}
          >
            {mid_density}
          </td>
          <td
            className={`cursor-pointer
            ${
              filter_concentration !== "Total" &&
              getColorClass(
                this.state.colorValues.min,
                this.state.colorValues.max,
                high_density
              )
            }`}
            onClick={() => {
              this.cellData(cell_row, "high_density");
              setSubFilterDisplay(true);
            }}
          >
            {high_density}
          </td>
          <td
            className={`cursor-pointer
              ${
                filter_concentration !== "Total" &&
                getColorClass(
                  this.state.colorValues.min,
                  this.state.colorValues.max,
                  ultra_high_density
                )
              }`}
            onClick={() => {
              this.cellData(cell_row, "ultra_high_density");
              setSubFilterDisplay(true);
            }}
          >
            {ultra_high_density}
          </td>
          <td
            className={"cursor-pointer"}
            onClick={() => {
              this.cellData(cell_row, "total");
              setSubFilterDisplay(true);
            }}
          >
            {total}
          </td>
        </tr>
      );
    });
  }

  cellData(row, column) {
    //console.log(row, column, this.state.masterData);
    let subFilterData = this.state.masterData.filter((data) => {
      if (
        row !== 10
          ? data["householdConc"] > row * 10 &&
            data["householdConc"] <= (row + 1) * 10
          : true
      ) {
        if (
          column === "low_density" &&
          data["density"] >= 0 &&
          data["density"] < 2000
        ) {
          return data;
        }
        if (
          column === "mid_density" &&
          data["density"] > 2000 &&
          data["density"] <= 20000
        ) {
          return data;
        }
        if (
          column === "high_density" &&
          data["density"] > 20000 &&
          data["density"] <= 200000
        ) {
          return data;
        }
        if (column === "ultra_high_density" && data["density"] > 200000) {
          return data;
        }
        if (column === "total") {
          return data;
        }
      }
    });
    this.context.setSubFilter(subFilterData);
    // console.log(subFilterData);
  }

  render() {
    return (
      <div style={{ margin: "auto", width: "95%" }}>
        <table className="city-filter refinement-custom-table ui celled table">
          <tbody>
            <tr>
              <th></th>
              <th colSpan="5">Density Spectrum</th>
            </tr>
            <tr>{this.renderTableHeader()}</tr>
            <tr>
              <td>Avg. Wealth</td>
              <td className="right-align-text">
                {"$" +
                  toMoneyFormat(
                    this.state.avgWealth?.low_density_avgWealth /
                      this.state.avgWealth?.low_density_city,
                    2
                  ) +
                  "k"}
              </td>
              <td className="right-align-text">
                {" "}
                {"$" +
                  toMoneyFormat(
                    this.state.avgWealth?.mid_density_avgWealth /
                      this.state.avgWealth?.mid_density_city,
                    2
                  ) +
                  "k"}
              </td>
              <td className="right-align-text">
                {" "}
                {"$" +
                  toMoneyFormat(
                    this.state.avgWealth?.high_density_avgWealth /
                      this.state.avgWealth?.high_density_city,
                    2
                  ) +
                  "k"}
              </td>
              <td className="right-align-text">
                {" "}
                {"$" +
                  toMoneyFormat(
                    this.state.avgWealth?.ultra_high_density_avgWealth /
                      this.state.avgWealth?.ultra_high_density_city,
                    2
                  ) +
                  "k"}
              </td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            {this.renderTableData()}
          </tbody>
        </table>
      </div>
    );
  }
}

export default CityTable;
